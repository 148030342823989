import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const EditIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    sx={{
      ...sx,
      fill: 'none',
    }}
    {...props}
  >
    <path
      d="M12.9227 4.52798C13.2607 4.18992 13.7193 4 14.1973 4C14.6754 4 15.134 4.18992 15.472 4.52798C15.8101 4.86605 16 5.32456 16 5.80265C16 6.28075 15.8101 6.73926 15.472 7.07732L7.39912 15.1502L4 16L4.84978 12.6009L12.9227 4.52798Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default EditIcon;
