import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowSortIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon width="6" height="7" viewBox="0 0 6 7" fill="none" {...props}>
    <path
      d="M5.6465 3.3535C5.84173 3.15827 5.84173 2.84173 5.6465 2.6465L3 0L0.3535 2.6465C0.158267 2.84173 0.158267 3.15827 0.3535 3.3535C0.548732 3.54873 0.865267 3.54873 1.0605 3.3535L2.5 1.914L2.5 6.5C2.5 6.77614 2.72386 7 3 7C3.27614 7 3.5 6.77614 3.5 6.5L3.5 1.914L4.9395 3.3535C5.13473 3.54873 5.45127 3.54873 5.6465 3.3535Z"
      fill="#9CABBD"
    />
  </SvgIcon>
);

export default ArrowSortIcon;
