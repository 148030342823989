import ArrowDownIcon from '@app/components/Icons/ArrowDownIcon';
import ArrowRightIcon from '@app/components/Icons/ArrowRightIcon';
import Tooltip from '@app/components/Tooltip';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { MainMenuItem } from '@app/layouts/MainLayout/Sidebar/MainMenu/mainMenuItems';
import { listItemIcon } from '@app/layouts/MainLayout/Sidebar/MainMenu/MenuItem.sx';
import MenuItemComponent from '@app/layouts/MainLayout/Sidebar/MainMenu/MenuItemComponent';
import { useAccessDenied } from '@app/layouts/MainLayout/Sidebar/MainMenu/useAccessDenied';
import { rem } from '@app/utils/sx/functions';
import {
  Box,
  ClickAwayListener,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import styles from '../Sidebar.module.scss';

interface Props {
  item: MainMenuItem;
  open?: string | null;
  setOpen?: Dispatch<SetStateAction<string | null>>;
  noInset?: boolean;
  defaultActive?: boolean;
}

const MenuItem = ({ item, open = null, setOpen = () => {}, noInset, defaultActive = false }: Props): JSX.Element => {
  const { name, icon, items } = item;
  const hasItems = items && items.length > 0;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const anchorRef = useRef(null);
  const { expanded } = useMainLayoutContext();
  const openPopper = Boolean(anchorEl);
  const accessDenied = useAccessDenied(item);

  const closePopper = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (expanded) {
      closePopper();
    }
  }, [expanded]);

  const toggleCollapse = () => {
    if (expanded) {
      if (item.items) {
        setOpen(open ? null : item.id);

        return;
      }
      setOpen(null);

      return;
    }

    setAnchorEl(item.items && !anchorEl ? anchorRef.current : null);
  };

  if (item.divider && !accessDenied) {
    return <Divider />;
  }

  return (
    <>
      <Tooltip
        title={name}
        placement="right"
        classes={{ popper: styles.Tooltip }}
        disableHoverListener={!icon || expanded || (!expanded && item.id === 'users')}
        disableTouchListener
      >
        {/* TODO (slicing) replace className with sx  */}
        <Box
          sx={{
            '.MuiListItemButton-root': {
              minHeight: rem(40),
              mb: rem(2),
            },
          }}
        >
          <MenuItemComponent item={item} open={open === item.id} toggleOpen={toggleCollapse}>
            {!!icon && (
              <Box
                sx={{ display: 'flex', color: openPopper || open === item.id ? 'primary.main' : '#fff' }}
                ref={anchorRef}
              >
                <ListItemIcon sx={listItemIcon(expanded)}>{icon}</ListItemIcon>
              </Box>
            )}
            <ListItemText
              sx={{ color: open === item.id ? 'primary.main' : '#fff' }}
              className={styles.ListItemText}
              primary={name}
              inset={!noInset && !icon}
            />
            {hasItems && open !== item.id && <ArrowRightIcon className={`ListItemArrow ${styles.ListItemArrow}`} />}
            {hasItems && open === item.id && (
              <ArrowDownIcon className={`ListItemArrow ListItemArrowDown ${styles.ListItemArrow}`} />
            )}
          </MenuItemComponent>
        </Box>
      </Tooltip>

      {hasItems ? (
        expanded ? (
          <Collapse in={open === item.id} timeout="auto">
            <List
              component="div"
              disablePadding
              sx={{
                mt: rem(-2),
                mb: rem(2),
                background: '#1d215b',
              }}
            >
              {items.map((item, index) => (
                <MenuItem item={item} key={index} />
              ))}
            </List>
          </Collapse>
        ) : (
          <Popper open={openPopper} anchorEl={anchorEl} placement="right-start" style={{ zIndex: 5 }}>
            <ClickAwayListener onClickAway={closePopper}>
              <List className={styles.DropdownList} component="div" disablePadding>
                <ListItem disablePadding>
                  <ListItemButton className={styles.DropdownHeaderBox} disabled>
                    <ListItemText primary={name.toUpperCase()} />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <div className={styles.DropdownItemsList} onClick={closePopper}>
                  {items.map((item, index) => (
                    <MenuItem item={item} key={index} noInset />
                  ))}
                </div>
              </List>
            </ClickAwayListener>
          </Popper>
        )
      ) : null}
    </>
  );
};

export default MenuItem;
