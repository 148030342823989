import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowUpIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    className="icon-size-initial"
    sx={{
      color: 'text.auxiliary',
      fontSize: '12px',
      ...sx,
    }}
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 7.70711C2.68342 8.09763 3.31658 8.09763 3.70711 7.70711L6 5.41421L8.29289 7.70711C8.68342 8.09763 9.31658 8.09763 9.70711 7.70711C10.0976 7.31658 10.0976 6.68342 9.70711 6.29289L6 2.58579L2.29289 6.29289C1.90237 6.68342 1.90237 7.31658 2.29289 7.70711Z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default ArrowUpIcon;
