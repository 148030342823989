import { SIDEBAR_WIDTH } from '@app/constants/main-layout';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { Drawer } from '@mui/material';
import React from 'react';
import MainMenu from './MainMenu';
import SidebarHeader from './SidebarHeader';

const MobileDrawer = (): JSX.Element => {
  const { mobileOpen, handleDrawerToggle } = useMainLayoutContext();

  return (
    <Drawer
      className="MobileDrawer"
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiBackdrop-root': {
          background: 'transparent',
        },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: SIDEBAR_WIDTH,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#030848',
          color: '#fff',
          '& .MuiListItemIcon-root': {
            color: 'inherit',
          },
          '& .MuiButtonBase-root': {
            color: '#fff',
            textTransform: 'none',
          },
        },
      }}
    >
      <div className="MobileDrawerNav">
        <SidebarHeader />
        <MainMenu />
      </div>
    </Drawer>
  );
};

export default MobileDrawer;
