import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CheckMarkIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 11"
    {...props}
    sx={{
      fontSize: rem(16),
      ...sx,
    }}
  >
    <path
      d="M6.00011 10.657L0.550299 5.20711C0.15978 4.81658 0.159782 4.18342 0.550304 3.7929V3.7929C0.94083 3.40237 1.574 3.40238 1.96452 3.79291L6.00011 7.82858L13.5356 0.293044C13.9262 -0.0974804 14.5593 -0.09748 14.9499 0.293044V0.293044C15.3404 0.683569 15.3404 1.31673 14.9499 1.70726L6.00011 10.657Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CheckMarkIcon;
