import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

const H4 = ({ children, sx, ...props }: TypographyProps): JSX.Element => (
  <Typography
    variant="h4"
    sx={{
      mb: '16px',
      color: '#445277',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Typography>
);

export default H4;
