import { useHandleQueryError } from '@app/api/hooks/useHandleQueryError';
import { HttpStatusCode } from '@app/api/http/constants/http-status-code';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { RetryValue } from 'react-query/types/core/retryer';
import { QueryFunction, QueryKey } from 'react-query/types/core/types';
import { UseQueryOptions } from 'react-query/types/react/types';

const noRetryStatuses = [HttpStatusCode.Unauthorized, HttpStatusCode.NotFound, HttpStatusCode.Forbidden];

export function useCustomQuery<TData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TData>,
  options?: UseQueryOptions<TData, AxiosError, TData>
) {
  const handleErrors = useHandleQueryError();
  const defaultOptions = {
    refetchOnWindowFocus: false,
    retry: (failureCount: number, error: AxiosError): RetryValue<AxiosError> => {
      if (noRetryStatuses.includes(error?.response?.status)) {
        return false;
      }

      return failureCount < 2;
    },
    onError: (error) => {
      handleErrors(error);
    },
  } as UseQueryOptions<TData, AxiosError, TData>;

  return useQuery<TData, AxiosError>(queryKey, queryFn, { ...defaultOptions, ...options });
}
