import { useSearch } from '@app/components/DefaultPageHeader/Search/useSearch';
import CloseIcon from '@app/components/Icons/CloseIcon';
import SearchIcon from '@app/components/Icons/SearchIcon';
import Input from '@app/forms/components/Input';
import { rem } from '@app/utils/sx/functions';
import { Box, BoxProps, InputAdornment } from '@mui/material';
import React from 'react';
import { clearBth, inputStyles } from './Search.sx';

const Search = ({ sx, ...props }: BoxProps): JSX.Element => {
  const { ref, value, handleChange, handleSubmit, doSearch, clear } = useSearch();

  return (
    <Box
      sx={{
        m: rem([0, 16, 0, 24]),
        ...sx,
      }}
      {...props}
    >
      <form onSubmit={handleSubmit}>
        <Input
          ref={ref}
          sx={inputStyles}
          placeholder="Search"
          value={value}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {value && <CloseIcon sx={clearBth} color="action" onClick={clear} />}
                <SearchIcon color="action" onClick={doSearch} />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Box>
  );
};

export default Search;
