import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const OneMoreIcon = ({ ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path d="M8.68048 16.8696C8.21149 16.8696 7.83129 16.4894 7.83129 16.0204V8.05707C7.83129 7.58808 8.21149 7.20789 8.68048 7.20789C9.14948 7.20789 9.52967 7.58808 9.52967 8.05708V16.0204C9.52967 16.4894 9.14948 16.8696 8.68048 16.8696ZM4.6988 12.8879C4.2298 12.8879 3.84961 12.5078 3.84961 12.0388C3.84961 11.5698 4.2298 11.1896 4.6988 11.1896H12.6622C13.1312 11.1896 13.5114 11.5698 13.5114 12.0388C13.5114 12.5078 13.1312 12.8879 12.6622 12.8879H4.6988Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.7768 6.26485C20.0389 6.41693 20.2001 6.69702 20.2001 7.00002V17C20.2001 17.4695 19.8196 17.85 19.3501 17.85C18.8807 17.85 18.5001 17.4695 18.5001 17V8.46473L16.2719 9.73803C15.8643 9.97094 15.345 9.82933 15.1121 9.42174C14.8792 9.01415 15.0208 8.49493 15.4284 8.26202L18.9284 6.26202C19.1915 6.11169 19.5147 6.11277 19.7768 6.26485Z" fill="currentColor"/>
  </SvgIcon>
);

export default OneMoreIcon;
