import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WarehouseIcon = ({ ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9999 16.3496C15.5857 16.3496 15.2499 16.0138 15.2499 15.5996V6.65723L9.14671 2.23237C9.05918 2.16891 8.94076 2.16891 8.85323 2.23237L2.74995 6.65723V15.5996C2.74995 16.0138 2.41416 16.3496 1.99995 16.3496C1.58573 16.3496 1.24995 16.0138 1.24995 15.5996V7.70693C0.944662 7.81497 0.592487 7.71535 0.392738 7.43984C0.149609 7.10448 0.224369 6.63553 0.559721 6.3924L7.97278 1.01795C8.58553 0.573709 9.41442 0.573711 10.0272 1.01795L17.4402 6.3924C17.7755 6.63553 17.8503 7.10449 17.6072 7.43984C17.4074 7.71535 17.0552 7.81497 16.7499 7.70693V15.5996C16.7499 16.0138 16.4142 16.3496 15.9999 16.3496ZM12.6 16.3496C12.1858 16.3496 11.85 16.0138 11.85 15.5996V11.7996C11.85 11.6615 11.738 11.5496 11.6 11.5496H6.39997C6.2619 11.5496 6.14997 11.6615 6.14997 11.7996V15.5996C6.14997 16.0138 5.81418 16.3496 5.39997 16.3496C4.98576 16.3496 4.64997 16.0138 4.64997 15.5996V11.7996C4.64997 10.8331 5.43347 10.0496 6.39997 10.0496H11.6C12.5665 10.0496 13.35 10.8331 13.35 11.7996V15.5996C13.35 16.0138 13.0142 16.3496 12.6 16.3496Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default WarehouseIcon;
