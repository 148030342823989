import React from 'react';

const Bell = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.02488 15.75C7.02488 15.4048 7.30471 15.125 7.64988 15.125H10.3499C10.6951 15.125 10.9749 15.4048 10.9749 15.75C10.9749 16.0952 10.6951 16.375 10.3499 16.375H7.64988C7.30471 16.375 7.02488 16.0952 7.02488 15.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6737 7.37614C14.6737 4.25326 12.1812 1.64858 9.04236 1.62524L8.95749 1.62524C5.81868 1.64858 3.32615 4.25326 3.32615 7.37614V7.87509C3.32615 10.3052 2.81831 11.6536 2.41983 12.3394L2.42094 12.3375C2.3154 12.5177 2.25924 12.7225 2.25812 12.9313C2.25701 13.1401 2.31098 13.3455 2.41458 13.5268C2.51819 13.7081 2.66777 13.8589 2.84824 13.9639C3.02871 14.069 3.23369 14.1246 3.4425 14.1251L3.4441 14.1251H8.99992H14.5558L14.5574 14.1251C14.7662 14.1246 14.9711 14.069 15.1516 13.9639C15.3321 13.8589 15.4817 13.7081 15.5853 13.5268C15.6889 13.3455 15.7428 13.1401 15.7417 12.9313C15.7406 12.7225 15.6845 12.5177 15.5789 12.3375L15.58 12.3394C15.1816 11.6536 14.6737 10.3052 14.6737 7.87509V7.37614ZM14.4468 12.8751C13.9342 11.951 13.4237 10.3956 13.4237 7.87509V7.37614C13.4237 4.91627 11.4625 2.89349 9.03424 2.87522H8.96562C6.53732 2.89349 4.57615 4.91627 4.57615 7.37614V7.87509C4.57615 10.3956 4.06564 11.951 3.55303 12.8751H8.99993H14.4468Z"
      fill="currentColor"
    />
  </svg>
);

export default Bell;
