import { SvgIconProps } from '@mui/material';
import React from 'react';

const ReceiverTerminalIcon = (props: SvgIconProps): JSX.Element => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 13.7H16C16.2761 13.7 16.5 13.4762 16.5 13.2V2.80001C16.5 2.52387 16.2761 2.30001 16 2.30001H2C1.72386 2.30001 1.5 2.52387 1.5 2.80001V13.2C1.5 13.4762 1.72386 13.7 2 13.7ZM16 15.2C17.1046 15.2 18 14.3046 18 13.2V2.80001C18 1.69544 17.1046 0.800013 16 0.800013H2C0.89543 0.800013 0 1.69544 0 2.80001V13.2C0 14.3046 0.89543 15.2 2 15.2H16Z"
      fill="CurrentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1504 4.39996C15.1504 4.81418 14.8146 5.14996 14.4004 5.14996H3.60039C3.18618 5.14996 2.85039 4.81418 2.85039 4.39996C2.85039 3.98575 3.18618 3.64996 3.60039 3.64996H14.4004C14.8146 3.64996 15.1504 3.98575 15.1504 4.39996Z"
      fill="CurrentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.84961 8C2.84961 7.58579 3.1854 7.25 3.59961 7.25H7.19961C7.61382 7.25 7.94961 7.58579 7.94961 8V11.6C7.94961 12.0142 7.61382 12.35 7.19961 12.35H3.59961C3.1854 12.35 2.84961 12.0142 2.84961 11.6V8ZM4.34961 8.75V10.85H6.44961V8.75H4.34961Z"
      fill="CurrentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0498 8C10.0498 7.58579 10.3856 7.25 10.7998 7.25H14.3998C14.814 7.25 15.1498 7.58579 15.1498 8V11.6C15.1498 12.0142 14.814 12.35 14.3998 12.35H10.7998C10.3856 12.35 10.0498 12.0142 10.0498 11.6V8ZM11.5498 8.75V10.85H13.6498V8.75H11.5498Z"
      fill="CurrentColor"
    />
  </svg>
);

export default ReceiverTerminalIcon;
