import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const LockIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 12C6.33579 12 6 12.3358 6 12.75V18C6 18.4142 6.33579 18.75 6.75 18.75H17.25C17.6642 18.75 18 18.4142 18 18V12.75C18 12.3358 17.6642 12 17.25 12H6.75ZM4.5 12.75C4.5 11.5074 5.50736 10.5 6.75 10.5H17.25C18.4926 10.5 19.5 11.5074 19.5 12.75V18C19.5 19.2426 18.4926 20.25 17.25 20.25H6.75C5.50736 20.25 4.5 19.2426 4.5 18V12.75Z"
      fill="#212B36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.25C11.2044 5.25 10.4413 5.56607 9.87868 6.12868C9.31607 6.69129 9 7.45435 9 8.25V11.25C9 11.6642 8.66421 12 8.25 12C7.83579 12 7.5 11.6642 7.5 11.25V8.25C7.5 7.05653 7.97411 5.91193 8.81802 5.06802C9.66193 4.22411 10.8065 3.75 12 3.75C13.1935 3.75 14.3381 4.22411 15.182 5.06802C16.0259 5.91193 16.5 7.05653 16.5 8.25V11.25C16.5 11.6642 16.1642 12 15.75 12C15.3358 12 15 11.6642 15 11.25V8.25C15 7.45435 14.6839 6.69129 14.1213 6.12868C13.5587 5.56607 12.7956 5.25 12 5.25Z"
      fill="#212B36"
    />
  </SvgIcon>
);

export default LockIcon;
