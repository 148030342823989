import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const WalletIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      d="M17.0625 15C17.7874 15 18.375 14.4124 18.375 13.6875C18.375 12.9626 17.7874 12.375 17.0625 12.375C16.3376 12.375 15.75 12.9626 15.75 13.6875C15.75 14.4124 16.3376 15 17.0625 15Z"
      fill="currentColor"
    />
    <path
      d="M19.875 6.03375V4.875C19.8751 4.28406 19.6427 3.71682 19.228 3.29582C18.8133 2.87482 18.2496 2.63386 17.6588 2.625H5.9325C2.625 2.625 2.29125 5.33625 2.25 6.705V6.75V18.75C2.25 19.3467 2.48705 19.919 2.90901 20.341C3.33097 20.7629 3.90326 21 4.5 21H19.5C20.0967 21 20.669 20.7629 21.091 20.341C21.5129 19.919 21.75 19.3467 21.75 18.75V8.25C21.7495 7.71867 21.5609 7.20468 21.2177 6.79904C20.8746 6.3934 20.3989 6.1223 19.875 6.03375ZM5.9325 4.125H17.6588C17.8518 4.13369 18.034 4.21653 18.1674 4.35625C18.3008 4.49598 18.3752 4.6818 18.375 4.875V6H3.8175C4.00875 4.63875 4.63125 4.125 5.9325 4.125ZM20.25 18.75C20.25 18.9489 20.171 19.1397 20.0303 19.2803C19.8897 19.421 19.6989 19.5 19.5 19.5H4.5C4.30109 19.5 4.11032 19.421 3.96967 19.2803C3.82902 19.1397 3.75 18.9489 3.75 18.75V7.5H19.5C19.6989 7.5 19.8897 7.57902 20.0303 7.71967C20.171 7.86032 20.25 8.05109 20.25 8.25V18.75Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default WalletIcon;
