import React from 'react';

const PlusEllipse = (): JSX.Element => (
  <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12C32 18.6274 26.6274 24 20 24H12C5.37258 24 0 18.6274 0 12Z"
      fill="currentColor"
    />
    <path d="M16.75 7L16.75 17L15.25 17L15.25 7L16.75 7Z" fill="white" />
    <path d="M11 11.25H21V12.75H11V11.25Z" fill="white" />
  </svg>
);

export default PlusEllipse;
