import { useAuth } from '@app/contexts/auth';
import { authService } from '@app/utils/services/authService';
import { credentialsService } from '@app/utils/services/credentialsService';
import { currentUserService } from '@app/utils/services/currentUserService';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

export const useLogoutDialog = () => {
  const { setIsAuthorized } = useAuth();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [forgetDevice, setForgetDevice] = useState(false);

  const { mutate, isLoading } = useMutation(authService.logout, {
    onSettled: () => {
      setIsOpen(false);
      credentialsService.flush();
      currentUserService.flush();
      setIsAuthorized(false);
      history.push('/auth/login');
    },
  });

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      setIsOpen(false);

      return;
    }

    mutate(forgetDevice);
  };

  return {
    isLoading,
    isOpen,
    openDialog,
    closeDialog,
    forgetDevice,
    setForgetDevice,
  };
};
