import { Permission, User } from '@app/pages/users/types';

const defaultValue: User = {
  id: null,
  userId: null,
  createdAt: null,
  createdBy: null,
  updatedAt: null,
  permissions: [],
  disabled: false,
  emailVerified: false,
  newEmail: null,
  phoneVerified: false,
  socialSecurity: null,
  status: null,
  totalLogins: null,
  address: null,
  address2: null,
  apt: null,
  businessName: null,
  cellPhone: null,
  city: null,
  country: null,
  dateOfBirth: null,
  email: null,
  firstName: null,
  lastName: null,
  notificationsReceiving: false,
  reasonForJoin: null,
  referralSource: null,
  role: null,
  state: null,
  wholesaler: false,
  workPhone: null,
  zip: null,
};

class CurrentUserService {
  private _currentUser: User = defaultValue;
  private currentUserKey = 'currentUser';

  constructor() {
    const savedCurrentUser = localStorage.getItem(this.currentUserKey);

    if (savedCurrentUser) {
      try {
        this._currentUser = JSON.parse(savedCurrentUser) as User;
      } catch (e) {
        this._currentUser = defaultValue;
        localStorage.removeItem(this.currentUserKey);
      }
    }
  }

  public get permissions(): Permission[] {
    return this._currentUser ? this._currentUser.permissions : [];
  }

  /**
   * Gets the currentUser.
   *
   * @return The currentUser or null if the user is not authenticated.
   */
  public get currentUser(): User | null {
    return this._currentUser;
  }

  /**
   * Sets the currentUser.
   * The currentUser persisted across sessions.
   *
   * @param currentUser The currentUser.
   */
  public set(currentUser: User): void {
    this._currentUser = currentUser;

    localStorage.setItem(this.currentUserKey, JSON.stringify(currentUser));
  }

  public flush(): void {
    this._currentUser = null;
    localStorage.removeItem(this.currentUserKey);
  }
}

export const currentUserService = new CurrentUserService();
