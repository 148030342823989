import ProfileButton from '@app/components/DefaultPageHeader/ProfileButton';
import {
  divider,
  iconBox,
  indicator,
  menu,
  menuItem,
  myProfileButton,
  name,
  profile,
  roleAndId,
  signOutBox,
  signOutButton,
  viewProfile,
} from '@app/components/DefaultPageHeader/ProfileMenu/ProfileMenu.sx';
import LogoutIcon from '@app/components/Icons/LogoutIcon';
import UserIcon from '@app/components/Icons/UserIcon';
import StyledMenu from '@app/components/StyledMenu';
import Tooltip from '@app/components/Tooltip';
import WarningIndicator from '@app/components/WarningIndicator';
import { verifyPhone } from '@app/constants/profile';
import { userRole } from '@app/constants/user-roles';
import { useCurrentUser } from '@app/contexts/currentUser';
import { UserRoles } from '@app/pages/users/types';
import { useMenuToggle } from '@app/utils/hooks/useMenuToggle';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import LogoutDialog from '../LogoutDialog';
import { useLogoutDialog } from '../LogoutDialog/useLogoutDialog';
import styles from './ProfileMenu.module.scss';

const ProfileMenu = () => {
  const { t } = useTranslation();
  const { isLoading, isOpen, openDialog, closeDialog, forgetDevice, setForgetDevice } = useLogoutDialog();
  const { firstName, lastName, role, userId, phoneVerified } = useCurrentUser() || {};
  const unverifiedPhone = userId && !phoneVerified;
  const notReceiver = role !== UserRoles.Receiver;

  const { openMenu, closeMenu, anchorEl, open } = useMenuToggle();

  return (
    <Box>
      <ProfileButton open={open} openMenu={openMenu} />

      <StyledMenu
        id="profile-menu"
        sx={menu}
        MenuListProps={{
          'aria-labelledby': 'profile-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        classes={{ root: styles.Modal }}
      >
        <MenuItem sx={menuItem} disabled disableRipple>
          <Box sx={profile}>
            <Box sx={name}>
              {firstName} {lastName}
            </Box>
            <Box sx={roleAndId}>
              <Typography>{`ID${userId}`}</Typography>
              <Typography>{userRole[role]}</Typography>
            </Box>
          </Box>
        </MenuItem>
        <Divider sx={divider} />
        {notReceiver && userId && (
          <MenuItem sx={myProfileButton} component={Link} to={'/profile'} onClick={closeMenu} disableRipple>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={viewProfile}>
                <UserIcon sx={iconBox} />
                View my profile
              </Box>

              {unverifiedPhone && (
                <Tooltip placement="bottom-end" title={t(verifyPhone)}>
                  <Box sx={{ pl: '20px' }}>
                    <WarningIndicator sx={{ ...indicator, position: 'static' }} />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </MenuItem>
        )}
        <MenuItem sx={signOutBox} onClick={closeMenu} disableRipple>
          <Button sx={signOutButton} variant="outlined" color="inherit" startIcon={<LogoutIcon />} onClick={openDialog}>
            Sign out
          </Button>
        </MenuItem>
      </StyledMenu>

      <LogoutDialog
        open={isOpen}
        onClose={closeDialog}
        forgetDevice={forgetDevice}
        setForgetDevice={setForgetDevice}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default memo(ProfileMenu);
