import { SvgIconProps } from '@mui/material';
import React from 'react';

const Rebuild = (props: SvgIconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6646 3.82918C11.8757 3.72361 12.1243 3.72361 12.3354 3.82918L19.8354 7.57918C20.0895 7.70622 20.25 7.96592 20.25 8.25C20.25 8.53408 20.0895 8.79378 19.8354 8.92082L12.3354 12.6708C12.1243 12.7764 11.8757 12.7764 11.6646 12.6708L4.16459 8.92082C3.9105 8.79378 3.75 8.53408 3.75 8.25C3.75 7.96592 3.9105 7.70622 4.16459 7.57918L11.6646 3.82918ZM6.17705 8.25L12 11.1615L17.8229 8.25L12 5.33853L6.17705 8.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.82934 15.4148C4.01459 15.0443 4.46509 14.8941 4.83557 15.0793L12.0002 18.6616L19.1648 15.0793C19.5352 14.8941 19.9857 15.0443 20.171 15.4148C20.3562 15.7852 20.2061 16.2357 19.8356 16.421L12.3356 20.171C12.1244 20.2766 11.8759 20.2766 11.6648 20.171L4.16475 16.421C3.79427 16.2357 3.6441 15.7852 3.82934 15.4148Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.82934 11.6648C4.01459 11.2943 4.46509 11.1441 4.83557 11.3293L12.0002 14.9116L19.1648 11.3293C19.5352 11.1441 19.9857 11.2943 20.171 11.6648C20.3562 12.0352 20.2061 12.4857 19.8356 12.671L12.3356 16.421C12.1244 16.5266 11.8759 16.5266 11.6648 16.421L4.16475 12.671C3.79427 12.4857 3.6441 12.0352 3.82934 11.6648Z"
      fill="currentColor"
    />
  </svg>
);

export default Rebuild;
