import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

interface SnackbarOpenProps {
  severity: AlertColor;
  message: ReactNode;
  duration?: number;
}

interface SnackbarContextProps {
  open: (props: SnackbarOpenProps) => void;
  close: () => void;
}

type ActionType =
  | { type: 'open'; payload: null }
  | { type: 'close'; payload: null }
  | { type: 'setSeverity'; payload: AlertColor }
  | { type: 'setMessage'; payload: ReactNode }
  | { type: 'setDuration'; payload: number };

interface SnackbarState {
  isOpen: boolean;
  severity: AlertColor;
  message: ReactNode;
  duration: number | null;
}

export interface SnackbarLocationState {
  snackbar: Omit<SnackbarState, 'isOpen'>;
}
interface Props {
  children: ReactNode;
}

const SnackbarContext = createContext({
  open: (props: SnackbarOpenProps) => {},
  close: () => {},
});

export const useSnackbar = () => useContext<SnackbarContextProps>(SnackbarContext);

const initialState: SnackbarState = {
  isOpen: false,
  severity: 'error',
  message: '',
  duration: 6000,
};

const reducer = (state: SnackbarState, action: ActionType) => {
  switch (action.type) {
    case 'open':
      return { ...state, isOpen: true };
    case 'close':
      return { ...state, isOpen: false };
    case 'setMessage':
      return { ...state, message: action.payload };
    case 'setSeverity':
      return { ...state, severity: action.payload };
    case 'setDuration':
      return { ...state, duration: action.payload };
  }
};

const SnackbarProvider = ({ children }: Props): JSX.Element => {
  const [isAuthLayout, setIsAuthLayout] = useState(false);
  const [{ isOpen, severity, message, duration }, dispatch] = useReducer(reducer, initialState);
  const { state: locationState } = useLocation<SnackbarLocationState>();
  const history = useHistory();

  useEffect(() => {
    const authLayout = document.querySelector('.auth-layout');
    setIsAuthLayout(Boolean(authLayout));
  });

  const sx = isAuthLayout ? { left: { md: '25%' }, maxWidth: { md: '48%' } } : {};

  const close = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: 'close', payload: null });

    // clear location state in case of show snackbar after redirect
    if (locationState?.snackbar) {
      const { snackbar: _, ...state } = locationState;
      history.replace({ ...history.location, state });
    }
  };

  const open = ({ severity, message, duration }: SnackbarOpenProps) => {
    dispatch({ type: 'setSeverity', payload: severity });
    dispatch({ type: 'setMessage', payload: message });
    duration !== undefined && dispatch({ type: 'setDuration', payload: duration });
    dispatch({ type: 'open', payload: null });
  };

  const value: SnackbarContextProps = {
    open,
    close,
  };

  return (
    <SnackbarContext.Provider value={value}>
      <Snackbar
        open={isOpen}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: '99999999', ...sx }}
        onClose={close}
      >
        <Alert
          onClose={close}
          severity={severity}
          elevation={6}
          variant="filled"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
