import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const RepliesIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path
      d="M3.2876 13.1204L2.51285 16.8376C2.47554 17.0191 2.53114 17.2071 2.66209 17.338C2.76598 17.4426 2.90571 17.499 3.04983 17.499C3.08714 17.499 3.12445 17.4953 3.1625 17.4873L6.88115 16.7118C7.86001 17.1741 8.94202 17.417 10.0226 17.417C11.4389 17.3936 12.7777 17.0351 13.9622 16.3021C14.5717 15.9254 15.1203 15.465 15.5937 14.9369C15.8469 14.6545 16.0786 14.3521 16.2866 14.0332C16.7195 13.3696 16.8604 12.5692 16.8604 11.7769C16.8604 10.3352 15.6917 9.1665 14.25 9.1665H9.21898V10.4165H14.1937C14.9669 10.4165 15.5937 11.0433 15.5937 11.8165V11.9061C15.5937 12.4502 15.4811 12.9966 15.1749 13.4464C14.6853 14.1656 14.0466 14.7805 13.3043 15.2392C12.3332 15.8402 11.2226 16.1454 10.0124 16.167C9.12015 16.1655 8.22417 15.9637 7.41502 15.5815L7.03615 15.4026L3.95488 16.0451L4.59671 12.9657L4.41811 12.5871C4.03259 11.7699 3.83065 10.8639 3.83308 9.96115V9.95779C3.83308 8.80618 4.15405 7.67785 4.76123 6.69674C5.36776 5.71569 6.23338 4.92492 7.26579 4.40902L7.27017 4.40681C8.10968 3.98317 9.04544 3.76697 10.0523 3.75146H10.4125C11.7433 3.83143 12.9816 4.31816 13.9838 5.13591C14.2512 5.35413 14.6451 5.35685 14.8891 5.11271C15.1331 4.86855 15.1346 4.47048 14.8709 4.24773C13.6385 3.20669 12.098 2.59221 10.4484 2.50146H10.0431C8.86301 2.51829 7.73491 2.77215 6.70703 3.29085C5.46699 3.9105 4.42668 4.86083 3.69802 6.03941C2.96863 7.218 2.58308 8.57363 2.58308 9.95779C2.58015 11.0464 2.82304 12.1357 3.2876 13.1204Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.275 6.84956C11.5191 7.09364 11.5191 7.48937 11.275 7.73345L9.21699 9.7915L11.275 11.8496C11.5191 12.0936 11.5191 12.4894 11.275 12.7334C11.031 12.9775 10.6352 12.9775 10.3912 12.7334L7.44922 9.7915L10.3912 6.84956C10.6352 6.60548 11.031 6.60548 11.275 6.84956Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default RepliesIcon;
