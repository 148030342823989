import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DatabaseIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.54082 6.31421C5.54079 6.31421 5.54086 6.3136 5.54113 6.31238L5.54082 6.31421ZM5.54329 6.30493C5.55294 6.27652 5.59758 6.1835 5.78753 6.0338C6.04979 5.82712 6.48286 5.60318 7.09505 5.39912C8.3104 4.994 10.0444 4.7286 11.9993 4.7286C13.9542 4.7286 15.6882 4.994 16.9036 5.39912C17.5158 5.60318 17.9488 5.82712 18.2111 6.0338C18.401 6.1835 18.4457 6.27652 18.4553 6.30493C18.4457 6.33333 18.401 6.42636 18.2111 6.57605C17.9488 6.78274 17.5158 7.00667 16.9036 7.21074C15.6882 7.61585 13.9542 7.88126 11.9993 7.88126C10.0444 7.88126 8.3104 7.61585 7.09505 7.21074C6.48286 7.00667 6.04979 6.78274 5.78753 6.57605C5.59758 6.42636 5.55294 6.33334 5.54329 6.30493ZM18.4578 6.31421C18.4578 6.31421 18.4576 6.31361 18.4575 6.31239L18.4578 6.31421ZM18.4575 6.29747C18.4576 6.29624 18.4578 6.29564 18.4578 6.29564L18.4575 6.29747ZM5.54112 6.29747C5.54086 6.29625 5.54079 6.29565 5.54082 6.29564L5.54112 6.29747ZM6.54834 3.75899C7.98312 3.28073 9.91027 2.99976 11.9993 2.99976C14.0883 2.99976 16.0155 3.28073 17.4503 3.75899C18.1631 3.99662 18.8024 4.29862 19.2812 4.67595C19.7502 5.0456 20.186 5.58955 20.186 6.30493C20.186 7.02031 19.7502 7.56425 19.2812 7.9339C18.8024 8.31123 18.1631 8.61324 17.4503 8.85086C16.0155 9.32912 14.0883 9.6101 11.9993 9.6101C9.91027 9.6101 7.98312 9.32912 6.54834 8.85086C5.83547 8.61324 5.19622 8.31123 4.71742 7.9339C4.24838 7.56425 3.81264 7.02031 3.81264 6.30493C3.81264 5.58955 4.24838 5.0456 4.71742 4.67595C5.19622 4.29862 5.83547 3.99662 6.54834 3.75899Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.54147 11.995C5.53883 11.5199 5.15283 11.1355 4.67706 11.1355C4.19965 11.1355 3.81264 11.5225 3.81264 11.9999C3.81264 12.713 4.24354 13.2571 4.71225 13.6285C5.18958 14.0068 5.8272 14.309 6.53898 14.5467C7.97149 15.0249 9.89899 15.3051 11.9993 15.3051C14.0996 15.3051 16.0271 15.0249 17.4596 14.5467C18.1714 14.309 18.809 14.0068 19.2864 13.6285C19.7551 13.2571 20.186 12.713 20.186 11.9999C20.186 11.5225 19.799 11.1355 19.3216 11.1355C18.8458 11.1355 18.4598 11.5198 18.4572 11.995C18.453 12.0128 18.4201 12.1091 18.2126 12.2735C17.9527 12.4795 17.5225 12.7031 16.9122 12.9068C15.7005 13.3113 13.9669 13.5762 11.9993 13.5762C10.0317 13.5762 8.29808 13.3113 7.08645 12.9068C6.47616 12.7031 6.04595 12.4795 5.78597 12.2735C5.57847 12.1091 5.54566 12.0128 5.54147 11.995ZM18.4577 11.992C18.4577 11.992 18.4577 11.9926 18.4574 11.994L18.4577 11.992ZM5.54123 11.994C5.54096 11.9926 5.54089 11.992 5.54093 11.992L5.54123 11.994Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67706 5.44037C5.15447 5.44037 5.54148 5.82738 5.54148 6.30479V17.6901C5.54573 17.7081 5.5787 17.8043 5.78597 17.9686C6.04595 18.1746 6.47616 18.3981 7.08645 18.6019C8.29808 19.0064 10.0317 19.2713 11.9993 19.2713C13.9669 19.2713 15.7005 19.0064 16.9122 18.6019C17.5225 18.3981 17.9527 18.1746 18.2126 17.9686C18.4199 17.8043 18.4529 17.7081 18.4571 17.6901V6.30479C18.4571 5.82738 18.8442 5.44037 19.3216 5.44037C19.799 5.44037 20.186 5.82738 20.186 6.30479V17.695C20.186 18.4081 19.7551 18.9522 19.2864 19.3236C18.809 19.7018 18.1714 20.0041 17.4596 20.2417C16.0271 20.72 14.0996 21.0001 11.9993 21.0001C9.89899 21.0001 7.97149 20.72 6.53898 20.2417C5.8272 20.0041 5.18958 19.7018 4.71225 19.3236C4.24354 18.9522 3.81264 18.4081 3.81264 17.695V6.30479C3.81264 5.82738 4.19965 5.44037 4.67706 5.44037ZM18.4577 17.687C18.4577 17.687 18.4577 17.6877 18.4574 17.6891L18.4577 17.687ZM5.54093 17.687C5.54097 17.687 5.54112 17.6877 5.54125 17.6891L5.54093 17.687Z"
      fill="white"
    />
  </SvgIcon>
);

export default DatabaseIcon;
