import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AchIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      d="M3.64307 5.99972C3.64307 5.52633 4.02682 5.14258 4.50021 5.14258H20.5002C20.9736 5.14258 21.3574 5.52633 21.3574 5.99972V10.9304C21.3574 11.1578 21.267 11.3758 21.1063 11.5365C20.5663 12.0765 19.6431 11.6941 19.6431 10.9304V6.85686H5.35735V17.1426H11.4307C11.658 17.1426 11.876 17.2329 12.0368 17.3936C12.5767 17.9336 12.1943 18.8569 11.4307 18.8569H4.50021C4.02682 18.8569 3.64307 18.4731 3.64307 17.9997V5.99972Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1365 13.8632C21.488 14.2147 21.488 14.7845 21.1365 15.136L16.5001 19.7724L13.8637 17.136C13.5122 16.7845 13.5122 16.2147 13.8637 15.8632C14.2152 15.5117 14.785 15.5117 15.1365 15.8632L16.5001 17.2268L19.8637 13.8632C20.2152 13.5117 20.785 13.5117 21.1365 13.8632Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.1001 12.9996C7.1001 12.5026 7.50304 12.0996 8.0001 12.0996H12.0001C12.4972 12.0996 12.9001 12.5026 12.9001 12.9996C12.9001 13.4967 12.4972 13.8996 12.0001 13.8996H8.0001C7.50304 13.8996 7.1001 13.4967 7.1001 12.9996Z"
      fill="currentColor"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 8.39961H5V6.59961H20V8.39961Z" fill="currentColor" />
  </SvgIcon>
);

export default AchIcon;
