import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { rem } from '@app/utils/sx/functions';

const Exclamation = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 20 20"
    {...props}
    sx={{
      color: 'primary.main',
      fontSize: rem(20),
      ...sx
    }}
  >
    <rect width="20" height="20" rx="10" fill="currentColor" fillOpacity="0.1"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0002 4.5C10.683 4.5 11.3657 5.01206 11.3657 6.03617L10.8537 10.6447C10.8537 11.116 10.4716 11.4981 10.0002 11.4981C9.52891 11.4981 9.14682 11.116 9.14682 10.6447L8.63477 6.03617C8.63477 5.01206 9.31751 4.5 10.0002 4.5Z" fill="currentColor"/>
    <circle cx="10.0002" cy="14.2293" r="1.36548" fill="currentColor"/>
  </SvgIcon>
);

export default Exclamation;
