import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const Calendar = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 6.75C6.33579 6.75 6 7.08579 6 7.5V18C6 18.4142 6.33579 18.75 6.75 18.75H17.25C17.6642 18.75 18 18.4142 18 18V7.5C18 7.08579 17.6642 6.75 17.25 6.75H6.75ZM4.5 7.5C4.5 6.25736 5.50736 5.25 6.75 5.25H17.25C18.4926 5.25 19.5 6.25736 19.5 7.5V18C19.5 19.2426 18.4926 20.25 17.25 20.25H6.75C5.50736 20.25 4.5 19.2426 4.5 18V7.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 3.75C15.4142 3.75 15.75 4.08579 15.75 4.5V7.5C15.75 7.91421 15.4142 8.25 15 8.25C14.5858 8.25 14.25 7.91421 14.25 7.5V4.5C14.25 4.08579 14.5858 3.75 15 3.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3.75C9.41421 3.75 9.75 4.08579 9.75 4.5V7.5C9.75 7.91421 9.41421 8.25 9 8.25C8.58579 8.25 8.25 7.91421 8.25 7.5V4.5C8.25 4.08579 8.58579 3.75 9 3.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 10.5C4.5 10.0858 4.83579 9.75 5.25 9.75H18.75C19.1642 9.75 19.5 10.0858 19.5 10.5C19.5 10.9142 19.1642 11.25 18.75 11.25H5.25C4.83579 11.25 4.5 10.9142 4.5 10.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default Calendar;
