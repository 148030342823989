import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DealsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.70005 1.99999V16.1537L6.86606 12.9491C8.12414 11.9813 9.87596 11.9813 11.134 12.9491L15.3 16.1537L14.3855 17.3426L10.2195 14.138C9.50057 13.585 8.49953 13.585 7.78063 14.138L3.61462 17.3426C2.62819 18.1014 1.20005 17.3982 1.20005 16.1537V1.99999C1.20005 0.895424 2.09548 -5.72205e-06 3.20005 -5.72205e-06H14.8C15.9046 -5.72205e-06 16.8 0.895426 16.8 1.99999V16.1537C16.8 17.3982 15.3719 18.1014 14.3855 17.3426L15.3 16.1537V1.99999C15.3 1.72385 15.0762 1.49999 14.8 1.49999H3.20005C2.92391 1.49999 2.70005 1.72385 2.70005 1.99999Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default DealsIcon;
