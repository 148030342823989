import { SvgIconProps } from '@mui/material';
import React from 'react';

const CheckedDanger = (props: SvgIconProps): JSX.Element => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H21.5C23.7091 0 25.5 1.79086 25.5 4V21C25.5 23.2091 23.7091 25 21.5 25H4.5C2.29086 25 0.5 23.2091 0.5 21V4Z"
      fill="#F13572"
      fillOpacity="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2157 8.6561C18.5948 9.03514 18.5948 9.64969 18.2157 10.0287L11.7059 16.5386L7.78428 12.617C7.40524 12.2379 7.40524 11.6234 7.78428 11.2443C8.16332 10.8653 8.77786 10.8653 9.1569 11.2443L11.7059 13.7933L16.8431 8.6561C17.2221 8.27707 17.8367 8.27707 18.2157 8.6561Z"
      fill="#F13572"
    />
  </svg>
);

export default CheckedDanger;
