import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpLazyLoad from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Language } from './i18n.constant';

i18n
  .use(LanguageDetector)
  .use(HttpLazyLoad)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.en,
    whitelist: [Language.en],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    backend: {
      loadPath: '/locales/{{lng}}.json',
      allowMultiLoading: false,
      crossDomain: false,
      withCredentials: false,
      reloadInterval: false,
    },
  });

export default i18n;
