import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const RefreshIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_4818_97072)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1664 4.4834C21.6358 4.4834 22.0164 4.86396 22.0164 5.3334V10.3334C22.0164 10.8028 21.6358 11.1834 21.1664 11.1834H16.1664C15.697 11.1834 15.3164 10.8028 15.3164 10.3334C15.3164 9.86395 15.697 9.4834 16.1664 9.4834H20.3164V5.3334C20.3164 4.86396 20.697 4.4834 21.1664 4.4834Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9372 5.41728C11.5097 5.21479 10.055 5.48177 8.79235 6.17796C7.52973 6.87416 6.52758 7.96187 5.93692 9.27718C5.34627 10.5925 5.1991 12.0641 5.51761 13.4704C5.83611 14.8766 6.60303 16.1412 7.70279 17.0736C8.80255 18.0061 10.1756 18.5558 11.615 18.64C13.0543 18.7242 14.4821 18.3383 15.6831 17.5405C16.8841 16.7427 17.7932 15.5761 18.2735 14.2166C18.4299 13.774 18.9155 13.5419 19.3581 13.6983C19.8007 13.8547 20.0328 14.3403 19.8764 14.7829C19.2733 16.4899 18.1318 17.9547 16.6238 18.9565C15.1158 19.9583 13.323 20.4429 11.5157 20.3371C9.70833 20.2314 7.98431 19.5411 6.6034 18.3703C5.2225 17.1995 4.25953 15.6116 3.8596 13.8459C3.45968 12.0802 3.64446 10.2323 4.38611 8.58077C5.12776 6.92922 6.3861 5.56345 7.9715 4.68927C9.5569 3.8151 11.3835 3.47988 13.176 3.73413C14.9641 3.98776 16.6217 4.81428 17.9002 6.08953L21.7494 9.71427C22.0911 10.0361 22.1073 10.5741 21.7854 10.9158C21.4636 11.2576 20.9257 11.2737 20.5839 10.9519L16.7256 7.31857C16.7193 7.31267 16.7131 7.30668 16.7071 7.3006C15.6879 6.28074 14.3648 5.61977 12.9372 5.41728Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_4818_97072">
        <rect width="19" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default RefreshIcon;
