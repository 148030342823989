/**
 * Convert User filter with Wholesaler to User filter without Wholesaler + Wholesaler filter
 * Usage in data service's getList() method for convert role filter
 *
 *  Role filter === '1' means is Admin
 *  Role filter === '2' means is Manager
 *  Role filter === '3' skipped because it means a Buyer including a Wholesaler
 *  Role filter === '4' means is Receiver
 *  Role filter === '5' means is Buyer only (not is Wholesaler)
 *  Role filter === '6' means is Wholesaler only (not is Buyer)
 *
 * @param role: string | null; Role filter
 * @param wholesaler: string | null; default: null
 *
 * @returns { role: string | null, wholesaler: 'true' | 'false' | null}
 */
export function getUserRolesWithWholesaler(role: string | null, wholesaler: string | null = null) {
  const userRole = role ? role.split(',').filter((value) => value !== '5' && value !== '6') : [];
  if (role?.includes('5') && role?.includes('6')) {
    userRole.push('3');
  }
  if (role?.includes('5') && !role?.includes('6')) {
    userRole.push('3');
    wholesaler = 'false';
  }
  if (role?.includes('6') && !role?.includes('5')) {
    userRole.push('3');
    wholesaler = 'true';
  }

  return {
    role: userRole.length > 0 ? userRole.join(',') : null,
    wholesaler,
  };
}
