import React, { forwardRef } from 'react';
import Scrollbar, { ScrollbarProps } from 'react-scrollbars-custom';
import { ScrollState } from 'react-scrollbars-custom/dist/types/types';
import './CustomScrollbar.scss';

interface Props extends ScrollbarProps {
  height: string | number;
  skipScroll?: boolean;
  handleScroll?: (scrollValues: ScrollState, prevScrollState: ScrollState) => void;
}

const CustomScrollbar = forwardRef(
  ({ height, skipScroll, children, style, handleScroll, ...props }: Props, ref): JSX.Element => {
    if (skipScroll) {
      return <>{children}</>;
    }

    const innerStyle =
      height === 'auto'
        ? {
            flexGrow: 1,
            width: 'auto',
            height: 'auto',
          }
        : {
            height,
          };

    return (
      <Scrollbar style={{ ...style, ...innerStyle }} onScroll={handleScroll} ref={ref} {...(props as any)}>
        {children}
      </Scrollbar>
    );
  }
);

export default CustomScrollbar;
