import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ExternalLinkIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 10 10"
    fill="none"
    sx={{
      fill: 'none',
      color: 'text.auxiliary',
      fontSize: rem(10),
      ...sx,
    }}
    {...props}
  >
    <path
      d="M7.5 5.41667V7.91667C7.5 8.13768 7.4122 8.34964 7.25592 8.50592C7.09964 8.6622 6.88768 8.75 6.66667 8.75H2.08333C1.86232 8.75 1.65036 8.6622 1.49408 8.50592C1.3378 8.34964 1.25 8.13768 1.25 7.91667V3.33333C1.25 3.11232 1.3378 2.90036 1.49408 2.74408C1.65036 2.5878 1.86232 2.5 2.08333 2.5H4.58333"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.25 1.25H8.75V3.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.16663 5.83333L8.74996 1.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default ExternalLinkIcon;
