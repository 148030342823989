import { SvgIconProps } from '@mui/material';
import React from 'react';

const Serials = (props: SvgIconProps): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.20001 3.15002C1.20001 2.73581 1.5358 2.40002 1.95001 2.40002C2.36423 2.40002 2.70001 2.73581 2.70001 3.15002V8.40002H4.79999V3.15002C4.79999 2.73581 5.13577 2.40002 5.54999 2.40002C5.9642 2.40002 6.29999 2.73581 6.29999 3.15002V8.40002H8.40002V3.15002C8.40002 2.73581 8.73581 2.40002 9.15002 2.40002C9.56424 2.40002 9.90002 2.73581 9.90002 3.15002V8.40002H12V3.15002C12 2.73581 12.3358 2.40002 12.75 2.40002C13.1642 2.40002 13.5 2.73581 13.5 3.15002V8.40002L15.6 8.40002V3.15002C15.6 2.73581 15.9358 2.40002 16.35 2.40002C16.7642 2.40002 17.1 2.73581 17.1 3.15002V8.40002H17.25C17.6642 8.40002 18 8.73581 18 9.15002C18 9.56424 17.6642 9.90002 17.25 9.90002L0.75 9.90002C0.335787 9.90002 0 9.56424 0 9.15002C0 8.73581 0.335787 8.40002 0.75 8.40002H1.20001V3.15002ZM1.20001 11.55C1.20001 11.1358 1.5358 10.8 1.95001 10.8C2.36423 10.8 2.70001 11.1358 2.70001 11.55V14.85C2.70001 15.2642 2.36423 15.6 1.95001 15.6C1.5358 15.6 1.20001 15.2642 1.20001 14.85V11.55ZM4.79999 11.55C4.79999 11.1358 5.13577 10.8 5.54999 10.8C5.9642 10.8 6.29999 11.1358 6.29999 11.55V13.65C6.29999 14.0642 5.9642 14.4 5.54999 14.4C5.13577 14.4 4.79999 14.0642 4.79999 13.65V11.55ZM8.40002 11.55C8.40002 11.1358 8.73581 10.8 9.15002 10.8C9.56424 10.8 9.90002 11.1358 9.90002 11.55V12.45C9.90002 12.8642 9.56424 13.2 9.15002 13.2C8.73581 13.2 8.40002 12.8642 8.40002 12.45V11.55ZM12.75 10.8C12.3358 10.8 12 11.1358 12 11.55V13.65C12 14.0642 12.3358 14.4 12.75 14.4C13.1642 14.4 13.5 14.0642 13.5 13.65V11.55C13.5 11.1358 13.1642 10.8 12.75 10.8ZM16.35 10.8C15.9358 10.8 15.6 11.1358 15.6 11.55V14.85C15.6 15.2642 15.9358 15.6 16.35 15.6C16.7642 15.6 17.1 15.2642 17.1 14.85V11.55C17.1 11.1358 16.7642 10.8 16.35 10.8Z"
      fill="currentColor"
    />
  </svg>
);

export default Serials;
