import { HttpStatusCode } from '@app/api/http/constants/http-status-code';
import { ValidationError } from '@app/api/http/types';
import { AxiosError } from 'axios';

export const getErrorMessage = (errorResult: AxiosError): string => {
  if (errorResult?.response?.data?.code === HttpStatusCode.BadGateway) {
    return 'Oops... Something went wrong.';
  }

  if (!errorResult.response) {
    return 'Please check your internet connection.';
  }

  if (errorResult?.response?.data?.result) {
    const { result } = errorResult.response.data;

    if (typeof result === 'string') {
      return result;
    }

    if (Array.isArray(result)) {
      return result.map(({ message }: ValidationError) => message).join(' ');
    }

    return result.message;
  }

  return 'Oops... Something went wrong.';
};
