import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const LogoutIcon = ({ ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 25 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.44444 6.25C7.26027 6.25 7.08363 6.32316 6.9534 6.4534C6.82316 6.58363 6.75 6.76027 6.75 6.94444V17.0556C6.75 17.2397 6.82316 17.4164 6.9534 17.5466C7.08363 17.6768 7.26027 17.75 7.44444 17.75H10.3333V19.25H7.44444C6.86244 19.25 6.30428 19.0188 5.89274 18.6073C5.4812 18.1957 5.25 17.6376 5.25 17.0556V6.94444C5.25 6.36244 5.4812 5.80428 5.89274 5.39274C6.30428 4.9812 6.86244 4.75 7.44444 4.75H10.3333V6.25H7.44444ZM15.9191 7.8584L19.5302 11.4695C19.8231 11.7624 19.8231 12.2373 19.5302 12.5302L15.9191 16.1413L14.8584 15.0806L17.189 12.75H10.3335V11.25H17.1893L14.8584 8.91906L15.9191 7.8584Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default LogoutIcon;
