import { useCustomQuery } from '@app/api/hooks/useCustomQuery';
import { QueryKeys } from '@app/api/queryKeys';
import { AppConfig, ConfigService } from '@app/utils/services/configService';
import { AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';

export function useConfigQuery(options?: UseQueryOptions<AppConfig, AxiosError, AppConfig>) {
  const { isLoading, data } = useCustomQuery<AppConfig>(QueryKeys.Config, ConfigService.get, {
    ...options,
  });

  return {
    isLoading,
    data,
  };
}
