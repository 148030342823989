import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowForwardIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path d="M4 11H16.17L12.59 7.41L14 6L20 12L14 18L12.59 16.59L16.17 13H4V11Z" fill="currentColor" />
  </SvgIcon>
);

export default ArrowForwardIcon;
