import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { rem } from '@app/utils/sx/functions';

const WholesalerIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 12 12"
    {...props}
    sx={{
        color: 'primary.main',
        fontSize: rem(12),
        ...sx
    }}
  >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.5 6.5H1.5L1.5 10.5H5.5V6.5ZM1.5 5.5C0.947715 5.5 0.5 5.94772 0.5 6.5V10.5C0.5 11.0523 0.947715 11.5 1.5 11.5H6.5V6.5C6.5 5.94772 6.05228 5.5 5.5 5.5H1.5Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5 6.5H6.5V10.5H10.5V6.5ZM6.5 5.5C5.94772 5.5 5.5 5.94772 5.5 6.5V11.5H10.5C11.0523 11.5 11.5 11.0523 11.5 10.5V6.5C11.5 5.94772 11.0523 5.5 10.5 5.5H6.5Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 1.5H4V5.5H8V1.5ZM4 0.5C3.44772 0.5 3 0.947715 3 1.5V5.5C3 6.05228 3.44772 6.5 4 6.5H8C8.55228 6.5 9 6.05228 9 5.5V1.5C9 0.947715 8.55228 0.5 8 0.5H4Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.5 0.5H5.5V3.5H6.5V0.5Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4 5.5H3V8.5H4V5.5Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 5.5H8V8.5H9V5.5Z" fill="currentColor"/>
  </SvgIcon>
);

export default WholesalerIcon;
