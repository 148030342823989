import { UserRoles } from '@app/pages/users/types';
import { Credentials } from '@app/utils/services/types';

class CredentialsService {
  private _credentials: Credentials | null = null;
  private credentialsKey = 'credentials';

  constructor() {
    const savedCredentials = localStorage.getItem(this.credentialsKey);

    if (savedCredentials) {
      try {
        this._credentials = JSON.parse(savedCredentials) as Credentials;
      } catch (e) {
        this._credentials = null;
        localStorage.removeItem(this.credentialsKey);
      }
    }
  }

  /**
   * Gets the user role from credentials.
   *
   * @return The user role or null if the user is not authenticated.
   */
  public get role(): UserRoles | null {
    return this._credentials ? this._credentials.roleId : null;
  }

  /**
   * Gets the token from credentials.
   *
   * @return The token or null if the user is not authenticated.
   */
  public get token(): string | null {
    return this._credentials ? this._credentials.token : null;
  }

  /**
   * Gets the user credentials.
   *
   * @return The user credentials or null if the user is not authenticated.
   */
  public get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials persisted across sessions.
   *
   * @param credentials The user credentials.
   */
  public set(credentials: Credentials): void {
    this._credentials = credentials;

    localStorage.setItem(this.credentialsKey, JSON.stringify(credentials));
  }

  public flush(): void {
    this._credentials = null;
    localStorage.removeItem(this.credentialsKey);
  }
}

export const credentialsService = new CredentialsService();
