import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CreditAndChargesIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <g clipPath="url(#clip0_3259_92531)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.53026 6.53027C1.44849 7.47173 0 9.56671 0 12C0 15.3137 2.68629 18 6 18C8.4333 18 10.5283 16.5515 11.4697 14.4698C10.821 14.7631 10.1109 14.9445 9.36384 14.9892C8.53957 15.9161 7.33798 16.5 6 16.5C3.51472 16.5 1.5 14.4853 1.5 12C1.5 10.662 2.08393 9.46044 3.01085 8.63618C3.05553 7.88916 3.23689 7.17897 3.53026 6.53027Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53026 3.53027C4.44849 4.47173 3 6.56671 3 9.00002C3 12.3137 5.68629 15 9 15C11.4333 15 13.5283 13.5515 14.4697 11.4698C13.821 11.7631 13.1109 11.9445 12.3638 11.9892C11.5396 12.9161 10.338 13.5 9 13.5C6.51472 13.5 4.5 11.4853 4.5 9.00002C4.5 7.66204 5.08393 6.46044 6.01085 5.63618C6.05553 4.88916 6.23689 4.17897 6.53026 3.53027Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.5C14.4853 10.5 16.5 8.48528 16.5 6C16.5 3.51472 14.4853 1.5 12 1.5C9.51472 1.5 7.5 3.51472 7.5 6C7.5 8.48528 9.51472 10.5 12 10.5ZM12 12C15.3137 12 18 9.31371 18 6C18 2.68629 15.3137 0 12 0C8.68629 0 6 2.68629 6 6C6 9.31371 8.68629 12 12 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2641 2.85466C10.2505 2.92445 9.45 3.76874 9.45 4.8C9.45 5.87696 10.323 6.75 11.4 6.75H12.6C12.8485 6.75 13.05 6.95147 13.05 7.2C13.05 7.44853 12.8485 7.65 12.6 7.65H10.8C10.3858 7.65 10.05 7.98579 10.05 8.4C10.05 8.81421 10.3858 9.15 10.8 9.15H11.265C11.3345 9.49234 11.6372 9.75 12 9.75C12.3645 9.75 12.6682 9.48999 12.7359 9.14534C13.7495 9.07555 14.55 8.23126 14.55 7.2C14.55 6.12304 13.677 5.25 12.6 5.25H11.4C11.1515 5.25 10.95 5.04853 10.95 4.8C10.95 4.55147 11.1515 4.35 11.4 4.35H13.2C13.6142 4.35 13.95 4.01421 13.95 3.6C13.95 3.18579 13.6142 2.85 13.2 2.85H12.735C12.6655 2.50766 12.3628 2.25 12 2.25C11.6355 2.25 11.3317 2.51001 11.2641 2.85466Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3259_92531">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default CreditAndChargesIcon;
