import { SvgIconProps } from '@mui/material';
import React from 'react';

const LinkIcon = (props: SvgIconProps): JSX.Element => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H24C26.2091 0 28 1.79086 28 4V24C28 26.2091 26.2091 28 24 28H4C1.79086 28 0 26.2091 0 24V4Z"
      fill="#F4F6FA"
    />
    <path
      d="M7.52 14C7.52 12.632 8.632 11.52 10 11.52H13.2V10H10C7.792 10 6 11.792 6 14C6 16.208 7.792 18 10 18H13.2V16.48H10C8.632 16.48 7.52 15.368 7.52 14ZM10.8 14.8H17.2V13.2H10.8V14.8ZM18 10H14.8V11.52H18C19.368 11.52 20.48 12.632 20.48 14C20.48 15.368 19.368 16.48 18 16.48H14.8V18H18C20.208 18 22 16.208 22 14C22 11.792 20.208 10 18 10Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkIcon;
