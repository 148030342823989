import { SIDEBAR_WIDTH } from '@app/constants/main-layout';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { styled } from '@mui/material/styles';
import React from 'react';
import DesktopDrawer from './DesktopDrawer';
import MobileDrawer from './MobileDrawer';
import styles from './Sidebar.module.scss';

interface Props {
  expanded?: boolean;
}

const Container = styled('aside', {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<Props>(({ theme, expanded }) => ({
  flexShrink: 0,
  ...(expanded && {
    [theme.breakpoints.up('md')]: {
      width: `${SIDEBAR_WIDTH}px`,
    },
  }),
  ...(!expanded && {}),
}));

const Sidebar = (): JSX.Element => {
  const { expanded } = useMainLayoutContext();

  return (
    <Container expanded={expanded} className={styles.Aside}>
      <MobileDrawer />
      <DesktopDrawer />
    </Container>
  );
};

export default Sidebar;
