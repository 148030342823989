import { credentialsService } from '@app/utils/services/credentialsService';
import { AxiosRequestConfig } from 'axios';

export const apiInterceptor = (config: AxiosRequestConfig) => {
  const url = config.url as string;
  const token = credentialsService.token;

  if (!/^(http|https):/i.test(url)) {
    const headers: { [key: string]: string } = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    config.headers = {
      ...config.headers,
      ...headers,
    };

    config.url = `${url.replace(/^\/+/, '')}`;

    return config;
  }

  return config;
};
