import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface FormDirtyValue {
  isDirty: boolean;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  children: ReactNode;
}

const FormDirtyContext = createContext<FormDirtyValue>({
  isDirty: false,
  setIsDirty: () => {},
});

export const useFormDirtyContext = () => useContext(FormDirtyContext);

const FormDirtyProvider = ({ children }: Props): JSX.Element => {
  const [isDirty, setIsDirty] = useState(false);

  return <FormDirtyContext.Provider value={{ isDirty, setIsDirty }}>{children}</FormDirtyContext.Provider>;
};

export default FormDirtyProvider;
