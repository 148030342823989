import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const OkIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M11.0006 17.657L6.05086 12.7073C5.66033 12.3167 5.66033 11.6836 6.05086 11.293C6.44138 10.9025 7.07455 10.9025 7.46507 11.293L11.0006 14.8286L17.5361 8.29304C17.9267 7.90252 18.5598 7.90252 18.9504 8.29304C19.3409 8.68357 19.3409 9.31673 18.9504 9.70726L11.0006 17.657Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default OkIcon;
