import { rem } from '@app/utils/sx/functions';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const listItemIcon = (expanded: boolean): SxProps<Theme> => {
  return {
    minWidth: 'auto',
    mr: expanded ? rem(16) : 0,
    svg: {
      fontSize: rem(18),
    },
  };
};
