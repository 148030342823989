import React, { useState } from 'react';

export const useMenuToggle = (handleMenuClose?: () => void) => {
  const [anchorEl, setEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setEl(event.currentTarget);
  };

  const closeMenu = () => {
    setEl(null);

    if (handleMenuClose) {
      handleMenuClose();
    }
  };

  return {
    openMenu,
    closeMenu,
    anchorEl,
    open,
  };
};
