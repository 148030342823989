import DefaultPageHeader from '@app/components/DefaultPageHeader';
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

const defaultValue = {
  mobileOpen: false,
  handleDrawerToggle: () => {},
  expanded: true,
  handleExpand: () => {},
  handleCollapse: () => {},
  header: '',
  setHeader: (header: string) => {},
  content: <DefaultPageHeader />,
  setContent: ((content: ReactNode) => {}) as Dispatch<SetStateAction<JSX.Element>>,
  viewportHeight: true,
  setViewportHeight: ((state: boolean) => {}) as Dispatch<SetStateAction<boolean>>,
  mainPaddingTop: 0,
  setMainPaddingTop: ((state: number) => {}) as Dispatch<SetStateAction<number>>,
  resetMainPaddingTop: () => {},
};

const MainLayoutContext = createContext<typeof defaultValue>(defaultValue);

export const useMainLayoutContext = () => useContext(MainLayoutContext);

export const useSetPageHeader = () => {
  return useMainLayoutContext().setHeader;
};

interface Props {
  children: ReactNode;
}

const MainLayoutProvider = ({ children }: Props): JSX.Element => {
  const [mobileOpen, setMobileOpen] = useState(defaultValue.mobileOpen);
  const [expanded, setExpanded] = useState(defaultValue.expanded);
  const [header, setHeader] = useState(defaultValue.header);
  const [content, setContent] = useState(defaultValue.content);
  const [viewportHeight, setViewportHeight] = useState(defaultValue.viewportHeight);
  const [mainPaddingTop, setMainPaddingTop] = useState(defaultValue.mainPaddingTop);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExpand = () => {
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  const resetMainPaddingTop = () => {
    setMainPaddingTop(defaultValue.mainPaddingTop);
  };

  return (
    <MainLayoutContext.Provider
      value={{
        mobileOpen,
        handleDrawerToggle,
        expanded,
        handleExpand,
        handleCollapse,
        header,
        setHeader,
        content,
        setContent,
        viewportHeight,
        setViewportHeight,
        mainPaddingTop,
        setMainPaddingTop,
        resetMainPaddingTop,
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export default MainLayoutProvider;
