import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { parseLocationSearch } from '@app/utils/format/locationSearch';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export const useSearch = () => {
  const ref = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const history = useHistory();
  const searchWord = parseLocationSearch(location.search, ['searchWord'])?.searchWord || '';
  const [value, setValue] = useState(searchWord);
  const { mobileOpen, handleDrawerToggle } = useMainLayoutContext();

  useEffect(() => {
    if (searchWord === '') {
      setValue('');
    }
  }, [searchWord]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const doSearch = () => {
    const search = value.trim();
    if (search.length < 2) {
      return;
    }

    history.push({
      pathname: `/search-results`,
      search: `searchWord=${encodeURIComponent(search)}`,
      state: { backLocation: location },
    });
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    doSearch();
  };

  const clear = () => {
    setValue('');
    (ref.current as HTMLElement)?.focus();
  };

  return { ref, value, handleChange, handleSubmit, doSearch, clear };
};
