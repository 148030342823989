import { httpClient } from '@app/api/http/http-client';

class EmailService {
  public newEmail(token: string): Promise<void> {
    return httpClient
      .post<void>('/email/verify-new', { token })
      .then(() => {});
  }
}

export const emailService = new EmailService();
