import { setLocale } from 'yup';

export const initValidationConfig = () =>
  setLocale({
    mixed: {
      required: ({ label }: { label: string }) => ({ key: 'validation.required', values: { label } }),
    },
    string: {
      matches: ({ label }: { label: string }) => ({ key: 'validation.invalid', values: { label } }),
    },
  });
