import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const RequestIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      ...sx,
      fontSize: '18px',
    }}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3.75C4.30109 3.75 4.11032 3.82902 3.96967 3.96967C3.82902 4.11032 3.75 4.30109 3.75 4.5V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V4.5C14.25 4.30109 14.171 4.11032 14.0303 3.96967C13.8897 3.82902 13.6989 3.75 13.5 3.75H12C11.5858 3.75 11.25 3.41421 11.25 3C11.25 2.58579 11.5858 2.25 12 2.25H13.5C14.0967 2.25 14.669 2.48705 15.091 2.90901C15.5129 3.33097 15.75 3.90326 15.75 4.5V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V4.5C2.25 3.90326 2.48705 3.33097 2.90901 2.90901C3.33097 2.48705 3.90326 2.25 4.5 2.25H6C6.41421 2.25 6.75 2.58579 6.75 3C6.75 3.41421 6.41421 3.75 6 3.75H4.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5303 7.46967C12.8232 7.76256 12.8232 8.23744 12.5303 8.53033L8.53033 12.5303C8.23744 12.8232 7.76256 12.8232 7.46967 12.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L8 10.9393L11.4697 7.46967C11.7626 7.17678 12.2374 7.17678 12.5303 7.46967Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 2.25C5.25 1.42157 5.92157 0.75 6.75 0.75H11.25C12.0784 0.75 12.75 1.42157 12.75 2.25V3.75C12.75 4.57843 12.0784 5.25 11.25 5.25H6.75C5.92157 5.25 5.25 4.57843 5.25 3.75V2.25ZM11.25 2.25L6.75 2.25V3.75H11.25V2.25Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default RequestIcon;
