/**
 * Here we divide the application into components according to the layouts that are used.
 */

import ErrorBoundary from '@app/components/ErrorBoundary';
import ConfigProvider from '@app/contexts/config';
import SnackbarProvider from '@app/contexts/snackbar';
import VerifyEmail from '@app/pages/VerifyEmail';
import AuthLayoutRoutes from '@app/routes/AuthLayoutRoutes';
import MainLayoutRoutes from '@app/routes/MainLayoutRoutes';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const AppRoutes = (): JSX.Element => {
  return (
    <ErrorBoundary>
      <Router>
        <ConfigProvider>
          <SnackbarProvider>
            <Switch>
              <Route path="/auth/:path?" component={AuthLayoutRoutes} />

              <Route path="/user/change-email/:token" component={VerifyEmail} />

              <Route component={MainLayoutRoutes} />
            </Switch>
          </SnackbarProvider>
        </ConfigProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default AppRoutes;
