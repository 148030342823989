import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NotesIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 18"
    sx={{
      color: 'primary.main',
      fontSize: '18px',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2C18 0.895431 17.1046 0 16 0H2C0.895431 0 0 0.89543 0 2V16C0 17.1046 0.895431 18 2 18H10.75V13.75C10.75 12.0931 12.0931 10.75 13.75 10.75H18V2ZM12.25 18L18 12.25H14.25C13.1454 12.25 12.25 13.1454 12.25 14.25V18Z"
      fill="currentColor"
    />
    <path d="M5.5 8.5L8 11L12.5 6.5" stroke="white" strokeWidth="1.5" />
  </SvgIcon>
);

export default NotesIcon;
