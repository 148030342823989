import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const BookOpenIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.70825 6C4.70825 5.65482 4.98807 5.375 5.33325 5.375H9.33325C10.2063 5.375 11.0435 5.7218 11.6608 6.33911C12.2781 6.95641 12.6249 7.79366 12.6249 8.66667V18C12.6249 18.3452 12.3451 18.625 11.9999 18.625C11.6547 18.625 11.3749 18.3452 11.3749 18C11.3749 17.6353 11.2301 17.2856 10.9722 17.0277C10.7143 16.7699 10.3646 16.625 9.99992 16.625H5.33325C4.98807 16.625 4.70825 16.3452 4.70825 16V6ZM11.3749 15.7639V8.66667C11.3749 8.12518 11.1598 7.60588 10.7769 7.22299C10.394 6.8401 9.87474 6.625 9.33325 6.625H5.95825V15.375H9.99992C10.4894 15.375 10.9647 15.5117 11.3749 15.7639Z"
      fill="#212B36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3391 6.33911C12.9564 5.7218 13.7937 5.375 14.6667 5.375H18.6667C19.0118 5.375 19.2917 5.65482 19.2917 6V16C19.2917 16.3452 19.0118 16.625 18.6667 16.625H14C13.6353 16.625 13.2856 16.7699 13.0277 17.0277C12.7699 17.2856 12.625 17.6353 12.625 18C12.625 18.3452 12.3452 18.625 12 18.625C11.6548 18.625 11.375 18.3452 11.375 18V8.66667C11.375 7.79366 11.7218 6.95641 12.3391 6.33911ZM12.625 15.7639C13.0352 15.5117 13.5106 15.375 14 15.375H18.0417V6.625H14.6667C14.1252 6.625 13.6059 6.8401 13.223 7.22299C12.8401 7.60588 12.625 8.12518 12.625 8.66667V15.7639Z"
      fill="#212B36"
    />
  </SvgIcon>
);

export default BookOpenIcon;
