import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ReturnTaskIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.43945 4.50006C7.73235 4.20716 8.20722 4.20716 8.50011 4.50006C8.79301 4.79295 8.79301 5.26782 8.50011 5.56072L6.81077 7.25006H17.0001C18.5189 7.25006 19.7501 8.48127 19.7501 10.0001V15.5001C19.7501 17.0188 18.5189 18.2501 17.0001 18.2501H10.0001C8.48133 18.2501 7.25011 17.0188 7.25011 15.5001V15.2501C7.25011 14.8358 7.5859 14.5001 8.00011 14.5001C8.41433 14.5001 8.75011 14.8358 8.75011 15.2501V15.5001C8.75011 16.1904 9.30976 16.7501 10.0001 16.7501H17.0001C17.6905 16.7501 18.2501 16.1904 18.2501 15.5001V10.0001C18.2501 9.3097 17.6905 8.75006 17.0001 8.75006H6.81077L8.50011 10.4394C8.79301 10.7323 8.79301 11.2072 8.50011 11.5001C8.20722 11.793 7.73235 11.793 7.43945 11.5001L3.93945 8.00006L7.43945 4.50006Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ReturnTaskIcon;
