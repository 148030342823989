import PlusEllipse from '@app/components/Icons/PlusEllipse';
import { usePermissions } from '@app/contexts/currentUser';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { MainMenuItem } from '@app/layouts/MainLayout/Sidebar/MainMenu/mainMenuItems';
import { useAccessDenied } from '@app/layouts/MainLayout/Sidebar/MainMenu/useAccessDenied';
import { ListItem, ListItemButton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React, { forwardRef, MouseEvent, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NavLink, NavLinkProps } from 'react-router-dom';

interface Props {
  children: ReactNode;
  item: MainMenuItem;
  open: boolean;
  toggleOpen: (event: MouseEvent<HTMLElement>) => void;
}

interface ItemProps {
  open: boolean;
}

const StyledListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ItemProps>(({ theme, open }) => ({
  '&:hover': { background: '#1d215b' },
  ...(open && {
    background: '#1d215b',
    '& .MuiListItemText-root': {
      color: 'primary.main',
    },
  }),
}));

const MenuItemComponent = ({ children, item, open, toggleOpen }: Props): JSX.Element => {
  const { link, add, href } = item;
  const { mobileOpen, handleDrawerToggle } = useMainLayoutContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const permissions = usePermissions();
  const accessDenied = useAccessDenied(item);
  const hasAddPermission = permissions.includes(add?.permission) || !add?.permission;
  const { expanded } = useMainLayoutContext();

  const handleItemClick = (event: MouseEvent<HTMLElement>) => {
    toggleOpen(event);

    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleAddClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    history.push({ pathname: add.link, state: { backlink: pathname } });
  };

  if (accessDenied) {
    return null;
  }

  if (href) {
    return (
      <ListItemButton
        component="a"
        href={href}
        target="_blank"
        sx={{
          '&.active': { background: '#1d215b', color: 'primary.main' },
          '&.active svg': { color: 'primary.main' },
          '&.active .MuiTypography-root': { color: 'primary.main' },
          '&:hover': { background: '#1d215b' },
          '&.MuiButtonBase-root': { textTransform: 'none' },
        }}
      >
        {children}
      </ListItemButton>
    );
  }

  if (!link || typeof link !== 'string') {
    return (
      <StyledListItem open={open} onClick={toggleOpen}>
        {children}
      </StyledListItem>
    );
  }

  // Return a LitItem with a link component
  return (
    <ListItem component="div" disablePadding style={{ position: 'relative' }}>
      <ListItemButton
        component={forwardRef((props: NavLinkProps, ref: any) => (
          <NavLink {...props} innerRef={ref} onClick={handleItemClick} />
        ))}
        to={{ pathname: link, state: { backlink: pathname } }}
        sx={{
          '&.active': { background: '#1d215b', color: 'primary.main' },
          '&.active svg': { color: 'primary.main' },
          '&.active .MuiTypography-root': { color: 'primary.main' },
          '&:hover': { background: '#1d215b' },
          '&.MuiButtonBase-root': { textTransform: 'none' },
        }}
      >
        {children}
        {add && expanded && hasAddPermission && (
          <IconButton
            sx={{
              p: 0,
              '& svg': {
                color: '#1d215b',
              },
            }}
            onClick={handleAddClick}
          >
            <PlusEllipse />
          </IconButton>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default MenuItemComponent;
