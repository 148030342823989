import { useAuth } from '@app/contexts/auth';
import { usePermissions } from '@app/contexts/currentUser';
import { Permission, UserRoles } from '@app/pages/users/types';
import { getDefaultFilter } from '@app/utils/helpers/getDefaultFilter';
import React from 'react';
import { RouteProps, useLocation } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

interface Props extends RouteProps {
  permission?: Permission;
  backPermission?: Permission;
  backUrl?: string;
  withDefaultFilter?: boolean;
}

const PrivateRoute = ({
  children,
  permission,
  backPermission,
  backUrl,
  location,
  withDefaultFilter,
  ...rest
}: Props): JSX.Element => {
  const { isAuthorized } = useAuth();
  const { pathname, state } = useLocation<{ backLocation: Location }>();
  const permissions = usePermissions();
  const { role } = useAuth();
  const hasPermission = permission ? permissions.includes(permission) : true;
  const hasListPermission = permission ? permissions.includes(backPermission) : true;
  const hasAccess = role === UserRoles.Receiver && !pathname.includes('receiver-terminal') ? false : hasPermission;
  const redirectTo = role === UserRoles.Receiver ? '/receiver-terminal' : hasListPermission ? backUrl : '/dashboard';
  if (withDefaultFilter) {
    location.search = location.search || `?createdDate=${getDefaultFilter()}`;
  }

  return (
    <Route location={location} {...rest}>
      {isAuthorized && hasAccess ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: isAuthorized ? redirectTo : '/auth/login',
            state: { backlink: pathname, backLocation: state?.backLocation },
          }}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;
