import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const OutgoingIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 18"
    sx={{
      ...sx,
      fill: 'none',
      fontSize: '18px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_3667_99971)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3299 17.7751C9.12178 17.877 8.87821 17.877 8.67008 17.7751L1.47008 14.2484C1.0981 14.0662 0.944247 13.617 1.12645 13.245C1.30866 12.873 1.75792 12.7192 2.1299 12.9014L8.99999 16.2664L15.8701 12.9014C16.2421 12.7192 16.6913 12.873 16.8735 13.245C17.0557 13.617 16.9019 14.0662 16.5299 14.2484L9.3299 17.7751Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1999 14.3252C15.7857 14.3252 15.4499 13.9894 15.4499 13.5752L15.45 4.93743L14.9829 4.70865C14.6109 4.52645 14.4571 4.07719 14.6393 3.7052C14.8215 3.33321 15.2708 3.17936 15.6427 3.36157L16.5299 3.79611C16.787 3.92205 16.95 4.18337 16.95 4.46966L16.9499 13.5752C16.9499 13.9894 16.6141 14.3252 16.1999 14.3252ZM8.99995 10.3506C8.58573 10.3506 8.24995 10.0148 8.24995 9.60056L8.24999 1.20056C8.25 0.911927 8.41563 0.648908 8.67592 0.524191C8.93621 0.399474 9.24498 0.435191 9.46993 0.616039L12.4699 3.02791C12.7927 3.28745 12.8441 3.75954 12.5845 4.08237C12.325 4.40519 11.8529 4.45649 11.5301 4.19696L9.74999 2.76585L9.74995 9.60056C9.74995 10.0148 9.41416 10.3506 8.99995 10.3506Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80007 14.3252C2.21429 14.3252 2.55007 13.9894 2.55007 13.5752L2.54999 4.93743L3.0299 4.70237C3.40189 4.52016 3.55574 4.0709 3.37353 3.69891C3.19133 3.32693 2.74207 3.17308 2.37008 3.35528L1.47008 3.79611C1.21297 3.92205 1.04999 4.18337 1.04999 4.46966L1.05007 13.5752C1.05007 13.9894 1.38586 14.3252 1.80007 14.3252ZM8.99999 10.3506C9.4142 10.3506 9.74999 10.0148 9.74999 9.60056L9.74999 1.20056C9.74999 0.911931 9.58436 0.64891 9.32407 0.524193C9.06378 0.399475 8.75501 0.435191 8.53006 0.616039L5.53006 3.02791C5.20724 3.28745 5.15593 3.75954 5.41547 4.08237C5.675 4.40519 6.1471 4.45649 6.46992 4.19696L8.24999 2.76585L8.24999 9.60056C8.24999 10.0148 8.58578 10.3506 8.99999 10.3506Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3667_99971">
        <rect width="18" height="18" fill="white" transform="translate(18 18) rotate(-180)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default OutgoingIcon;
