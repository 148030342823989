import CustomScrollbar from '@app/components/CustomScrollbar';
import { useCurrentUser } from '@app/contexts/currentUser';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { mainMenuItems } from '@app/layouts/MainLayout/Sidebar/MainMenu/mainMenuItems';
import MenuItem from '@app/layouts/MainLayout/Sidebar/MainMenu/MenuItem';
import { UserRoles } from '@app/pages/users/types';
import { List } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import styles from '../Sidebar.module.scss';

const MainMenu = (): JSX.Element => {
  const { expanded } = useMainLayoutContext();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const isEmployee = currentUser?.role === UserRoles.Admin || currentUser?.role === UserRoles.Manager;
  const items = mainMenuItems(isEmployee);
  const currentItem = items.find((item) => item.itemsLinks?.includes(location.pathname));
  const [open, setOpen] = useState<string | null>(currentItem?.id ?? null);

  return (
    <>
      <List
        component="nav"
        className={classNames(styles.MainMenu, !expanded ? styles.MainMenuMin : '')}
        style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
        disablePadding
      >
        <CustomScrollbar height="auto">
          {items.map((item, index) => (
            <MenuItem
              item={item}
              key={index}
              open={open}
              setOpen={setOpen}
              defaultActive={item.itemsLinks?.includes(location.pathname)}
            />
          ))}
        </CustomScrollbar>
      </List>
    </>
  );
};

export default MainMenu;
