import { rem } from '@app/utils/sx/functions';
import { Box, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';

interface Props {
  sx?: SxProps<Theme>;
}

const WarningIndicator = ({ sx }: Props): JSX.Element => (
  <Box
    sx={{
      width: rem(6),
      height: rem(6),
      borderRadius: '50%',
      backgroundColor: '#FF9635',
      ...sx,
    }}
  />
);

export default WarningIndicator;
