export enum UserRoles {
  Undefined = '',
  Admin = 1,
  Manager,
  Buyer,
  Receiver,
  Wholesaler,
}

export enum UserStatus {
  New = 1,
  Enabled,
  Disabled,
  Deleted,
}

export enum UserCreatedBy {
  Registration = 1,
  Admin,
}

export type Permission =
  | 'can_user_view'
  | 'can_user_update'
  | 'can_user_create'
  | 'can_user_list'
  | 'can_email_update'
  | 'can_password_update'
  | 'can_current_view'
  | 'can_current_update'
  | 'can_warehouse_list'
  | 'can_warehouse_create'
  | 'can_warehouse_delete'
  | 'can_warehouse_view'
  | 'can_warehouse_update'
  | 'can_item_list'
  | 'can_item_create'
  | 'can_item_view'
  | 'can_item_update'
  | 'can_item_delete'
  | 'can_serial_list'
  | 'can_serial_delete'
  | 'can_serial_update'
  | 'can_serial_create'
  | 'can_tracking_list'
  | 'can_tracking_view'
  | 'can_tracking_update'
  | 'can_tracking_delete'
  | 'can_receipt_create'
  | 'can_receipt_view'
  | 'can_receiving_create'
  | 'can_receiving_list'
  | 'can_receiving_view'
  | 'can_receiving_update'
  | 'can_receiving_delete'
  | 'can_payment_view'
  | 'can_payment_list'
  | 'can_payment_update'
  | 'can_payment_create'
  | 'can_payment_viewOwn'
  | 'can_payment_listOwn'
  | 'can_invoice_list'
  | 'can_invoice_view'
  | 'can_invoice_delete'
  | 'can_creditCharge_list'
  | 'can_creditCharge_delete'
  | 'can_creditCharge_create'
  | 'can_creditCharge_update'
  | 'can_creditCharge_view'
  | 'can_backup_delete'
  | 'can_backup_view'
  | 'can_backup_create'
  | 'can_ticket_create'
  | 'can_ticket_list'
  | 'can_ticket_view'
  | 'can_ticket_delete'
  | 'can_returnTask_create'
  | 'can_returnTask_list'
  | 'can_returnTask_view'
  | 'can_returnTask_delete'
  | 'can_returnTask_update'
  | 'can_returnTask_details';

export interface CreateUserContext {
  address: string;
  address2?: string;
  apt?: string;
  businessName?: string;
  cellPhone?: string;
  city: string;
  country: string;
  dateOfBirth?: string;
  email: string;
  firstName: string;
  lastName: string;
  notificationsReceiving?: boolean;
  reasonForJoin?: number;
  referralSource?: string;
  role: UserRoles;
  state: string;
  wholesaler?: boolean;
  workPhone?: string;
  zip: string;
}

export interface User extends CreateUserContext {
  id: number;
  userId: number;
  createdAt: number;
  createdBy: UserCreatedBy;
  updatedAt: number;
  permissions: Permission[];
  disabled: boolean;
  emailVerified: boolean;
  newEmail?: string;
  phoneVerified: boolean;
  socialSecurity?: string;
  status: UserStatus;
  totalLogins: number;
  balance?: number;
  canBeDeleted?: boolean;
}

export interface UpdatePasswordContext {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface NewPasswordContext {
  newPassword: string;
  confirmNewPassword: string;
  id: number;
}

export interface ChangeEmailContext {
  currentEmail: string;
  newEmail: string;
}

export interface UpdateUserContext {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  businessName: string;
  socialSecurity: string;
  address: string;
  apt?: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  address2?: string;
  cellPhone: string;
  workPhone: string;
  notificationsReceiving?: boolean;
}

export interface SearchBuyerContext {
  search: string;
  wholesaler?: boolean;
  enabled?: boolean;
}

export interface SearchUserContext {
  search: string;
  role: string;
  wholesaler?: boolean;
  enabled?: boolean;
}

export interface UserInfo {
  id: number;
  uid: number;
  lastName: string;
  firstName: string;
  email: string;
}
