import React from 'react';

const Users = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_143:39241)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09998 4.80001C2.09998 5.95981 3.04018 6.90001 4.19998 6.90001C5.35977 6.90001 6.29998 5.95981 6.29998 4.80001C6.29998 3.64021 5.35977 2.70001 4.19998 2.70001C3.04018 2.70001 2.09998 3.64021 2.09998 4.80001ZM4.19998 1.20001C2.21175 1.20001 0.599976 2.81179 0.599976 4.80001C0.599976 6.78824 2.21175 8.40001 4.19998 8.40001C6.1882 8.40001 7.79998 6.78824 7.79998 4.80001C7.79998 2.81179 6.1882 1.20001 4.19998 1.20001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7 4.80001C11.7 5.95981 12.6402 6.90001 13.8 6.90001C14.9597 6.90001 15.9 5.95981 15.9 4.80001C15.9 3.64021 14.9597 2.70001 13.8 2.70001C12.6402 2.70001 11.7 3.64021 11.7 4.80001ZM13.8 1.20001C11.8117 1.20001 10.2 2.81179 10.2 4.80001C10.2 6.78824 11.8117 8.40001 13.8 8.40001C15.7882 8.40001 17.4 6.78824 17.4 4.80001C17.4 2.81179 15.7882 1.20001 13.8 1.20001Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.150025 13.2C-0.150025 10.7976 1.79754 8.85001 4.19998 8.85001C6.60241 8.85001 8.54998 10.7976 8.54997 13.2L8.54997 15.8C8.54997 16.2142 8.21419 16.55 7.79997 16.55C7.38576 16.55 7.04997 16.2142 7.04997 15.8L7.04997 13.2C7.04997 11.626 5.77399 10.35 4.19998 10.35C2.62596 10.35 1.34998 11.626 1.34997 13.2L1.34997 13.8C1.34997 14.2142 1.01419 14.55 0.599975 14.55C0.185761 14.55 -0.150025 14.2142 -0.150025 13.8L-0.150025 13.2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44995 13.2C9.44995 10.7976 11.3975 8.85001 13.8 8.85001C16.2024 8.85001 18.15 10.7976 18.15 13.2L18.15 13.8C18.15 14.2142 17.8142 14.55 17.4 14.55C16.9857 14.55 16.65 14.2142 16.65 13.8L16.65 13.2C16.65 11.626 15.374 10.35 13.8 10.35C12.2259 10.35 10.95 11.626 10.95 13.2L10.9499 15.8C10.9499 16.2142 10.6142 16.55 10.1999 16.55C9.78574 16.55 9.44995 16.2142 9.44995 15.8L9.44995 13.2Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_143:39241">
        <rect width="18" height="18" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default Users;
