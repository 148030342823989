import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DropOffIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 38 38"
    sx={{
      ...sx,
      fill: 'none',
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0854 3.34864C17.6676 3.01295 18.3279 2.83624 19 2.83624C19.6721 2.83624 20.3324 3.01296 20.9146 3.34864C20.9153 3.34903 20.916 3.34942 20.9167 3.34981L30.8333 9.01647C31.4155 9.35259 31.899 9.83588 32.2354 10.4179C32.5719 10.9999 32.7493 11.6601 32.75 12.3323V23.6677C32.7493 24.3399 32.5719 25.0001 32.2354 25.5821C31.899 26.1641 31.4155 26.6474 30.8333 26.9835L30.8295 26.9857L27.4961 28.8905C27.0166 29.1645 26.4058 28.9979 26.1318 28.5184C25.8577 28.0389 26.0243 27.428 26.5039 27.154L29.8333 25.2515C29.8339 25.2511 29.8346 25.2508 29.8352 25.2504C30.1128 25.0897 30.3434 24.859 30.5039 24.5812C30.6648 24.3029 30.7497 23.9871 30.75 23.6656V12.3344C30.7497 12.0129 30.6648 11.6971 30.5039 11.4188C30.3433 11.141 30.1127 10.9102 29.835 10.7495C29.8345 10.7492 29.8339 10.7489 29.8333 10.7485L19.9167 5.08187C19.638 4.92096 19.3218 4.83624 19 4.83624C18.6782 4.83624 18.362 4.92095 18.0833 5.08186L18.0795 5.08409L8.16667 10.7485C8.1661 10.7489 8.16553 10.7492 8.16496 10.7495C7.8873 10.9102 7.65665 11.141 7.49609 11.4188C7.33527 11.697 7.25041 12.0126 7.25 12.3339V23.6661C7.25041 23.9874 7.33527 24.303 7.49609 24.5812C7.65668 24.8591 7.88737 25.0899 8.16509 25.2506C8.16562 25.2509 8.16614 25.2512 8.16667 25.2515L11.4961 27.154C11.9757 27.428 12.1423 28.0389 11.8682 28.5184C11.5942 28.9979 10.9834 29.1645 10.5039 28.8905L7.17053 26.9857L7.16667 26.9835C6.5845 26.6474 6.10096 26.1641 5.76455 25.5821C5.42814 25.0001 5.25069 24.3399 5.25 23.6677V12.3323C5.25069 11.6601 5.42814 10.9999 5.76455 10.4179C6.10096 9.83588 6.5845 9.35259 7.16667 9.01648L7.17052 9.01425L17.0833 3.34981C17.084 3.34942 17.0847 3.34903 17.0854 3.34864Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1344 6.49926C12.411 6.0212 13.0227 5.85783 13.5008 6.13438L25.8683 13.2885C26.1773 13.4673 26.3675 13.7972 26.3675 14.1541V18.5C26.3675 19.0523 25.9198 19.5 25.3675 19.5C24.8153 19.5 24.3675 19.0523 24.3675 18.5V14.7309L12.4993 7.86559C12.0213 7.58905 11.8579 6.97732 12.1344 6.49926Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76688 10.3593C6.04343 9.88124 6.65515 9.71788 7.13321 9.99442L19 16.8589L30.8668 9.99442C31.3448 9.71788 31.9566 9.88124 32.2331 10.3593C32.5096 10.8374 32.3463 11.4491 31.8682 11.7256L19.5007 18.8798C19.1909 19.059 18.809 19.059 18.4993 18.8798L6.13177 11.7256C5.65371 11.4491 5.49034 10.8374 5.76688 10.3593Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 17C19.5523 17 20 17.4477 20 18V21C20 21.5523 19.5523 22 19 22C18.4477 22 18 21.5523 18 21V18C18 17.4477 18.4477 17 19 17Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 22.2C15.2444 22.2 12.2 25.2445 12.2 29C12.2 32.7555 15.2444 35.8 19 35.8C22.7555 35.8 25.8 32.7555 25.8 29C25.8 25.2445 22.7555 22.2 19 22.2ZM10.2 29C10.2 24.1399 14.1398 20.2 19 20.2C23.8601 20.2 27.7999 24.1399 27.7999 29C27.7999 33.8601 23.8601 37.8 19 37.8C14.1398 37.8 10.2 33.8601 10.2 29Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 25C19.5523 25 20 25.4477 20 26V29.5858L21.2929 28.2929C21.6834 27.9024 22.3166 27.9024 22.7071 28.2929C23.0976 28.6834 23.0976 29.3166 22.7071 29.7071L19.7071 32.7071C19.3166 33.0976 18.6834 33.0976 18.2929 32.7071L15.2929 29.7071C14.9024 29.3166 14.9024 28.6834 15.2929 28.2929C15.6834 27.9024 16.3166 27.9024 16.7071 28.2929L18 29.5858V26C18 25.4477 18.4477 25 19 25Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default DropOffIcon;
