import FullPageSpinner from '@app/components/FullPageSpinner';
import GuestRoute from '@app/components/Routes/GuestRoute';
import AuthLayout from '@app/layouts/AuthLayout';
import NoMatch from '@app/pages/NoMatch';
import React, { lazy, Suspense } from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';

const Login = lazy(() => import('@app/pages/auth/Login'));
const Registration = lazy(() => import('@app/pages/auth/Registration'));
const RestorePassword = lazy(() => import('@app/pages/auth/RestorePassword'));
const NewPassword = lazy(() => import('@app/pages/auth/NewPassword'));
const EmailVerification = lazy(() => import('@app/pages/auth/EmailVerification'));
const SetPassword = lazy(() => import('@app/pages/auth/SetPassword'));

const AuthLayoutRoutes = (): JSX.Element => (
  <AuthLayout>
    <Suspense fallback={<FullPageSpinner />}>
      <Switch>
        <GuestRoute path="/auth/login">
          <Login />
        </GuestRoute>
        <GuestRoute path="/auth/registration">
          <Registration />
        </GuestRoute>
        <GuestRoute path="/auth/restore-password">
          <RestorePassword />
        </GuestRoute>
        <GuestRoute path="/auth/reset-password/:token">
          <NewPassword />
        </GuestRoute>
        <GuestRoute path="/auth/email-verification/:token">
          <EmailVerification />
        </GuestRoute>
        <GuestRoute path="/auth/set-password/:token">
          <SetPassword />
        </GuestRoute>
        <Route>
          <NoMatch auth />
        </Route>
      </Switch>
    </Suspense>
  </AuthLayout>
);

export default AuthLayoutRoutes;
