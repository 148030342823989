import { useHasError } from '@app/forms/components/FormGroup';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import React, { forwardRef } from 'react';

const Input = forwardRef(
  (props: TextFieldProps, ref): JSX.Element => {
    const hasError = useHasError();

    return <TextField inputRef={ref} error={hasError} size="small" {...props} />;
  }
);

export default Input;
