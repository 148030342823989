import { useCustomQuery } from '@app/api/hooks/useCustomQuery';
import { QueryKeys } from '@app/api/queryKeys';
import { User } from '@app/pages/users/types';
import { usersService } from '@app/utils/services/usersService';
import { AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';

export function useCurrentUserQuery(options?: UseQueryOptions<User, AxiosError, User>) {
  const { isLoading, data } = useCustomQuery<User>(QueryKeys.CurrentUser, usersService.getCurrent, {
    ...options,
  });

  return {
    isLoading,
    data,
  };
}
