import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowDownIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    className="icon-size-initial"
    sx={{
      fontSize: '12px',
      ...sx,
    }}
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29289 3.79289C1.68342 3.40237 2.31658 3.40237 2.70711 3.79289L6 7.08579L9.29289 3.79289C9.68342 3.40237 10.3166 3.40237 10.7071 3.79289C11.0976 4.18342 11.0976 4.81658 10.7071 5.20711L6 9.91421L1.29289 5.20711C0.902369 4.81658 0.902369 4.18342 1.29289 3.79289Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowDownIcon;
