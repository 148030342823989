import { UserRoles } from '@app/pages/users/types';

export const roleOptions = [
  { label: 'Admin', value: UserRoles.Admin },
  { label: 'Manager', value: UserRoles.Manager },
  { label: 'Receiver', value: UserRoles.Receiver },
  { label: 'Buyer', value: UserRoles.Buyer },
  { label: 'Wholesaler', value: UserRoles.Wholesaler },
];

export const userRole = {
  [UserRoles.Admin]: 'Admin',
  [UserRoles.Manager]: 'Manager',
  [UserRoles.Receiver]: 'Receiver',
  [UserRoles.Buyer]: 'Buyer',
  [UserRoles.Wholesaler]: 'Wholesaler',
};
