import CheckboxChecked from '@app/components/Icons/CheckboxChecked';
import CheckboxIcon from '@app/components/Icons/CheckboxIcon';
import { rem } from '@app/utils/sx/functions';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import React, { ForwardedRef, forwardRef, ReactNode } from 'react';

interface Props extends CheckboxProps {
  label: ReactNode;
}

const Checkbox = forwardRef(({ label, ...props }: Props, ref: ForwardedRef<HTMLInputElement>) => (
  <FormControlLabel
    sx={{
      alignItems: 'flex-start',
      wordBreak: 'break-word',
    }}
    control={
      <MuiCheckbox
        inputRef={ref}
        checkedIcon={<CheckboxChecked />}
        icon={<CheckboxIcon />}
        checked={Boolean(props.value)}
        sx={{ mt: rem(-9) }}
        {...props}
      />
    }
    label={label}
  />
));

export default Checkbox;
