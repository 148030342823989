import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CollapseMenuIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 16"
    fill="none"
    sx={{
      fontSize: rem(16),
    }}
    {...props}
  >
    <path d="M5 1.5H15V3H5V1.5Z" fill="currentColor" />
    <path d="M1 7H15V8.5H1V7Z" fill="currentColor" />
    <path d="M5 12.5H15V14H5V12.5Z" fill="currentColor" />
  </SvgIcon>
);

export default CollapseMenuIcon;
