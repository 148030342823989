import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NumberInputArrowIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon viewBox="0 0 10 7" sx={{ fontSize: rem(10), ...sx }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 0.355393C0.683417 -0.0351311 1.31658 -0.0351311 1.70711 0.355393L5 3.64829L8.29289 0.355393C8.68342 -0.0351311 9.31658 -0.0351311 9.70711 0.355393C10.0976 0.745917 10.0976 1.37908 9.70711 1.76961L5 6.47671L0.292893 1.76961C-0.0976311 1.37908 -0.0976311 0.745917 0.292893 0.355393Z"
      fill="#9CABBD"
    />
  </SvgIcon>
);

export default NumberInputArrowIcon;
