import { SvgIconProps } from '@mui/material';
import React from 'react';

const WhatsApp = (props: SvgIconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.83268 7.39045C9.12077 7.10235 9.58786 7.10235 9.87595 7.39045L11.2892 8.80373C11.5773 9.09183 11.5773 9.55891 11.2892 9.84701C11.0011 10.1351 10.5341 10.1351 10.246 9.84701L9.35431 8.95536L9.2456 9.06407C9.24554 9.06413 9.24567 9.064 9.2456 9.06407C8.96704 9.34294 8.81037 9.72119 8.81037 10.1154C8.81037 10.5095 8.96685 10.8876 9.24541 11.1665M9.24541 11.1665L12.7096 14.6306C12.7096 14.6307 12.7095 14.6306 12.7096 14.6306C12.9884 14.9092 13.3667 15.0659 13.7609 15.0659C14.155 15.0659 14.5331 14.9094 14.812 14.6308C14.812 14.6308 14.8119 14.6309 14.812 14.6308L14.9226 14.5202L14.3753 13.9691C14.0882 13.6801 14.0898 13.213 14.3789 12.9259C14.6679 12.6388 15.135 12.6403 15.4221 12.9294L16.4875 14.0021C16.774 14.2904 16.7732 14.7562 16.4858 15.0436L15.8552 15.6741C15.2997 16.2292 14.5462 16.5413 13.7609 16.5413C12.9755 16.5413 12.2222 16.2294 11.6667 15.6743L8.20213 12.2097C7.64699 11.6542 7.33496 10.9007 7.33496 10.1154C7.33496 9.32998 7.6468 8.57674 8.20193 8.02119L8.83268 7.39045"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4705 4.6222C12.339 4.39674 11.1709 4.43448 10.0563 4.7325C8.94178 5.03051 7.91069 5.58082 7.04275 6.34088C6.17482 7.10095 5.49328 8.05041 5.05081 9.11589C4.60835 10.1814 4.41681 11.3343 4.49101 12.4856C4.5652 13.6369 4.90314 14.7558 5.47871 15.7556C5.57597 15.9246 5.60239 16.1252 5.55221 16.3136L4.77776 19.222L5.80907 18.9477L7.68621 18.4476C7.87466 18.3974 8.07533 18.4238 8.24432 18.5212C9.38839 19.1802 10.6857 19.5265 12.006 19.5255C13.3263 19.5244 14.623 19.176 15.766 18.5152C16.9091 17.8544 17.8581 16.9045 18.5178 15.7609C19.1776 14.6173 19.5248 13.3202 19.5246 11.9999M9.6752 3.30716C11.0083 2.95071 12.4055 2.90557 13.7588 3.17523C15.1121 3.44489 16.3853 4.02213 17.4799 4.86232C18.5745 5.70252 19.4613 6.78317 20.0717 8.02074C20.6821 9.25832 20.9997 10.6197 21 11.9996C21.0002 13.5788 20.5849 15.1302 19.7958 16.4981C19.0067 17.866 17.8716 19.0022 16.5045 19.7925C15.1373 20.5829 13.5863 20.9996 12.0072 21.0009C10.5314 21.002 9.07967 20.6403 7.77909 19.9497L6.18889 20.3733L3.92738 20.975C3.67313 21.0426 3.40211 20.9697 3.21609 20.7837C3.03006 20.5977 2.95718 20.3266 3.02481 20.0724L4.05011 16.2206C3.45281 15.0951 3.10074 13.8544 3.01865 12.5805C2.9299 11.2035 3.159 9.82443 3.68822 8.55004C4.21744 7.27565 5.03262 6.14001 6.07074 5.23091C7.10886 4.32182 8.34213 3.66361 9.6752 3.30716ZM19.5246 11.9999C19.5243 10.8463 19.2588 9.70804 18.7485 8.67336C18.2381 7.63867 17.4967 6.73517 16.5816 6.03272C15.6664 5.33026 14.6019 4.84765 13.4705 4.6222"
      fill="currentColor"
    />
  </svg>
);

export default WhatsApp;
