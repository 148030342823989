import { SvgIconProps } from '@mui/material';
import React from 'react';

const Dashboard = (props: SvgIconProps): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4 1.5H2C1.72386 1.5 1.5 1.72386 1.5 2V4C1.5 4.27614 1.72386 4.5 2 4.5H6.4C6.67614 4.5 6.9 4.27614 6.9 4V2C6.9 1.72386 6.67614 1.5 6.4 1.5ZM2 0C0.89543 0 0 0.89543 0 2V4C0 5.10457 0.895431 6 2 6H6.4C7.50457 6 8.4 5.10457 8.4 4V2C8.4 0.895431 7.50457 0 6.4 0H2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8 16.5H16C16.2761 16.5 16.5 16.2761 16.5 16V14C16.5 13.7239 16.2761 13.5 16 13.5H12.8C12.5239 13.5 12.3 13.7239 12.3 14V16C12.3 16.2761 12.5239 16.5 12.8 16.5ZM16 18C17.1046 18 18 17.1046 18 16V14C18 12.8954 17.1046 12 16 12H12.8C11.6954 12 10.8 12.8954 10.8 14V16C10.8 17.1046 11.6954 18 12.8 18H16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.5H12.8C12.5239 1.5 12.3 1.72386 12.3 2V7.6C12.3 7.87614 12.5239 8.1 12.8 8.1H16C16.2762 8.1 16.5 7.87614 16.5 7.6V2C16.5 1.72386 16.2762 1.5 16 1.5ZM12.8 0C11.6955 0 10.8 0.895429 10.8 2V7.6C10.8 8.70457 11.6955 9.6 12.8 9.6H16C17.1046 9.6 18 8.70457 18 7.6V2C18 0.89543 17.1046 0 16 0H12.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00003 16.5H6.40002C6.67617 16.5 6.90002 16.2761 6.90002 16V10.4C6.90002 10.1239 6.67617 9.9 6.40002 9.9H2.00003C1.72388 9.9 1.50002 10.1239 1.50002 10.4V16C1.50002 16.2761 1.72388 16.5 2.00003 16.5ZM6.40002 18C7.50459 18 8.40002 17.1046 8.40002 16V10.4C8.40002 9.29543 7.50459 8.4 6.40002 8.4H2.00003C0.895455 8.4 2.47955e-05 9.29543 2.47955e-05 10.4V16C2.47955e-05 17.1046 0.895457 18 2.00003 18H6.40002Z"
      fill="currentColor"
    />
  </svg>
);

export default Dashboard;
