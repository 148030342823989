import { SvgIconProps } from '@mui/material';
import React from 'react';

const RadioCheckedDisabled = (props: SvgIconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="11.5" fill="#F9FAFB" stroke="#E8ECF1" />
    <circle cx="12" cy="12" r="7" fill="#9CABBD" />
  </svg>
);

export default RadioCheckedDisabled;
