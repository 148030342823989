import { rem } from '@app/utils/sx/functions';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const profileButton: SxProps<Theme> = {
  justifyContent: { xs: 'center', md: 'flex-start' },
  width: { xs: rem(40), md: rem(165) },
  minWidth: { xs: 'auto', md: rem(64) },
  height: { xs: rem(40), md: rem(36) },
  pr: { xs: 0, md: rem(30) },
  pl: { xs: 0, md: rem(5) },
  backgroundColor: '#fff',
  '.MuiButton-startIcon': {
    m: { xs: 0, md: rem([-4, 0, -4, -2]) },
  },
  '.MuiButton-startIcon *:nth-of-type(1)': {
    mr: { xs: 0, md: rem(8) },
    fontSize: rem(16),
  },
  '.MuiButton-endIcon > *:nth-of-type(1)': {
    position: 'absolute',
    top: '50%',
    right: rem(12),
    color: 'text.auxiliary',
    fontSize: rem(11),
    transform: 'translateY(-50%)',
  },
};

export const viewProfile: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
};

export const indicator: SxProps<Theme> = {
  position: 'absolute',
  top: rem(5),
  left: rem(23),
};

export const menu: SxProps<Theme> = {
  '.MuiPaper-root .MuiMenuItem-root': {
    py: rem(16),
  },
};

export const menuItem: SxProps<Theme> = {
  pt: rem(16),
  pb: `${rem(12)} !important`,
  opacity: '1 !important',
};

export const profile: SxProps<Theme> = {
  color: 'text.primary',
  textTransform: 'none',
};

export const profileName: SxProps<Theme> = {
  fontSize: rem(13),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const name: SxProps<Theme> = {
  width: rem(308),
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  mb: rem(2),
  color: 'text.primary',
  fontWeight: 600,
  fontSize: rem(14),
  lineHeight: rem(24),
};

export const roleAndId: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  '.MuiTypography-root': {
    mr: rem(4),
    color: 'text.auxiliary',
    fontSize: rem(12),
    lineHeight: rem(19),
    '&:last-of-type': {
      mr: 0,
    },
  },
};

export const divider: SxProps<Theme> = {
  m: '0 !important',
  borderColor: 'actions.borders',
};

export const myProfileButton: SxProps<Theme> = {
  my: rem(6),
  py: `${rem(10)} !important`,
  '.MuiButton-startIcon *:nth-of-type(1)': {
    fontSize: rem(16),
  },
};

export const signOutButton: SxProps<Theme> = {
  width: '100%',
  minHeight: rem(40),
  mt: rem(8),
  p: 0,
  '.MuiSvgIcon-root': {
    mr: '0 !important',
    color: '#212b36 !important',
  },
};

export const iconBox: SxProps<Theme> = {
  mr: rem(8),
  p: rem(6),
  fontSize: rem(16),
  backgroundColor: 'actions.secondaryBackground',
  borderRadius: rem(4),
  boxSizing: 'content-box',
};

export const signOutBox: SxProps<Theme> = {
  pt: '0 !important',
  backgroundColor: 'inherit !important',
};
