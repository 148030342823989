import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const BackupsIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.25C7.14186 2.25 5.57211 3.76881 5.4499 5.75274C5.45001 5.75083 5.45001 5.75093 5.44992 5.75368C5.44979 5.75787 5.44945 5.76826 5.44903 5.78717C5.44844 5.81379 5.44787 5.84863 5.44732 5.88993C5.44623 5.97231 5.4453 6.07532 5.44455 6.17953C5.44305 6.38832 5.44231 6.59613 5.44231 6.64286C5.44231 7.05707 5.10652 7.39286 4.69231 7.39286C4.27809 7.39286 3.94231 7.05707 3.94231 6.64286C3.94231 6.60293 3.94294 6.39592 3.9445 6.18054C3.7913 6.13266 3.62933 6.10714 3.46154 6.10714C2.54682 6.10714 1.75 6.89142 1.75 7.92857C1.75 8.96573 2.54682 9.75 3.46154 9.75C3.87575 9.75 4.21154 10.0858 4.21154 10.5C4.21154 10.9142 3.87575 11.25 3.46154 11.25C1.65732 11.25 0.25 9.73174 0.25 7.92857C0.25 6.12541 1.65732 4.60714 3.46154 4.60714C3.68204 4.60714 3.89744 4.63021 4.1055 4.67406C4.6685 2.43411 6.62637 0.75 9 0.75C10.8799 0.75 12.5031 1.81076 13.3737 3.36207C13.5533 3.33528 13.7367 3.32143 13.9231 3.32143C16.0672 3.32143 17.75 5.12751 17.75 7.28571C17.75 9.10108 16.566 10.6578 14.9146 11.1155C14.5154 11.2261 14.1022 10.9922 13.9915 10.5931C13.8809 10.1939 14.1148 9.78062 14.514 9.66998C15.4945 9.39822 16.25 8.44925 16.25 7.28571C16.25 5.89594 15.1814 4.82515 13.9296 4.82144C14.0135 5.20085 14.0577 5.59544 14.0577 6C14.0577 6.41421 13.7219 6.75 13.3077 6.75C12.8935 6.75 12.5577 6.41421 12.5577 6C12.5577 5.4707 12.454 4.9693 12.2682 4.51554C11.7158 3.16694 10.4481 2.25 9 2.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 7.73935L12.5303 11.2697C12.8232 11.5626 12.8232 12.0374 12.5303 12.3303C12.2374 12.6232 11.7626 12.6232 11.4697 12.3303L9.75 10.6107V16C9.75 16.4142 9.41421 16.75 9 16.75C8.58579 16.75 8.25 16.4142 8.25 16V10.6107L6.53033 12.3303C6.23744 12.6232 5.76256 12.6232 5.46967 12.3303C5.17678 12.0374 5.17678 11.5626 5.46967 11.2697L9 7.73935Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default BackupsIcon;
