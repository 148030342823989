import { useAuth } from '@app/contexts/auth';
import React from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

const GuestRoute = ({ children, ...rest }: RouteProps) => {
  const { isAuthorized } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthorized ? (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { backlink: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export default GuestRoute;
