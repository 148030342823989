import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const AttachIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.84544 4.354C9.36053 4.354 8.89548 4.54663 8.5526 4.88952C8.20972 5.2324 8.01709 5.69744 8.01709 6.18235L8.01709 14.3522C8.01709 15.1707 8.34225 15.9557 8.92104 16.5345C9.49983 17.1133 10.2848 17.4385 11.1034 17.4385C11.9219 17.4385 12.7069 17.1133 13.2857 16.5345C13.8645 15.9557 14.1896 15.1707 14.1896 14.3522V6.18235C14.1896 5.80266 14.4974 5.49485 14.8771 5.49485C15.2568 5.49485 15.5646 5.80266 15.5646 6.18235V14.3522C15.5646 15.5354 15.0946 16.6701 14.258 17.5068C13.4213 18.3434 12.2866 18.8135 11.1034 18.8135C9.92016 18.8135 8.78542 18.3434 7.94877 17.5068C7.11212 16.6701 6.64209 15.5354 6.64209 14.3522L6.64209 6.18235C6.64209 5.33277 6.97958 4.51799 7.58033 3.91724C8.18107 3.3165 8.99586 2.979 9.84544 2.979C10.695 2.979 11.5098 3.3165 12.1105 3.91724C12.7113 4.51799 13.0488 5.33277 13.0488 6.18235L13.0443 14.3566C13.0443 14.3568 13.0443 14.3565 13.0443 14.3566C13.0442 14.8725 12.8393 15.3675 12.4745 15.7323C12.1097 16.0971 11.6149 16.3021 11.0989 16.3021C10.583 16.3021 10.0881 16.0971 9.72329 15.7323C9.35846 15.3674 9.15349 14.8726 9.15349 14.3566L9.15794 6.81313C9.15816 6.43344 9.46615 6.12581 9.84584 6.12604C10.2255 6.12626 10.5332 6.43425 10.5329 6.81394L10.5285 14.3566C10.5285 14.3566 10.5285 14.3566 10.5285 14.3566C10.5285 14.5079 10.5886 14.653 10.6956 14.76C10.8025 14.867 10.9476 14.9271 11.0989 14.9271C11.2502 14.9271 11.3953 14.867 11.5023 14.76C11.6092 14.653 11.6693 14.5079 11.6693 14.3566L11.6738 6.18235C11.6738 6.18223 11.6738 6.18248 11.6738 6.18235C11.6737 5.69758 11.4811 5.23231 11.1383 4.88951C10.7954 4.54663 10.3303 4.354 9.84544 4.354Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AttachIcon;
