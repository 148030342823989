import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ShipDirectIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 38 38"
    sx={{
      ...sx,
      fill: 'none',
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0854 3.34864C17.6676 3.01295 18.3279 2.83624 19 2.83624C19.6721 2.83624 20.3324 3.01296 20.9146 3.34864C20.9153 3.34903 20.916 3.34942 20.9167 3.34981L30.8333 9.01647C31.4155 9.35259 31.899 9.83588 32.2354 10.4179C32.5719 10.9999 32.7493 11.6601 32.75 12.3323V23.6677C32.7493 24.3399 32.5719 25.0001 32.2354 25.5821C31.899 26.1641 31.4155 26.6474 30.8333 26.9835L30.8295 26.9857L24.9961 30.3191C24.5166 30.5931 23.9058 30.4265 23.6318 29.947C23.3577 29.4675 23.5243 28.8566 24.0039 28.5826L29.8333 25.2515C29.8339 25.2512 29.8344 25.2509 29.8349 25.2506C30.1126 25.0899 30.3433 24.8591 30.5039 24.5812C30.6648 24.3029 30.7497 23.9871 30.75 23.6656V12.3344C30.7497 12.0129 30.6648 11.6971 30.5039 11.4188C30.3433 11.141 30.1127 10.9102 29.835 10.7495C29.8345 10.7492 29.8339 10.7489 29.8333 10.7485L19.9167 5.08187C19.638 4.92096 19.3218 4.83624 19 4.83624C18.6782 4.83624 18.362 4.92095 18.0833 5.08186L18.0795 5.08409L8.16667 10.7485C8.1661 10.7489 8.16553 10.7492 8.16496 10.7495C7.8873 10.9102 7.65665 11.141 7.49609 11.4188C7.33527 11.697 7.25041 12.0126 7.25 12.3339V23.6661C7.25041 23.9874 7.33527 24.303 7.49609 24.5812C7.65668 24.8591 7.88737 25.0899 8.16509 25.2506C8.16562 25.2509 8.16614 25.2512 8.16667 25.2515L13.9961 28.5826C14.4757 28.8566 14.6423 29.4675 14.3682 29.947C14.0942 30.4265 13.4834 30.5931 13.0039 30.3191L7.17053 26.9857L7.16667 26.9835C6.5845 26.6474 6.10096 26.1641 5.76455 25.5821C5.42814 25.0001 5.25069 24.3399 5.25 23.6677V12.3323C5.25069 11.6601 5.42814 10.9999 5.76455 10.4179C6.10096 9.83588 6.5845 9.35259 7.16667 9.01648L7.17052 9.01425L17.0833 3.34981C17.084 3.34942 17.0847 3.34903 17.0854 3.34864Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1344 6.49926C12.411 6.0212 13.0227 5.85783 13.5008 6.13438L25.8683 13.2885C26.1773 13.4673 26.3675 13.7972 26.3675 14.1541V18.5C26.3675 19.0523 25.9198 19.5 25.3675 19.5C24.8153 19.5 24.3675 19.0523 24.3675 18.5V14.7309L12.4993 7.86559C12.0213 7.58905 11.8579 6.97732 12.1344 6.49926Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.76688 10.3593C6.04343 9.88124 6.65515 9.71788 7.13321 9.99442L19 16.8589L30.8668 9.99442C31.3448 9.71788 31.9566 9.88124 32.2331 10.3593C32.5096 10.8374 32.3463 11.4491 31.8682 11.7256L19.5007 18.8798C19.1909 19.059 18.809 19.059 18.4993 18.8798L6.13177 11.7256C5.65371 11.4491 5.49034 10.8374 5.76688 10.3593Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 17C19.5523 17 20 17.4477 20 18V21C20 21.5523 19.5523 22 19 22C18.4477 22 18 21.5523 18 21V18C18 17.4477 18.4477 17 19 17Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 22C16.2336 22 14 24.2294 14 26.9684C14 27.7228 14.2963 28.6762 14.8319 29.7472C15.3587 30.8005 16.0719 31.8798 16.8024 32.8617C17.5304 33.8404 18.2607 34.7028 18.8102 35.3214C18.8762 35.3958 18.9396 35.4665 19 35.5335C19.0604 35.4665 19.1238 35.3958 19.1898 35.3214C19.7393 34.7028 20.4696 33.8404 21.1976 32.8617C21.9281 31.8798 22.6413 30.8005 23.1681 29.7472C23.7037 28.6762 24 27.7228 24 26.9684C24 24.2294 21.7664 22 19 22ZM19 37C18.2871 37.7012 18.2869 37.7011 18.2868 37.7009L18.2851 37.6992L18.2809 37.695L18.2662 37.6799L18.2123 37.6242C18.1658 37.5758 18.0986 37.5055 18.0137 37.4152C17.844 37.2347 17.6031 36.9741 17.3148 36.6495C16.7393 36.0015 15.9696 35.0932 15.1977 34.0554C14.4281 33.0209 13.6413 31.838 13.0431 30.6418C12.4537 29.4633 12 28.1798 12 26.9684C12 23.1149 15.139 20 19 20C22.861 20 26 23.1149 26 26.9684C26 28.1798 25.5463 29.4633 24.9569 30.6418C24.3587 31.838 23.5719 33.0209 22.8024 34.0554C22.0304 35.0932 21.2607 36.0015 20.6852 36.6495C20.3969 36.9741 20.156 37.2347 19.9863 37.4152C19.9014 37.5055 19.8342 37.5758 19.7877 37.6242L19.7338 37.6799L19.7191 37.695L19.7149 37.6992L19.7136 37.7005C19.7135 37.7007 19.7129 37.7012 19 37ZM19 37L19.7129 37.7012C19.5249 37.8924 19.2681 38 19 38C18.7319 38 18.4748 37.8921 18.2868 37.7009L19 37Z"
      fill="currentColor"
    />
    <path
      d="M21 27C21 28.1046 20.1046 29 19 29C17.8954 29 17 28.1046 17 27C17 25.8954 17.8954 25 19 25C20.1046 25 21 25.8954 21 27Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ShipDirectIcon;
