import { SvgIconProps } from '@mui/material';
import React from 'react';

const TrackingNumbers = (props: SvgIconProps): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.99004 15.3H15.0099C15.2646 15.3 15.4787 15.1085 15.5068 14.8553L16.3241 7.50005H1.67586L2.4931 14.8553C2.52124 15.1085 2.73527 15.3 2.99004 15.3ZM15.0099 16.8C16.029 16.8 16.8851 16.0338 16.9976 15.0209L17.8766 7.11048C17.9424 6.51811 17.4787 6.00005 16.8827 6.00005H1.11722C0.521212 6.00005 0.0575199 6.51811 0.123339 7.11048L1.00228 15.0209C1.11482 16.0338 1.97095 16.8 2.99004 16.8H15.0099Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46977 0.669621C8.76266 0.376728 9.23754 0.376728 9.53043 0.669621L14.9304 6.06962C15.2233 6.36251 15.2233 6.83739 14.9304 7.13028C14.6375 7.42317 14.1627 7.42317 13.8698 7.13028L9.0001 2.26061L4.13043 7.13028C3.83753 7.42317 3.36266 7.42317 3.06977 7.13028C2.77687 6.83739 2.77687 6.36251 3.06977 6.06962L8.46977 0.669621Z"
      fill="currentColor"
    />
    <path
      d="M10.1998 11.4C10.1998 12.0627 9.66255 12.6 8.9998 12.6C8.33706 12.6 7.7998 12.0627 7.7998 11.4C7.7998 10.7372 8.33706 10.2 8.9998 10.2C9.66255 10.2 10.1998 10.7372 10.1998 11.4Z"
      fill="currentColor"
    />
  </svg>
);

export default TrackingNumbers;
