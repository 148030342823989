import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowDown = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon width="10" height="10" viewBox="0 0 10 10" sx={{ fontSize: '10px', ...sx }} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.70711 9.70711C8.09763 9.31658 8.09763 8.68342 7.70711 8.29289L4.41421 5L7.70711 1.70711C8.09763 1.31658 8.09763 0.683417 7.70711 0.292893C7.31658 -0.0976315 6.68342 -0.0976315 6.29289 0.292893L1.58579 5L6.29289 9.70711C6.68342 10.0976 7.31658 10.0976 7.70711 9.70711Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowDown;
