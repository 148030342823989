import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ItemsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7932 3.24083C11.1602 3.02931 11.5764 2.91797 12 2.91797C12.4236 2.91797 12.8397 3.0293 13.2067 3.2408C13.2072 3.24111 13.2078 3.24143 13.2083 3.24174L19.0417 6.57507C19.4087 6.78696 19.7135 7.09165 19.9256 7.45856C20.1377 7.82547 20.2496 8.2417 20.25 8.66549V15.3329C20.2496 15.7567 20.1377 16.1737 19.9256 16.5406C19.7135 16.9075 19.4087 17.2122 19.0417 17.4241L19.0388 17.4258L13.2083 20.7574C13.2078 20.7578 13.2073 20.7581 13.2067 20.7584C12.8397 20.9699 12.4236 21.0812 12 21.0812C11.5764 21.0812 11.1602 20.9699 10.7932 20.7583C10.7927 20.758 10.7922 20.7577 10.7917 20.7574L4.96123 17.4258L4.95833 17.4241C4.59132 17.2122 4.28648 16.9075 4.07439 16.5406C3.86231 16.1737 3.75044 15.7575 3.75 15.3337L3.75 8.66626C3.75043 8.24247 3.86231 7.82547 4.07439 7.45856C4.28648 7.09165 4.59132 6.78697 4.95833 6.57507L4.96123 6.5734L10.7932 3.24083ZM12 4.41797C11.8391 4.41797 11.681 4.46032 11.5417 4.54078L11.5388 4.54245L5.70833 7.87411C5.70794 7.87434 5.70754 7.87457 5.70714 7.8748C5.56846 7.95515 5.45326 8.07045 5.37304 8.20923C5.29266 8.3483 5.25023 8.50605 5.25 8.66669V15.3325C5.25023 15.4931 5.29266 15.6509 5.37304 15.79C5.45325 15.9287 5.56843 16.044 5.70708 16.1244C5.7075 16.1246 5.70792 16.1248 5.70833 16.1251L11.5417 19.4584C11.681 19.5389 11.8391 19.5812 12 19.5812C12.1609 19.5812 12.319 19.5389 12.4583 19.4584L12.4612 19.4567L18.2917 16.1251C18.2921 16.1248 18.2925 16.1246 18.2929 16.1244C18.4316 16.044 18.5468 15.9287 18.627 15.79C18.7074 15.6508 18.7498 15.4929 18.75 15.3322V8.66703C18.7498 8.50628 18.7074 8.3484 18.627 8.20923C18.5467 8.07045 18.4315 7.95515 18.2929 7.8748C18.2925 7.87457 18.2921 7.87434 18.2917 7.87411L12.4583 4.54079C12.319 4.46033 12.1609 4.41797 12 4.41797Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07553 7.42439C4.28294 7.06584 4.74173 6.94332 5.10028 7.15072L11.9997 11.1418L18.8992 7.15072C19.2577 6.94332 19.7165 7.06584 19.9239 7.42439C20.1313 7.78293 20.0088 8.24173 19.6503 8.44913L12.3753 12.6575C12.143 12.7919 11.8565 12.7919 11.6242 12.6575L4.34919 8.44913C3.99065 8.24173 3.86812 7.78293 4.07553 7.42439Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.70639 5.12458C7.91379 4.76604 8.37259 4.64351 8.73114 4.85092L16.0061 9.05925C16.2379 9.19331 16.3806 9.44073 16.3806 9.70846V13.2352C16.3806 13.6494 16.0448 13.9852 15.6306 13.9852C15.2164 13.9852 14.8806 13.6494 14.8806 13.2352V10.1411L7.98005 6.14933C7.6215 5.94192 7.49898 5.48313 7.70639 5.12458Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V20.4C12.75 20.8142 12.4142 21.15 12 21.15C11.5858 21.15 11.25 20.8142 11.25 20.4V12C11.25 11.5858 11.5858 11.25 12 11.25Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ItemsIcon;
