import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const BuyersIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.09835 14.5984C4.80161 13.8951 5.75544 13.5 6.75 13.5H12.75C13.7446 13.5 14.6984 13.8951 15.4016 14.5984C16.1049 15.3016 16.5 16.2554 16.5 17.25V18.75C16.5 19.1642 16.1642 19.5 15.75 19.5C15.3358 19.5 15 19.1642 15 18.75V17.25C15 16.6533 14.7629 16.081 14.341 15.659C13.919 15.2371 13.3467 15 12.75 15H6.75C6.15326 15 5.58097 15.2371 5.15901 15.659C4.73705 16.081 4.5 16.6533 4.5 17.25V18.75C4.5 19.1642 4.16421 19.5 3.75 19.5C3.33579 19.5 3 19.1642 3 18.75V17.25C3 16.2554 3.39509 15.3016 4.09835 14.5984Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 6C8.50736 6 7.5 7.00736 7.5 8.25C7.5 9.49264 8.50736 10.5 9.75 10.5C10.9926 10.5 12 9.49264 12 8.25C12 7.00736 10.9926 6 9.75 6ZM6 8.25C6 6.17893 7.67893 4.5 9.75 4.5C11.8211 4.5 13.5 6.17893 13.5 8.25C13.5 10.3211 11.8211 12 9.75 12C7.67893 12 6 10.3211 6 8.25Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.274 14.1601C17.3776 13.759 17.7866 13.5179 18.1877 13.6214C18.9922 13.8291 19.7049 14.2982 20.214 14.9548C20.723 15.6115 20.9996 16.4187 21.0002 17.2495L21.0002 18.7501C21.0002 19.1643 20.6644 19.5001 20.2502 19.5001C19.836 19.5001 19.5002 19.1643 19.5002 18.7501V17.2504C19.4997 16.7519 19.3338 16.2678 19.0284 15.8738C18.723 15.4798 18.2954 15.1984 17.8127 15.0738C17.4116 14.9702 17.1704 14.5612 17.274 14.1601Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2736 5.16156C14.3764 4.76029 14.7849 4.51829 15.1862 4.62103C15.9928 4.82756 16.7078 5.29669 17.2184 5.95445C17.7289 6.6122 18.0061 7.42118 18.0061 8.25384C18.0061 9.0865 17.7289 9.89548 17.2184 10.5532C16.7078 11.211 15.9928 11.6801 15.1862 11.8867C14.7849 11.9894 14.3764 11.7474 14.2736 11.3461C14.1709 10.9449 14.4129 10.5363 14.8142 10.4335C15.2981 10.3096 15.7271 10.0281 16.0334 9.63348C16.3398 9.23882 16.5061 8.75344 16.5061 8.25384C16.5061 7.75425 16.3398 7.26886 16.0334 6.8742C15.7271 6.47955 15.2981 6.19807 14.8142 6.07416C14.4129 5.97141 14.1709 5.56283 14.2736 5.16156Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default BuyersIcon;
