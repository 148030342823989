import React from 'react';

const Mobile = (): JSX.Element => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9 4H8.1C7.49249 4 7 4.55103 7 5.23077V18.7692C7 19.449 7.49249 20 8.1 20H16.9C17.5075 20 18 19.449 18 18.7692V5.23077C18 4.55103 17.5075 4 16.9 4Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5 5H14.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="12.5" cy="17" r="1" fill="currentColor" />
  </svg>
);

export default Mobile;
