import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CheckboxIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#E8ECF1" fill="none" />
  </SvgIcon>
);

export default CheckboxIcon;
