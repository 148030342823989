import MenuIcon from '@app/components/Icons/MenuIcon';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { rem } from '@app/utils/sx/functions';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import styles from './Header.module.scss';

const Header = (): JSX.Element => {
  const { handleDrawerToggle, expanded, handleExpand, content } = useMainLayoutContext();

  return (
    <Box
      className={styles.Header}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        minHeight: rem(60),
        background: { xs: '#eef0f6', md: '#fff' },
        borderBottom: { xs: 'none', md: '1px solid #e8ecf1' },
        px: { xs: rem(12), md: rem(16) },
      }}
    >
      <IconButton
        className={styles.ButtonBurger}
        aria-label="open drawer"
        edge="start"
        onClick={!expanded ? handleExpand : handleDrawerToggle}
        sx={{
          display: { xs: 'none', md: expanded ? 'none' : 'flex' },
          width: rem(32),
          height: rem(32),
          marginRight: 1.5,
          marginLeft: 0,
          color: '#a4b0ba',
          border: '1px solid #E8ECF1',
          borderRadius: rem(6),
        }}
      >
        <MenuIcon className={styles.MenuIcon} />
      </IconButton>

      {content}
    </Box>
  );
};

export default Header;
