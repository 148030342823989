import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const BalkSentIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.75C7.60655 3.75 7.23607 3.93524 7 4.25L5.5 6.25H11.25V3.75H8ZM12.75 3.75V6.25H18.5L17 4.25C16.7639 3.93524 16.3934 3.75 16 3.75H12.75ZM19.25 7.75H4.75V19C4.75 19.6904 5.30964 20.25 6 20.25H18C18.6904 20.25 19.25 19.6904 19.25 19V7.75ZM5.8 3.35C6.31935 2.65753 7.13442 2.25 8 2.25H16C16.8656 2.25 17.6807 2.65753 18.2 3.35L20.75 6.75V19C20.75 20.5188 19.5188 21.75 18 21.75H6C4.48122 21.75 3.25 20.5188 3.25 19V6.75L5.8 3.35Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1868 10.4922C12.4673 10.1874 12.9417 10.1676 13.2466 10.448L17.1078 14L13.2466 17.552C12.9417 17.8324 12.4673 17.8126 12.1868 17.5078C11.9064 17.2029 11.9262 16.7285 12.231 16.448L14.0769 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H14.0769L12.231 11.552C11.9262 11.2715 11.9064 10.7971 12.1868 10.4922Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default BalkSentIcon;
