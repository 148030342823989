import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CheckboxChecked = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <rect width="24" height="24" rx="6" fill="#6658FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0608 9.06077L10.5001 17.6214L4.93945 12.0608L7.06077 9.93945L10.5001 13.3788L16.9395 6.93945L19.0608 9.06077Z"
      fill="white"
    />
  </SvgIcon>
);

export default CheckboxChecked;
