import styles from '@app/components/DefaultPageHeader/ProfileMenu/ProfileMenu.module.scss';
import BookOpenIcon from '@app/components/Icons/BookOpenIcon';
import H4 from '@app/components/typography/H4';
import { useConfig } from '@app/contexts/config';
import { useCurrentUser } from '@app/contexts/currentUser';
import { UserRoles } from '@app/pages/users/types';
import { Box, Menu, MenuItem, PopoverOrigin, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { SxProps } from '@mui/system';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { divider, heading, iconBox, link, menu, menuPaper, text } from './InfoMenu.sx';

interface Props {
  id?: string;
  anchorEl: null | HTMLElement;
  closeMenu: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sxPaper?: SxProps;
}

const InfoMenu = ({
  id = 'info-menu',
  anchorEl,
  closeMenu,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  sxPaper,
}: Props) => {
  const isMenuOpen = Boolean(anchorEl);
  const currentUser = useCurrentUser();
  const isReceiver = currentUser?.role === UserRoles.Receiver;
  const { supportEmail } = useConfig();

  if (isReceiver) {
    return null;
  }

  return (
    <Menu
      sx={menu}
      id={id}
      MenuListProps={{
        'aria-labelledby': 'info-button',
      }}
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={closeMenu}
      classes={{ root: styles.Modal }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        sx: menuPaper,
      }}
    >
      <H4 sx={heading}>Info</H4>

      <MenuItem sx={{ color: '#212B36' }} to="/terms-and-conditions" component={Link} onClick={closeMenu}>
        <Box sx={iconBox}>
          <BookOpenIcon />
        </Box>
        Terms and Conditions
      </MenuItem>

      <Divider sx={divider} />

      <Box
        sx={{
          m: '16px 16px 8px',
          p: '12px',
          background: '#F4F6FA',
          borderRadius: '8px',
        }}
      >
        <Typography sx={text}>Have any questions or concerns?</Typography>
        <Typography sx={{ ...text, ...link }} component="a" href={`mailto:${supportEmail}`} onClick={closeMenu}>
          {supportEmail}
        </Typography>
      </Box>
    </Menu>
  );
};

export default memo(InfoMenu);
