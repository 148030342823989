import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const divider: SxProps<Theme> = {
  m: '29px -48px 35px',
  borderColor: 'actions.borders',
};

export const iconBox: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '28px',
  height: '28px',
  mr: '8px',
  backgroundColor: 'actions.secondaryBackground',
  borderRadius: '4px',
};

export const menu: SxProps<Theme> = {
  mt: '12px',
};

export const menuPaper: SxProps<Theme> = (theme) => {
  return {
    boxShadow: '0px 4px 14px rgba(3, 8, 72, 0.1)',
    [theme.breakpoints.down('md')]: {
      m: '-10px -10px 0',
    },
    '@media(max-width: 575px)': {
      left: '32px !important',
    },
  };
};

export const heading: SxProps<Theme> = {
  m: '8px 16px 10px',
  color: 'text.primary',
};

export const text: SxProps<Theme> = {
  color: 'text.primary',
  fontSize: '14px',
  lineHeight: '24px',
};

export const link: SxProps<Theme> = {
  color: 'primary.main',
};
