import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SuccessLargeIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 81 80"
    sx={{
      fontSize: rem(80),
    }}
    {...props}
  >
    <path
      d="M8.5 40C8.5 22.3269 22.8269 8 40.5 8C58.1731 8 72.5 22.3269 72.5 40C72.5 57.6731 58.1731 72 40.5 72C22.8269 72 8.5 57.6731 8.5 40Z"
      fill="url(#paint0_linear_1565_80345)"
      fillOpacity="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 40C10.5 56.5685 23.9315 70 40.5 70C57.0685 70 70.5 56.5685 70.5 40C70.5 23.4315 57.0685 10 40.5 10C23.9315 10 10.5 23.4315 10.5 40ZM40.5 8C22.8269 8 8.5 22.3269 8.5 40C8.5 57.6731 22.8269 72 40.5 72C58.1731 72 72.5 57.6731 72.5 40C72.5 22.3269 58.1731 8 40.5 8Z"
      fill="url(#paint1_linear_1565_80345)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.4144 30.9142L36.5002 52.8284L25.0859 41.4142L27.9144 38.5858L36.5002 47.1716L55.5859 28.0858L58.4144 30.9142Z"
      fill="url(#paint2_linear_1565_80345)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1565_80345"
        x1="15"
        y1="72"
        x2="43.239"
        y2="-3.17894"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01AB55" />
        <stop offset="1" stopColor="#E8ECF1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1565_80345"
        x1="9.53226"
        y1="75"
        x2="73.3626"
        y2="4.4778"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01AB55" />
        <stop offset="1" stopColor="#E8ECF1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1565_80345"
        x1="25.0647"
        y1="52.125"
        x2="46.621"
        y2="17.393"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01AB55" />
        <stop offset="1" stopColor="#E8ECF1" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default SuccessLargeIcon;
