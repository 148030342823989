import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ItemsMenuIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 18"
    sx={{
      fontSize: rem(18),
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6699 0.224895C8.87802 0.122952 9.12159 0.122952 9.32972 0.224895L16.5297 3.75156C16.7868 3.87749 16.9498 4.13881 16.9498 4.4251C16.9498 4.71139 16.7868 4.97271 16.5297 5.09864L9.32972 8.62531C9.12159 8.72725 8.87802 8.72725 8.66989 8.62531L1.46989 5.09864C1.21279 4.97271 1.0498 4.71139 1.0498 4.4251C1.0498 4.13881 1.21279 3.87749 1.46989 3.75156L8.6699 0.224895ZM3.50481 4.4251L8.99981 7.11663L14.4948 4.4251L8.99981 1.73357L3.50481 4.4251Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.40298 3.78941C1.62261 3.65243 1.89734 3.63838 2.1298 3.75224L9.3298 7.2789C9.5869 7.40484 9.74989 7.66616 9.74989 7.95245L9.74981 17.058C9.7498 17.3168 9.61633 17.5574 9.39671 17.6944C9.17708 17.8313 8.90235 17.8454 8.6699 17.7315L1.46989 14.2049C1.21279 14.0789 1.0498 13.8176 1.0498 13.5313L1.04989 4.42578C1.04989 4.16694 1.18336 3.92639 1.40298 3.78941ZM2.54987 5.62827L2.54981 13.0635L8.24982 15.8555L8.24988 8.42022L2.54987 5.62827Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.596 3.78941C16.3764 3.65243 16.1017 3.63838 15.8692 3.75224L8.66923 7.2789C8.41212 7.40484 8.24914 7.66616 8.24914 7.95245L8.24922 17.058C8.24922 17.3168 8.38269 17.5574 8.60232 17.6944C8.82194 17.8313 9.09668 17.8454 9.32913 17.7315L16.5291 14.2049C16.7862 14.0789 16.9492 13.8176 16.9492 13.5313L16.9491 4.42578C16.9491 4.16694 16.8157 3.92639 16.596 3.78941ZM15.4491 5.62827L15.4492 13.0635L9.74921 15.8555L9.74914 8.42022L15.4491 5.62827Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8499 6.46384L5.06445 3.07113L5.73527 1.72949L13.3499 5.53679V10.2003C13.3499 10.6145 13.0141 10.9503 12.5999 10.9503C12.1856 10.9503 11.8499 10.6145 11.8499 10.2003V6.46384Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ItemsMenuIcon;
