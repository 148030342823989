import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const InfoCircleOutlinedIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 16"
    sx={{
      ...sx,
      fill: 'none',
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 14.59C11.6396 14.59 14.59 11.6396 14.59 8C14.59 4.36044 11.6396 1.41 8 1.41C4.36044 1.41 1.41 4.36044 1.41 8C1.41 11.6396 4.36044 14.59 8 14.59ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99992 6.79501C8.38928 6.79501 8.70492 7.11065 8.70492 7.50001V12C8.70492 12.3894 8.38928 12.705 7.99992 12.705C7.61056 12.705 7.29492 12.3894 7.29492 12V7.50001C7.29492 7.11065 7.61056 6.79501 7.99992 6.79501Z"
      fill="currentColor"
    />
    <path
      d="M9 4.5C9 5.05228 8.55228 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55228 3.5 9 3.94772 9 4.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default InfoCircleOutlinedIcon;
