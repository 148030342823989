import { usePermissions } from '@app/contexts/currentUser';
import { MainMenuItem } from '@app/layouts/MainLayout/Sidebar/MainMenu/mainMenuItems';

export const useAccessDenied = (item: MainMenuItem) => {
  const permissions = usePermissions();
  const { permission, items } = item;

  if (items) {
    const groupPermissions = items.map(({ permission }) => permission).filter(Boolean);
    if (groupPermissions.length === 0) {
      return false;
    }

    return !permissions.some((item) => groupPermissions.includes(item));
  }

  return !!permission && !permissions.includes(permission);
};
