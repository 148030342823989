import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  /*  This is the example how to use default options
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
  */
});
