import { rem } from '@app/utils/sx/functions';
import { createTheme } from '@mui/material';

let theme = createTheme({
  palette: {
    secondary: {
      main: '#445277',
      negative: '#F13572',
      positive: '#01aB55',
      warning: '#FF9635',
    },
    background: {
      default: '#EEF0F6',
    },
    text: {
      primary: '#212B36',
      secondary: '#445277',
      auxiliary: '#9cabbd',
      disabledText: '#9cabbd',
      contrastText: '#fff',
    },
    neutral: {
      main: '#dde1eb',
    },
    actions: {
      borders: '#e8ecf1',
      mainBackground: '#eef0f6',
      secondaryBackground: '#f4f6fa',
      darkBackground: '#dde1eb',
      disabled: '#f9fafb',
    },
    primary: {
      main: '#6658ff',
      dark: '#3d25cc',
      light: '#6658ff',
      deepDark: '#6658ff',
      deepDarkBG: '#6658ff',
      white: '#fff',
    },
    notificationsSolid: {
      negative: '#f13572',
      positive: '#01aB55',
      warning: '#ff9635',
    },
    notificationBG: {
      negativeBG: 'linear-gradient(0deg, rgba(241, 53, 114, 0.05), rgba(241, 53, 114, 0.05)), #fff',
      positiveBG: 'linear-gradient(0deg, rgba(1, 171, 85, 0.05), rgba(1, 171, 85, 0.05)), #fff',
      warningBG: 'linear-gradient(0deg, rgba(255, 150, 53, 0.05), rgba(255, 150, 53, 0.05)), #fff',
    },
    notificationHover: {
      negativeHover: '#e72B68',
      positiveHover: '#009f4f',
      warningHover: '#f98820',
    },
  },
  typography: {
    fontFamily: '"Inter", Arial, sans-serif',
  },
});

theme = createTheme(theme, {
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltipPlacementTop: {
          marginBottom: `${rem(2)} !important`,
        },
      },
    },
    /**
     * ICON BUTTON
     */
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
    /**
     * BUTTON BASE
     **/
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '.Mui-disabled': {
            color: '#9cabbd',
            backgroundColor: '#e8ecf1',
            boxShadow: 'none',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          letterSpacing: rem(0.2),
        },
      },
    },
    /**
     * BUTTON
     **/
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: rem(14),
          lineHeight: rem(17),
          letterSpacing: rem(0.2),
          textTransform: 'uppercase',
          borderRadius: rem(8),
          '&.Mui-disabled': {
            color: theme.palette.text.disabledText,
            backgroundColor: '#e8ecf1',
          },
        },
        containedPrimary: {
          color: '#fff',
          background: '#6658FF',
          boxShadow: '0 6px 18px rgba(102, 88, 255, 0.35)',
          '&:hover:not(.Mui-disabled)': {
            background: '#3D25CC',
            boxShadow: '0 6px 18px rgba(102, 88, 255, 0.35)',
          },
        },
        containedSecondary: {
          boxShadow: 'none',
          backgroundColor: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: '#333e5a',
            boxShadow: 'none',
          },
        },
        containedNeutral: {
          boxShadow: 'none',
          backgroundColor: theme.palette.neutral.main,
          '&:hover': {
            backgroundColor: '#d3d7e1',
            boxShadow: 'none',
          },
        },
        containedError: {
          backgroundColor: theme.palette.secondary.negative,
          boxShadow: '0px 6px 18px rgba(241, 53, 114, 0.25)',
          '&:hover': {
            backgroundColor: '#e72b68',
            boxShadow: '0px 6px 18px rgba(241, 53, 114, 0.25)',
          },
        },
        outlinedError: {
          color: theme.palette.secondary.negative,
          '&:hover': {
            borderColor: theme.palette.secondary.negative,
          },
        },
        sizeSmall: {
          padding: rem(8),
          minWidth: 'auto',
          minHeight: 'auto',
        },
        sizeMedium: {
          minHeight: rem(48),
          padding: rem([15, 22]),
        },
        outlined: {
          color: '#212B36',
          borderColor: '#e8ecf1',
          '&:hover': {
            backgroundColor: '#fff',
            borderColor: '#212B36',
          },
        },
        outlinedPrimary: {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
        text: {
          color: '#445277',
          fontSize: rem(13),
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#EEF0F6',
          },
        },
        textError: {
          color: theme.palette.secondary.negative,
          '&:hover': {
            background: 'linear-gradient(0, rgba(241, 53, 114, 0.05), rgba(241, 53, 114, 0.05)), #fff',
          },
        },
        startIcon: {
          marginTop: rem(-4),
          marginBottom: rem(-4),
          marginRight: rem(2),
          '*:nth-of-type(1)': {
            fontSize: rem(24),
          },
        },
        endIcon: {
          marginTop: rem(-4),
          marginBottom: rem(-4),
          marginLeft: rem(2),
          '*:nth-of-type(1)': {
            fontSize: rem(24),
          },
        },
      },
    },
    /**
     * MENU ITEM
     */
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#212B36',
        },
      },
    },
    /**
     * POPOVER
     **/
    MuiPopover: {
      styleOverrides: {
        paper: {
          marginTop: rem(10),
          boxShadow: `0 ${rem(5)} ${rem(5)} ${rem(-3)} rgba(3, 8, 72, 0.1), 0 ${rem(3)} ${rem(14)} rgba(3, 8, 72, 0.1)`,
          borderRadius: rem(6),
          '.MuiMenuItem-root': {
            paddingTop: rem(8),
            paddingBottom: rem(8),
            '&.Mui-selected': {
              backgroundColor: '#ecebfe',
              '&:hover': {
                backgroundColor: '#dcdbf8',
              },
            },
            '&:hover': {
              backgroundColor: '#f4f6fa',
            },
          },
        },
      },
    },
    /**
     * SNACKBAR
     **/
    MuiSnackbar: {
      styleOverrides: {
        root: {
          right: rem(12),
          left: rem(12),
        },
      },
    },
    /**
     * CHIP
     **/
    MuiChip: {
      styleOverrides: {
        colorDefault: {},
        colorPrimary: {
          color: theme.palette.primary.main,
          backgroundColor: 'rgba(102, 88, 255, 0.1)',
        },
        colorSecondary: {},
        colorError: {
          color: theme.palette.secondary.negative,
          backgroundColor: 'rgba(241, 53, 114, 0.1)',
        },
        colorInfo: {},
        colorSuccess: {
          color: theme.palette.secondary.positive,
          backgroundColor: 'rgba(1, 171, 85, 0.1)',
        },
        colorWarning: {
          color: theme.palette.secondary.warning,
          backgroundColor: 'rgba(255, 150, 53, 0.1)',
        },
      },
    },
    /**
     * ALERT
     **/
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: rem([12, 16]),
          borderRadius: rem(6),
          boxShadow: 'none',
        },
        icon: {
          display: 'none',
        },
        message: {
          padding: 0,
          color: '#fff',
          fontWeight: 500,
          fontSize: rem(12),
          lineHeight: rem(19),
        },
        action: {
          paddingTop: 0,
          '.MuiButtonBase-root': {
            padding: 0,
          },
        },
        filledSuccess: {
          backgroundColor: '#01ab55',
        },
        filledError: {
          backgroundColor: '#f13572',
        },
        filledInfo: {
          backgroundColor: '#6658FF',
        },
        filledWarning: {
          backgroundColor: '#FF9635',
        },
        standardWarning: {
          backgroundColor: '#FF9635',
        },
      },
    },
    /**
     * AUTOCOMPLETE
     **/
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          paddingLeft: `${rem(10)} !important`,
        },
        endAdornment: {
          top: '50%',
          right: rem(12),
          transform: 'translateY(-50%)',
        },
        popupIndicator: {
          svg: {
            color: '#9cabbd',
          },
        },
        paper: {
          boxShadow: '0px 5px 5px -3px rgba(3, 8, 72, 0.1), 0px 3px 14px rgba(3, 8, 72, 0.1)',
          borderRadius: rem(6),
        },
        option: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: rem(14),
        },
        noOptions: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: rem(14),
        },
        loading: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: rem(14),
        },
      },
    },
    /**
     * TABLE ROW
     **/
    MuiTableRow: {
      styleOverrides: {
        root: {
          color: '#212b36',
        },
      },
    },
    /**
     * TABLE CELL
     **/
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: rem(60),
          color: '#212b36',
          borderBottomColor: '#E8E9EB',
          [theme.breakpoints.up('xs')]: {
            padding: rem(16),
          },
          [theme.breakpoints.up('sm')]: {
            padding: rem(8),
          },
        },
        head: {
          height: rem(48),
          [theme.breakpoints.up('xs')]: {
            height: rem(32),
            padding: rem(16),
          },
          [theme.breakpoints.up('sm')]: {
            padding: rem(8),
          },
        },
      },
    },
    /**
     * ICON
     */
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: theme.palette.text.auxiliary,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
    /**
     * SELECT
     */
    MuiSelect: {
      styleOverrides: {
        select: {
          '~ .MuiSvgIcon-root': {
            position: 'absolute',
            right: rem(12),
            flexShrink: 0,
            color: theme.palette.text.auxiliary,
          },
          '&.Mui-disabled ~ .MuiSvgIcon-root': {
            display: 'none',
          },
        },
      },
    },
    /**
     * INPUT
     **/
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: rem(48),
          padding: rem([15, 16]),
          boxSizing: 'border-box',
          '&::placeholder': {
            color: '#9cabbd',
            fontWeight: 500,
            fontSize: rem(14),
            opacity: 1,
          },
        },
        adornedEnd: {
          paddingRight: rem(4),
        },
        multiline: {
          minHeight: rem(84),
          padding: `${rem(15, 16)} !important`,
          textarea: {
            marginBottom: 'auto',
            padding: 0,
          },
        },
      },
    },
    /**
     * OUTLINED INPUT
     **/
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#212B36',
          fontWeight: 500,
          fontSize: rem(14),
          lineHeight: rem(18),
          borderRadius: rem(6),
        },
        input: {
          padding: rem([15, 16]),
        },
        notchedOutline: {
          borderColor: '#E8ECF1',
        },
      },
    },
    /**
     * ACCORDION
     **/
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&::before': {
            backgroundColor: '#e8ecf1',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
          '&.Mui-expanded::before': {
            opacity: 1,
          },
        },
      },
    },
    /**
     * ACCORDION SUMMARY
     **/
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: rem(75),
          padding: 0,
          borderBottom: '1px solid #e8ecf1',
          '&.Mui-expanded': {
            minHeight: rem(75),
            borderBottom: 'transparent',
          },
        },
        expandIconWrapper: {
          '.MuiIconButton-root': {
            width: rem(24),
            height: rem(24),
            color: '#9CABBD',
            borderRadius: rem(6),
            backgroundColor: '#f4f6fa',
          },
        },
      },
    },
    /**
     * ACCORDION DETAILS
     **/
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingBottom: rem(24),
          borderBottom: '1px solid #e8ecf1',
        },
      },
    },
  },
});

export { theme };

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    deepDark?: string;
    deepDarkBG?: string;
    white?: string;
    negative?: string;
    positive?: string;
    warning?: string;
  }

  interface SimplePaletteColorOptions {
    deepDark?: string;
    deepDarkBG?: string;
    white?: string;
    negative?: string;
    positive?: string;
    warning?: string;
  }

  interface TypeText {
    auxiliary?: string;
    disabledText?: string;
    contrastText?: string;
  }

  interface ActionsPaletteColorOptions {
    borders?: string;
    mainBackground?: string;
    secondaryBackground?: string;
    darkBackground?: string;
    disabled?: string;
  }

  interface NotificationsSolidPaletteColorOptions {
    negative?: string;
    positive?: string;
    warning?: string;
  }

  interface NotificationBGPaletteColorOptions {
    negativeBG?: string;
    positiveBG?: string;
    warningBG?: string;
  }

  interface NotificationHoverPaletteColorOptions {
    negativeHover?: string;
    positiveHover?: string;
    warningHover?: string;
  }

  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    actions?: ActionsPaletteColorOptions;
    notificationsSolid?: NotificationsSolidPaletteColorOptions;
    notificationBG?: NotificationBGPaletteColorOptions;
    notificationHover?: NotificationHoverPaletteColorOptions;
    neutral: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}
