import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const UserIcon = ({ ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 16 16"
    sx={{
      fontSize: rem(16),
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.881829 14.5393C2.11754 11.8469 4.83802 9.97498 7.99701 9.97498C11.156 9.97498 13.8765 11.8469 15.1122 14.5393C15.2562 14.853 15.1186 15.224 14.8049 15.368C14.4912 15.512 14.1201 15.3744 13.9761 15.0607C12.9366 12.7958 10.6496 11.225 7.99701 11.225C5.34441 11.225 3.05738 12.7958 2.01789 15.0607C1.87391 15.3744 1.50287 15.512 1.18915 15.368C0.875439 15.224 0.737845 14.853 0.881829 14.5393Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24707 5C5.24707 6.51878 6.47829 7.75 7.99707 7.75C9.51585 7.75 10.7471 6.51878 10.7471 5C10.7471 3.48122 9.51585 2.25 7.99707 2.25C6.47829 2.25 5.24707 3.48122 5.24707 5ZM7.99707 1C5.78793 1 3.99707 2.79086 3.99707 5C3.99707 7.20914 5.78793 9 7.99707 9C10.2062 9 11.9971 7.20914 11.9971 5C11.9971 2.79086 10.2062 1 7.99707 1Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default UserIcon;
