import { serverUrl } from '@app/constants/environments';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosStatic } from 'axios';
import { apiInterceptor } from './interceptors/api.interceptor';
import { BaseSuccessResponse } from './types';

class HttpClient {
  private api: AxiosInstance;

  private config: AxiosRequestConfig = {
    baseURL: serverUrl,
    responseType: 'json',
    headers: {
      common: {
        Accept: 'application/json',
      },
    },
  };

  constructor(axios: AxiosStatic) {
    this.api = axios.create(this.config);
    this.initInterceptors();
  }

  public get<T, R = BaseSuccessResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.get(url, config);
  }

  public delete<T, R = BaseSuccessResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete(url, config);
  }

  public post<T, R = BaseSuccessResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
    return this.api.post(url, data, config);
  }

  public put<T, D, R = BaseSuccessResponse<T>>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> {
    return this.api.put(url, data, config);
  }

  public patch<T, D, R = BaseSuccessResponse<T>>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> {
    return this.api.patch(url, data, config);
  }

  private initInterceptors(): void {
    this.api.interceptors.request.use(apiInterceptor);
    this.api.interceptors.response.use((response) => response.data);
  }
}

export const httpClient = new HttpClient(axios);
