import { useCurrentUserQuery } from '@app/api/hooks/useCurrentUserQuery';
import { verifyPhone } from '@app/constants/profile';
import { userRole } from '@app/constants/user-roles';
import { useAuth } from '@app/contexts/auth';
import { useSnackbar } from '@app/contexts/snackbar';
import { User } from '@app/pages/users/types';
import { currentUserService } from '@app/utils/services/currentUserService';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type CurrentUser = User | null;

const CurrentUserContext = createContext<CurrentUser>(null);

export const useCurrentUser = () => useContext<CurrentUser>(CurrentUserContext);

export const useCurrentUserNameRole = () => {
  const currentUser = useContext<CurrentUser>(CurrentUserContext);

  return {
    userId: currentUser?.userId ?? '',
    firstName: currentUser?.firstName ?? '',
    lastName: currentUser?.lastName ?? '',
    role: currentUser?.role ? userRole[currentUser?.role] : '',
  };
};

export const usePermissions = () => {
  const currentUser = useContext<CurrentUser>(CurrentUserContext);

  return currentUser?.permissions || [];
};

interface Props {
  children: ReactNode;
}

const CurrentUserProvider = ({ children }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState<User>(currentUserService.currentUser);
  const { isAuthorized } = useAuth();
  const { data } = useCurrentUserQuery({ cacheTime: 0 });
  const { open } = useSnackbar();

  useEffect(() => {
    if (isAuthorized && data) {
      setCurrentUser(data);
      if (!data.phoneVerified) {
        open({
          severity: 'warning',
          message: t(verifyPhone),
          duration: null,
        });
      }
      currentUserService.set(data);
    }
  }, [isAuthorized, data, setCurrentUser]);

  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>;
};

export default CurrentUserProvider;
