import { rem } from '@app/utils/sx/functions';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const inputStyles: SxProps<Theme> = (theme) => {
  return {
    color: 'inherit',
    height: rem(40),

    '& .MuiInputBase-root': {
      paddingLeft: rem(12),
      paddingRight: rem(8),
      '& .MuiInputBase-input': {
        height: rem(40),
        lineHeight: rem(40),
        padding: theme.spacing(1, 0, 1, 0),
        transition: theme.transitions.create('width'),
        width: '100%',
        color: '#fff',
        [theme.breakpoints.up('md')]: {
          width: rem(160),
          color: 'inherit',
        },
      },
      '.MuiInputAdornment-positionEnd': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: rem(52),
        cursor: 'pointer',
      },
    },
  };
};

export const clearBth: SxProps<Theme> = {
  mr: rem(4),
};
