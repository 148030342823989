import { queryClient } from '@app/api/queryClient';
import AuthProvider from '@app/contexts/auth';
import { theme } from '@app/utils/material-ui/theme';
import { ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from 'react';
import { Helmet } from 'react-helmet';
import { QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import AppRoutes from './routes/AppRoutes';
import { initValidationConfig } from './utils/translation/initValidationConfig';

const App = (): JSX.Element => {
  dayjs.extend(duration);
  initValidationConfig();

  return (
    <>
      <Helmet defaultTitle="Pointsmaker" titleTemplate="%s | Pointsmaker" />

      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </ThemeProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </>
  );
};

export default App;
