import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CheckboxIndeterminateIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" stroke="#E8ECF1" />
    <path d="M17 10.5L7.00002 10.5005L7 13.5001L17 13.5V10.5Z" fill="#6658FF" />
  </SvgIcon>
);

export default CheckboxIndeterminateIcon;
