import { UserRoles } from '@app/pages/users/types';

export enum VerifyTokenActions {
  EmailVerification = 'email-verification',
  VerificationCode = 'verification-code',
  ChangePassword = 'change-password',
  AccountCreationVerification = 'account-creation-verification',
  ChangeEmail = 'change-email',
}

export interface LoginContext {
  email: string;
  password: string;
  timeOffset: number;
}

export interface RegistrationContext {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  businessName?: string;
  address: string;
  referralSource: string;
  reasonForJoin: number;
  apt?: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  cellPhone: string;
  workPhone?: string;
  termsAndConditions: number;
}

export interface ChangePasswordContext {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

export enum AuthSocialTypes {
  facebook = 'facebook',
  google = 'google',
}

export interface SocialLoginContext {
  type: AuthSocialTypes;
  context: {
    token: string;
  };
}

export interface Credentials {
  token: string;
  expiredAt: number;
  phone?: string;
  roleId: UserRoles;
}

export interface SendVerificationCodeContext {
  method: 'email' | 'phone';
  email: string;
  phone: string | null;
}

export interface VerifyCodeContext {
  email: string;
  code: string;
  timeOffset: number;
}

export interface VerifyTokenContext {
  token: string;
  action: VerifyTokenActions;
}

export interface ListQueryContext {
  page?: number;
  perPage?: number;
  trackingNumber?: string;
  id?: number;
  search?: string;
  receiptId?: string;
  sort?: string;
  itemIds?: string;
}

export interface InvoiceConnectedItemsQueryContext {
  invoiceId?: string;
  page?: number;
  perPage?: number;
  sort?: string;
}

export interface ListWithItemFiltersQueryContext extends ListQueryContext {
  items_itemIds?: string;
  items_status?: string;
  items_hasCost?: string;
  items_hasPrice?: string;
  items_hasCommission?: string;
  items_hasUpc?: string;
  items_hasAsin?: string;
  items_hasModel?: string;
  items_hasBrand?: string;
  items_serialRequired?: string;
  items_serials?: string;
  items_sellercloud?: string;
  items_hasInventory?: string;
  items_hasReceipts?: string;
  items_hasInvoices?: string;
  items_warehouseId?: string;
  items_createdAt?: string;
  items_receivingDate?: string;
}

export interface ExportCsvQueryContext {
  buyerIds?: string;
  createdBy?: string;
  createdDate?: string;
  employeeIds?: string;
  enabled?: boolean;
  files?: string;
  id?: number;
  lastMessage?: string;
  priority?: string;
  search?: string;
  sku?: string;
  sort?: string;
  status?: string;
  tracking?: string;
  type?: string;
  upc?: string;
  warehouseId?: string;
}

export interface ExportPdfQueryContext extends ExportCsvQueryContext {
  ids?: number[];
}

export interface ExportQueryResult {
  url: string;
}

export enum UploadFileModel {
  Item = 1,
}

export interface UploadFileContext {
  imageFile: File;
  model?: UploadFileModel;
}

export interface UploadFileContextTicket {
  file: File;
}
