import React, { SVGAttributes } from 'react';

const SimpleArrowRight = (props: SVGAttributes<any>): JSX.Element => (
  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.58579 5L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L6.41421 5L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711Z"
      fill="currentColor"
    />
  </svg>
);

export default SimpleArrowRight;
