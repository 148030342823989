import { rem } from '@app/utils/sx/functions';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const dialog = (width: number): SxProps<Theme> => {
  return {
    '.MuiPaper-root': {
      width: '100%',
      maxWidth: width ? rem(width) : rem(405),
      borderRadius: rem(8),
      boxShadow: `0 ${rem(4)} ${rem(14)} rgba(3, 8, 72, 0.1)`,
    },
  };
};

export const dialogTitle: SxProps<Theme> = {
  mb: rem(24),
  p: { xs: rem([24, 20, 0]), md: rem([32, 32, 0]) },
  fontSize: rem(22),
  lineHeight: rem(27),
  textAlign: 'center',
  wordBreak: 'break-word',
};

export const dialogContent: SxProps<Theme> = {
  p: { xs: rem([0, 20, 24]), md: rem([0, 32, 24]) },
  textAlign: 'left',
};

export const contentText: SxProps<Theme> = {
  color: 'text.primary',
  fontSize: rem(14),
  lineHeight: rem(24),
  textAlign: 'center',
  '.MuiTypography-root': {
    mb: rem(20),
    color: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    '&:last-of-type': {
      mb: 0,
    },
  },
};

export const buttonGroup: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: { xs: 'column', md: 'row' },
  p: { xs: rem([0, 20, 24]), md: rem([0, 32, 32]) },
};

export const button: SxProps<Theme> = {
  width: { xs: '100%', md: 'auto' },
  '&:first-of-type': {
    mb: { xs: rem(12), md: 0 },
  },
  '&:last-of-type': {
    ml: { xs: 0, md: rem(12) },
  },
};
