import { httpClient } from '@app/api/http/http-client';

export interface AppConfig {
  parameters: { [key: string]: string };
}

export class ConfigService {
  static get = async (): Promise<AppConfig> => {
    const response = await httpClient.get<AppConfig>('/config');

    return response?.result || null;
  };
}
