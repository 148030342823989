import { rem } from '@app/utils/sx/functions';
import { Menu as MuiMenu } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import * as React from 'react';

const StyledMenu = styled((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: rem(8),
    marginTop: rem(21),
    minWidth: rem(340),
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: '0px 4px 14px rgba(3, 8, 72, 0.1)',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      textTransform: 'none',
      fontWeight: 'normal',
      '& .MuiSvgIcon-root': {
        // fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default StyledMenu;
