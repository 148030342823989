import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DeleteIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      ...sx,
    }}
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#E8ECF1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9394 12.0001L7.00006 8.06072C6.70716 7.76782 6.70716 7.29295 7.00006 7.00006C7.29295 6.70716 7.76782 6.70716 8.06072 7.00006L12.0001 10.9394L15.9394 7.00006C16.2323 6.70716 16.7072 6.70716 17.0001 7.00006C17.2929 7.29295 17.2929 7.76782 17.0001 8.06072L13.0607 12.0001L17.0001 15.9394C17.2929 16.2323 17.2929 16.7072 17.0001 17.0001C16.7072 17.2929 16.2323 17.2929 15.9394 17.0001L12.0001 13.0607L8.06072 17.0001C7.76782 17.2929 7.29295 17.2929 7.00006 17.0001C6.70716 16.7072 6.70716 16.2323 7.00006 15.9394L10.9394 12.0001Z"
      fill="#9CABBD"
    />
  </SvgIcon>
);

export default DeleteIcon;
