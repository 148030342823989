import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ItemIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    sx={{
      ...sx,
      fill: 'none',
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7932 3.24132C11.1602 3.0298 11.5764 2.91846 12 2.91846C12.4236 2.91846 12.8397 3.02979 13.2067 3.24129C13.2072 3.2416 13.2078 3.24192 13.2083 3.24223L19.0417 6.57556C19.4087 6.78745 19.7135 7.09214 19.9256 7.45905C20.1377 7.82596 20.2496 8.24219 20.25 8.66598V15.3334C20.2496 15.7572 20.1377 16.1742 19.9256 16.5411C19.7135 16.908 19.4087 17.2127 19.0417 17.4246L19.0388 17.4263L13.2083 20.7579C13.2078 20.7582 13.2073 20.7586 13.2067 20.7589C12.8397 20.9704 12.4236 21.0817 12 21.0817C11.5764 21.0817 11.1602 20.9704 10.7932 20.7588C10.7927 20.7585 10.7922 20.7582 10.7917 20.7579L4.96123 17.4263L4.95833 17.4246C4.59132 17.2127 4.28648 16.908 4.07439 16.5411C3.86231 16.1742 3.75044 15.758 3.75 15.3342L3.75 8.66675C3.75043 8.24295 3.86231 7.82596 4.07439 7.45905C4.28648 7.09214 4.59132 6.78746 4.95833 6.57556L4.96123 6.57389L10.7932 3.24132ZM12 4.41846C11.8391 4.41846 11.681 4.46081 11.5417 4.54127L11.5388 4.54294L5.70833 7.8746C5.70794 7.87483 5.70754 7.87506 5.70714 7.87529C5.56846 7.95564 5.45326 8.07094 5.37304 8.20972C5.29266 8.34879 5.25023 8.50654 5.25 8.66717V15.333C5.25023 15.4936 5.29266 15.6514 5.37304 15.7904C5.45325 15.9292 5.56843 16.0445 5.70708 16.1248C5.7075 16.1251 5.70792 16.1253 5.70833 16.1256L11.5417 19.4589C11.681 19.5394 11.8391 19.5817 12 19.5817C12.1609 19.5817 12.319 19.5394 12.4583 19.4589L12.4612 19.4572L18.2917 16.1256C18.2921 16.1253 18.2925 16.1251 18.2929 16.1248C18.4316 16.0445 18.5468 15.9292 18.627 15.7904C18.7074 15.6513 18.7498 15.4934 18.75 15.3326V8.66752C18.7498 8.50677 18.7074 8.34889 18.627 8.20972C18.5467 8.07094 18.4315 7.95564 18.2929 7.87529C18.2925 7.87506 18.2921 7.87483 18.2917 7.8746L12.4583 4.54127C12.319 4.46082 12.1609 4.41846 12 4.41846Z"
      fill="#212B36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07553 7.42439C4.28294 7.06584 4.74173 6.94332 5.10028 7.15072L11.9997 11.1418L18.8992 7.15072C19.2577 6.94332 19.7165 7.06584 19.9239 7.42439C20.1313 7.78293 20.0088 8.24173 19.6503 8.44913L12.3753 12.6575C12.143 12.7919 11.8565 12.7919 11.6242 12.6575L4.34919 8.44913C3.99065 8.24173 3.86812 7.78293 4.07553 7.42439Z"
      fill="#212B36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.70639 5.12458C7.91379 4.76604 8.37259 4.64351 8.73114 4.85092L16.0061 9.05925C16.2379 9.19331 16.3806 9.44073 16.3806 9.70846V13.2352C16.3806 13.6494 16.0448 13.9852 15.6306 13.9852C15.2164 13.9852 14.8806 13.6494 14.8806 13.2352V10.1411L7.98005 6.14933C7.6215 5.94192 7.49898 5.48313 7.70639 5.12458Z"
      fill="#212B36"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.25C12.4142 11.25 12.75 11.5858 12.75 12V20.4C12.75 20.8142 12.4142 21.15 12 21.15C11.5858 21.15 11.25 20.8142 11.25 20.4V12C11.25 11.5858 11.5858 11.25 12 11.25Z"
      fill="#212B36"
    />
  </SvgIcon>
);

export default ItemIcon;
