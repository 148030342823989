import { SvgIconProps } from '@mui/material';
import React from 'react';

const UploadImage = (props: SvgIconProps): JSX.Element => (
  <svg width="130" height="117" viewBox="0 0 130 117" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity="0.15"
      d="M93.4234 55.8847C80.0542 70.6371 61.6311 80.7319 41.9926 84.067C33.0514 85.5851 23.4568 85.6462 15.3735 81.5375C4.64732 76.0855 -1.67531 63.1287 0.632996 51.3297C2.32714 42.6706 7.96322 35.384 13.4302 28.4541C18.5351 21.9831 23.7333 15.4226 30.4303 10.6144C38.4441 4.86132 48.212 1.93764 58.009 0.752042C68.8936 -0.565158 80.5432 0.327597 89.7693 6.2461C98.9521 12.1362 101.416 20.6748 102.667 30.807C103.924 40.9824 100.371 48.2186 93.4234 55.8847Z"
      fill="#9CABBD"
    />
    <path
      opacity="0.15"
      d="M128.957 95.4545C126.424 105.78 117.694 114.524 107.208 116.343C96.723 118.162 85.1796 112.467 80.8965 102.735C77.6061 95.2577 78.6458 86.4143 82.0246 78.9759C84.162 74.2702 87.2708 69.8978 91.5254 66.9593C96.9574 63.2074 103.991 62.074 110.472 63.3456C126.371 66.4649 132.691 80.2308 128.957 95.4545Z"
      fill="#9CABBD"
    />
    <path d="M110.171 61.1901L114.33 67.8686H107.604V60.231L110.171 61.1901Z" fill="url(#paint0_linear_375_52076)" />
    <path d="M89.2474 97.7282L110.199 106.681V61.1904L89.2474 53.4473V97.7282Z" fill="#212B36" />
    <path d="M21.3991 53.4473H89.2195V97.5687H21.3991V53.4473Z" fill="#212B36" />
    <path d="M82.0809 14.954L83.2244 74.7466L23.3717 75.8888L22.2282 16.0963L82.0809 14.954Z" fill="white" />
    <path d="M79.0052 18.5799L79.7679 58.4673L26.2034 59.4895L25.4406 19.6022L79.0052 18.5799Z" fill="#212B36" />
    <path
      d="M28.594 57.6364L28.3181 43.2284L36.1391 35.1106C37.2036 34.0056 38.963 33.9718 40.0697 35.0353L53.4917 47.9406C54.5533 48.9608 56.2415 48.9288 57.2634 47.8682L64.4559 40.4025C65.4279 39.3936 67.0342 39.3628 68.0442 40.3338L77.1569 49.0977L77.3029 56.7051L28.594 57.6364Z"
      fill="white"
    />
    <path
      d="M66.8491 27.4786C66.8912 29.6661 68.7005 31.4059 70.8901 31.3638C73.0797 31.3217 74.8213 29.5149 74.7792 27.3269C74.7371 25.1394 72.9284 23.3996 70.7382 23.4417C68.5485 23.4838 66.8075 25.2906 66.8491 27.4786Z"
      fill="white"
    />
    <path d="M82.7056 17.5787L91.9427 76.6655L32.7964 85.8934L23.5593 26.8065L82.7056 17.5787Z" fill="white" />
    <path d="M80.1491 21.5868L86.3112 61.0034L33.3788 69.2618L27.2168 29.8451L80.1491 21.5868Z" fill="#212B36" />
    <path
      d="M35.4982 67.0981L33.2724 52.8602L39.9214 43.7596C40.8263 42.5206 42.5656 42.2497 43.8058 43.1537L58.8525 54.1253C60.0422 54.9931 61.7109 54.7323 62.5796 53.5437L68.6945 45.1746C69.5205 44.0435 71.1085 43.7957 72.2407 44.6215L82.4577 52.0712L83.6327 59.5891L35.4982 67.0981Z"
      fill="white"
    />
    <path
      d="M69.3152 32.0451C69.6529 34.207 71.6811 35.686 73.8452 35.3481C76.0093 35.0108 77.4898 32.984 77.1516 30.8227C76.8134 28.6608 74.7851 27.1823 72.6216 27.5196C70.4575 27.8569 68.9776 29.8831 69.3152 32.0451Z"
      fill="white"
    />
    <path d="M59.041 10.5594L117.283 24.3862L103.443 82.5701L45.2003 68.7433L59.041 10.5594Z" fill="white" />
    <path d="M61.2753 14.7552L113.399 27.1294L104.166 65.9436L52.0422 53.5694L61.2753 14.7552Z" fill="#212B36" />
    <path
      d="M54.8239 52.373L58.1588 38.3527L67.7611 32.4437C69.0684 31.6393 70.7803 32.046 71.5855 33.3519L81.3558 49.1968C82.1284 50.45 83.7715 50.8401 85.026 50.0682L93.8569 44.6334C95.0502 43.899 96.6132 44.2701 97.3484 45.4622L103.983 56.2215L102.223 63.6244L54.8239 52.373Z"
      fill="white"
    />
    <path
      d="M99.4063 32.7156C98.9001 34.8444 100.217 36.9802 102.348 37.4859C104.479 37.9915 106.617 36.6761 107.123 34.5474C107.629 32.4186 106.312 30.2828 104.181 29.7771C102.05 29.2715 99.9125 30.5869 99.4063 32.7156Z"
      fill="white"
    />
    <path d="M42.3508 61.1904H110.171V106.681H42.3508V61.1904Z" fill="url(#paint1_linear_375_52076)" />
    <path d="M21.3991 97.7282L42.3507 106.681V61.1904L21.3991 53.4473V97.7282Z" fill="#10C66A" />
    <path
      d="M42.3507 61.1904L37.8914 70.1944L14.9982 61.1904L21.3991 53.4473L42.3507 61.1904Z"
      fill="url(#paint2_linear_375_52076)"
    />
    <path d="M90.7669 95.4712L98.7232 87.5229L106.68 95.4712H102.991V103.042H94.4555V95.4712H90.7669Z" fill="#0C7B43" />
    <path d="M72.2632 102.362V104.042H70.5815" stroke="#0C7B43" strokeMiterlimit="10" />
    <path d="M67.3261 104.042H49.4228" stroke="#0C7B43" strokeMiterlimit="10" strokeDasharray="5.49 5.49" />
    <path d="M47.7951 104.042H46.1129V102.362" stroke="#0C7B43" strokeMiterlimit="10" />
    <path d="M46.1129 99.11V81.2246" stroke="#0C7B43" strokeMiterlimit="10" strokeDasharray="5.49 5.49" />
    <path d="M46.1129 79.5986V77.918H47.7951" stroke="#0C7B43" strokeMiterlimit="10" />
    <path d="M51.0505 77.918H68.9538" stroke="#0C7B43" strokeMiterlimit="10" strokeDasharray="5.49 5.49" />
    <path d="M70.5815 77.918H72.2632V79.5986" stroke="#0C7B43" strokeMiterlimit="10" />
    <path d="M72.2632 82.8506V100.736" stroke="#0C7B43" strokeMiterlimit="10" strokeDasharray="5.49 5.49" />
    <path
      d="M52.483 89.4587C52.8538 89.4587 53.2123 89.5144 53.5499 89.6164C54.1658 87.0241 56.4979 85.0957 59.2809 85.0957C62.0467 85.0957 64.3669 87.0004 65 89.5684C65.2861 89.4972 65.5851 89.4587 65.8931 89.4587C67.9213 89.4587 69.5662 91.1014 69.5662 93.1281C69.5662 95.1549 67.9219 96.7976 65.8931 96.7976H60.1787C59.8856 96.8426 59.5859 96.8657 59.2803 96.8657C58.9747 96.8657 58.6751 96.8426 58.3819 96.7976H52.4824C50.4542 96.7976 48.8093 95.1549 48.8093 93.1281C48.8099 91.1014 50.4548 89.4587 52.483 89.4587Z"
      fill="#0C7B43"
    />
    <defs>
      <linearGradient
        id="paint0_linear_375_52076"
        x1="110.967"
        y1="60.231"
        x2="115.814"
        y2="65.1325"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01AB55" />
        <stop offset="1" stopColor="#0C7B43" />
      </linearGradient>
      <linearGradient id="paint1_linear_375_52076" x1="73" y1="107" x2="108.5" y2="61" gradientUnits="userSpaceOnUse">
        <stop stopColor="#01AB55" />
        <stop offset="1" stopColor="#2ADE83" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_375_52076"
        x1="28.6745"
        y1="53.4473"
        x2="37.5"
        y2="70"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#01AB55" />
        <stop offset="1" stopColor="#0C7B43" />
      </linearGradient>
    </defs>
  </svg>
);

export default UploadImage;
