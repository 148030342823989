import { httpClient } from '@app/api/http/http-client';
import {
  ChangePasswordContext,
  Credentials,
  LoginContext,
  RegistrationContext,
  SendVerificationCodeContext,
  VerifyCodeContext,
  VerifyTokenContext,
} from '@app/utils/services/types';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
class AuthService {
  /**
   * Authenticates the user.
   *
   * @param context The login parameters.
   * @return The user credentials.
   */
  public login = ({ email, password, timeOffset }: LoginContext): Promise<Credentials> => {
    return httpClient.post<Credentials>('user/login', { email, password, timeOffset }).then(({ result }) => result);
  };

  /**
   * Register new user.
   *
   * @param context Registration parameters. API support only 2 params for now - email and password.
   *
   */
  public register = (data: RegistrationContext): Promise<void> => {
    return httpClient.post<void>('user/register', data).then(() => {});
  };

  /**
   * Check the uniqueness of email
   *
   * @param email: string
   */
  public checkEmailUniqueness(email: string): Promise<void> {
    return httpClient.post<void>('email/check', { email }).then(() => {});
  }

  /**
   * Check the uniqueness of phone
   *
   * @param phone: string
   */
  public checkPhoneUniqueness(phone: string): Promise<void> {
    return httpClient.post<void>('phone/check', { phone }).then(() => {});
  }

  /**
   * Verify user email after registration
   *
   * @param token
   */
  public verifyEmail(token: string): Promise<void> {
    return httpClient.post<void>('email/verification', { token }).then(() => {});
  }

  /**
   * Resend link for verify user email after registration
   *
   * @param email: string
   */
  public resendVerifyEmailLink = (email: string): Promise<void> => {
    return httpClient.post<void>('email/resend', { email }).then(() => {});
  };

  /**
   * Logs out the user and clear credentials.
   * @param forgetDevice: boolean
   * @return Void if the user was logged out successfully.
   */
  public logout = (forgetDevice: boolean): Promise<void> => {
    const data = {
      mode: forgetDevice ? 'device' : 'signout',
    };

    return httpClient.post<void>('user/logout', data).then(() => {});
  };

  /**
   * Change current user password
   * @param context The Change Password parameters
   * @returns Void
   */
  public changePassword = (context: ChangePasswordContext): Promise<void> => {
    return httpClient.patch<void, ChangePasswordContext>('user/change-password', context).then(() => {});
  };

  /**
   * Send Verification Code to user's phone or email.
   * @param data: SendVerificationCodeContext
   * @returns Void
   */
  public sendVerificationCode = (data: SendVerificationCodeContext): Promise<void> => {
    return httpClient.post<void>('user/send-code', data).then(() => {});
  };

  /**
   * Verify Code
   * @param data: VerifyCodeContext
   * @returns Void
   */
  public verifyCode = (data: VerifyCodeContext): Promise<Credentials> => {
    return httpClient.post<Credentials>('user/verify-code', data).then(({ result }) => result);
  };

  /**
   * Verify token specified by action
   *
   * @param data: VerifyCodeContext
   */
  public verifyToken = (data: VerifyTokenContext): Promise<void> => {
    return httpClient.post<void>('user/verify-token', data).then(() => {});
  };
}

export const authService = new AuthService();
