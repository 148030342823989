import { UsersListQueryContext } from '@app/api/hooks/useUsersListQuery';
import { httpClient } from '@app/api/http/http-client';
import { EnableContext, ResultWithPagination } from '@app/api/http/types';
import {
  ChangeEmailContext,
  CreateUserContext,
  NewPasswordContext,
  SearchBuyerContext,
  SearchUserContext,
  UpdatePasswordContext,
  UpdateUserContext,
  User,
  UserRoles,
} from '@app/pages/users/types';
import { getUserRolesWithWholesaler } from '@app/utils/helpers/getUserRolesWithWholesaler';
import { ExportQueryResult } from '@app/utils/services/types';

class UsersService {
  public getList = async ({
    page,
    perPage,
    search,
    sort,
    role,
    wholesaler,
    enabled,
    withBussiness,
    state,
    createdDate,
  }: UsersListQueryContext): Promise<ResultWithPagination<User[]>> => {
    const params = {
      page,
      perPage,
      search: search ? search : null,
      ...(sort && { sort }),
      enabled: enabled ? Boolean(+enabled) : null,
      withBussiness: withBussiness || null,
      state: state || null,
      createdDate: createdDate || null,
      ...getUserRolesWithWholesaler(role, wholesaler),
    };

    const {
      result,
      _meta: { pagination },
    } = await httpClient.get<User[]>('user/list', { params });

    return {
      result,
      pagination,
    };
  };

  public get = async (id: number): Promise<User> => {
    const { result } = await httpClient.get<User>(`user/${id}`);

    return result;
  };

  public create = (data: CreateUserContext): Promise<User> => {
    return httpClient.post<User>('user/create', data).then(({ result }) => result);
  };

  public update = async (data: Partial<User>): Promise<User> => {
    const { result } = await httpClient.put<User, Partial<User>>(`user/${data.id}`, data);

    return result;
  };

  public remove = async (id: number): Promise<void> => {
    await httpClient.delete<User>('user', { data: { id } });

    return null;
  };

  public enable = (data: EnableContext): Promise<void> => {
    return httpClient.post<void>('user/enable', data).then(() => {});
  };

  public getCurrent(): Promise<User> {
    return httpClient.get<User>('user/current').then(({ result }) => result);
  }

  public export({
    search,
    sort,
    role,
    wholesaler,
    enabled,
    withBussiness,
    state,
    createdDate,
  }: UsersListQueryContext): Promise<ExportQueryResult> {
    const params = {
      search: search ? encodeURIComponent(search) : null,
      ...(sort && { sort }),
      enabled: enabled ? Boolean(+enabled) : null,
      withBussiness: withBussiness || null,
      state: state || null,
      createdDate: createdDate || null,
      ...getUserRolesWithWholesaler(role, wholesaler),
    };

    return httpClient.get<ExportQueryResult>('user/list/export', { params }).then(({ result }) => result);
  }

  public changeEmail(data: ChangeEmailContext): Promise<void> {
    return httpClient.post<void>('/user/change-email', data).then(() => {});
  }

  public updatePassword(data: UpdatePasswordContext): Promise<void> {
    return httpClient.post<void>('/user/change-password', data).then(() => {});
  }

  public newPassword({ id, ...data }: NewPasswordContext): Promise<void> {
    return httpClient.post<void>(`/user/${id}/change-password`, data).then(() => {});
  }

  public updateCurrent(user: User): Promise<User> {
    return httpClient.put<User, User>('user/current', user).then(({ result }) => result);
  }

  public updateUserDetails(user: UpdateUserContext): Promise<User> {
    return httpClient.put<User, UpdateUserContext>('user/current', user).then(({ result }) => result);
  }

  public async searchUser({
    search,
    role,
    wholesaler,
    enabled,
  }: SearchUserContext): Promise<ResultWithPagination<User[]>> {
    if (!search) {
      return {
        result: [],
        pagination: {
          currentPage: 1,
          pageCount: 1,
          perPage: 10,
          totalCount: 0,
        },
      };
    }

    const params = {
      search,
      role,
      wholesaler,
      enabled,
    };
    const {
      result,
      _meta: { pagination },
    } = await httpClient.get<User[]>('user/list', { params });

    return {
      result,
      pagination,
    };
  }

  public async searchBuyer({ search, wholesaler, enabled }: SearchBuyerContext): Promise<ResultWithPagination<User[]>> {
    return this.searchUser({ search, role: UserRoles.Buyer.toString(), wholesaler, enabled });
  }
}

export const usersService = new UsersService();
