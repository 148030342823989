import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const RestoreIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30391 13.3899C5.61652 13.1182 6.09024 13.1513 6.36199 13.4639L8.45849 15.8757L10.8702 13.7792C11.1829 13.5074 11.6566 13.5405 11.9283 13.8531C12.2001 14.1657 12.1669 14.6395 11.8543 14.9112L8.31051 17.9918L5.22992 14.448C4.95817 14.1354 4.9913 13.6617 5.30391 13.3899Z"
      fill="currentColor"
    />
    <path
      d="M19.1797 16C19.1797 17.1095 18.2803 18.0088 17.1709 18.0088C16.0615 18.0088 15.1621 17.1095 15.1621 16C15.1621 14.8906 16.0615 13.9913 17.1709 13.9913C18.2803 13.9913 19.1797 14.8906 19.1797 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1707 7.97095C12.7363 7.97095 9.1416 11.5657 9.1416 16C9.1416 16.4142 8.80582 16.75 8.3916 16.75C7.97739 16.75 7.6416 16.4142 7.6416 16C7.6416 10.7373 11.9079 6.47095 17.1707 6.47095C22.4334 6.47095 26.6998 10.7373 26.6998 16C26.6998 21.2628 22.4334 25.5291 17.1707 25.5291C16.7565 25.5291 16.4207 25.1933 16.4207 24.7791C16.4207 24.3649 16.7565 24.0291 17.1707 24.0291C21.605 24.0291 25.1998 20.4344 25.1998 16C25.1998 11.5657 21.605 7.97095 17.1707 7.97095Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default RestoreIcon;
