import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const PaymentIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      ...sx,
      fontSize: '18px',
    }}
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.849976 2.8999C0.849976 1.65726 1.85733 0.649902 3.09998 0.649902H15.7C16.9426 0.649902 17.95 1.65726 17.95 2.8999V5.5999C17.95 6.01412 17.6142 6.3499 17.2 6.3499C16.8592 6.3499 16.5715 6.12265 16.4802 5.81144H2.34998V11.8999C2.34998 12.3141 2.68576 12.6499 3.09998 12.6499H8.15382C8.56804 12.6499 8.90382 12.9857 8.90382 13.3999C8.90382 13.8141 8.56804 14.1499 8.15382 14.1499H3.09998C1.85733 14.1499 0.849976 13.1425 0.849976 11.8999V2.8999ZM2.34998 4.31144H16.45V2.8999C16.45 2.48569 16.1142 2.1499 15.7 2.1499H3.09998C2.68576 2.1499 2.34998 2.48569 2.34998 2.8999V4.31144Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.84998 9.19971C3.84998 8.78549 4.18576 8.44971 4.59998 8.44971H6.39998C6.81419 8.44971 7.14998 8.78549 7.14998 9.19971C7.14998 9.61392 6.81419 9.94971 6.39998 9.94971H4.59998C4.18576 9.94971 3.84998 9.61392 3.84998 9.19971Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2 14.8998C16.0226 14.8998 17.5 13.4223 17.5 11.5998C17.5 9.77726 16.0226 8.2998 14.2 8.2998C12.3775 8.2998 10.9 9.77726 10.9 11.5998C10.9 13.4223 12.3775 14.8998 14.2 14.8998ZM14.2 16.3998C16.851 16.3998 19 14.2508 19 11.5998C19 8.94884 16.851 6.7998 14.2 6.7998C11.5491 6.7998 9.40002 8.94884 9.40002 11.5998C9.40002 14.2508 11.5491 16.3998 14.2 16.3998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2226 9.97748C16.5155 10.2704 16.5155 10.7452 16.2226 11.0381L13.9999 13.2608L12.7387 11.9996C12.4458 11.7067 12.4458 11.2318 12.7387 10.9389C13.0316 10.646 13.5065 10.646 13.7994 10.9389L13.9999 11.1395L15.1619 9.97748C15.4548 9.68459 15.9297 9.68459 16.2226 9.97748Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default PaymentIcon;
