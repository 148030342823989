import React from 'react';

const HelpCircle = (): JSX.Element => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00016 1.95834C4.66344 1.95834 1.9585 4.66329 1.9585 8.00001C1.9585 11.3367 4.66344 14.0417 8.00016 14.0417C11.3369 14.0417 14.0418 11.3367 14.0418 8.00001C14.0418 4.66329 11.3369 1.95834 8.00016 1.95834ZM0.708496 8.00001C0.708496 3.97293 3.97309 0.708344 8.00016 0.708344C12.0272 0.708344 15.2918 3.97293 15.2918 8.00001C15.2918 12.0271 12.0272 15.2917 8.00016 15.2917C3.97309 15.2917 0.708496 12.0271 0.708496 8.00001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.17935 5.30849C7.85931 5.2536 7.53016 5.31374 7.25021 5.47827C6.97026 5.6428 6.75757 5.9011 6.64981 6.20742C6.53527 6.53304 6.17845 6.70414 5.85283 6.5896C5.52721 6.47505 5.3561 6.11823 5.47064 5.79261C5.67636 5.20782 6.0824 4.71471 6.61685 4.4006C7.15131 4.0865 7.77968 3.97168 8.39067 4.07649C9.00167 4.18129 9.55586 4.49895 9.95509 4.9732C10.3543 5.44739 10.5728 6.04753 10.5719 6.66736C10.5716 7.66544 9.83121 8.3283 9.29358 8.68671C9.00569 8.87864 8.72224 9.01992 8.51323 9.11282C8.40784 9.15966 8.31914 9.19518 8.25533 9.21949C8.22337 9.23166 8.1975 9.24108 8.17874 9.24776L8.15598 9.25572L8.1488 9.25818L8.1463 9.25902L8.14533 9.25935C8.14514 9.25941 8.14454 9.25961 7.95265 8.68394L8.14533 9.25935C7.81786 9.3685 7.46312 9.19179 7.35397 8.86433C7.24496 8.53729 7.42133 8.18383 7.74798 8.07418C7.7484 8.07404 7.74883 8.0739 7.74925 8.07376L7.94677 8.6663C7.74925 8.07376 7.74941 8.0737 7.74925 8.07376L7.75997 8.06999C7.7705 8.06624 7.78761 8.06004 7.81034 8.05138C7.8559 8.03402 7.92345 8.00704 8.00556 7.97055C8.17155 7.89678 8.3881 7.78806 8.60021 7.64665C9.06246 7.33849 9.32189 7.00156 9.32189 6.66668C9.32238 6.34196 9.20793 6.02662 8.99881 5.7782C8.78969 5.52978 8.4994 5.36339 8.17935 5.30849Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 11.3333C7.25 10.9191 7.58579 10.5833 8 10.5833H8.00583C8.42005 10.5833 8.75583 10.9191 8.75583 11.3333C8.75583 11.7476 8.42005 12.0833 8.00583 12.0833H8C7.58579 12.0833 7.25 11.7476 7.25 11.3333Z"
      fill="currentColor"
    />
  </svg>
);

export default HelpCircle;
