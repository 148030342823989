import { HandleQueryErrorKeys } from '@app/api/hooks/constants';
import { HttpStatusCode } from '@app/api/http/constants/http-status-code';
import { ValidationError } from '@app/api/http/types';
import { useAuth } from '@app/contexts/auth';
import { useSnackbar } from '@app/contexts/snackbar';
import { credentialsService } from '@app/utils/services/credentialsService';
import { AlertColor } from '@mui/material';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

export interface HandleQueryErrorOptions {
  messages?: Partial<typeof HandleQueryErrorKeys>;
  redirectTo?: string;
  severity?: AlertColor;
}

export const useHandleQueryError = () => {
  const { t } = useTranslation();
  const { open } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const { setIsAuthorized } = useAuth();

  return (error: AxiosError, options?: HandleQueryErrorOptions) => {
    if (!error) {
      return;
    }

    const messageKeys = { ...HandleQueryErrorKeys, ...options?.messages };
    const severity = options?.severity || 'error';

    if (error.response) {
      let message = t(messageKeys.unknown);
      switch (error.response.status) {
        case HttpStatusCode.Unauthorized:
          credentialsService.flush();
          setIsAuthorized(false);
          history.push({ pathname: '/auth/login', state: { backLocation: location } });

          return;
        case HttpStatusCode.Forbidden:
          message = t(messageKeys.forbidden);

          if (options?.redirectTo) {
            history.push(options.redirectTo);
          }
          break;
        case HttpStatusCode.NotFound:
          message = t(messageKeys.notFound);
          break;
        case HttpStatusCode.UnprocessableEntity:
          {
            const { result } = error.response.data;
            message = Array.isArray(result)
              ? result.map(({ message }: ValidationError) => message).join(', ')
              : result.message;
          }
          break;
      }
      open({ severity, message });

      return;
    }

    if (error.request) {
      // client never received a response, or request never left
      open({ severity, message: t(messageKeys.connection) });

      return;
    }

    open({ severity, message: t(messageKeys.connection) });
  };
};
