import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const DownloadIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75655 13.6582C5.17438 13.6582 5.5131 13.9969 5.5131 14.4148V17.6341C5.5131 17.8604 5.60298 18.0774 5.76298 18.2374C5.92297 18.3974 6.13997 18.4872 6.36623 18.4872H17.634C17.8603 18.4872 18.0773 18.3974 18.2373 18.2374C18.3972 18.0774 18.4871 17.8604 18.4871 17.6341V14.4148C18.4871 13.9969 18.8258 13.6582 19.2437 13.6582C19.6615 13.6582 20.0002 13.9969 20.0002 14.4148V17.6341C20.0002 18.2617 19.7509 18.8635 19.3072 19.3073C18.8634 19.751 18.2616 20.0003 17.634 20.0003H6.36623C5.73867 20.0003 5.13681 19.751 4.69305 19.3073C4.2493 18.8635 4 18.2617 4 17.6341V14.4148C4 13.9969 4.33872 13.6582 4.75655 13.6582Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7563 4.75655C12.7563 4.33872 12.4175 4 11.9997 4C11.5819 4 11.2432 4.33872 11.2432 4.75655V12.5873L8.51124 9.85538C8.21579 9.55993 7.73677 9.55993 7.44131 9.85538C7.14586 10.1508 7.14586 10.6298 7.44131 10.9253L11.4437 14.9277C11.5819 15.0774 11.7799 15.1712 11.9997 15.1712C12.0539 15.1712 12.1068 15.1655 12.1578 15.1546C12.2962 15.1253 12.428 15.057 12.5354 14.9495L16.5596 10.9253C16.8551 10.6298 16.8551 10.1508 16.5596 9.85538C16.2642 9.55993 15.7852 9.55993 15.4897 9.85538L12.7563 12.5888V4.75655Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default DownloadIcon;
