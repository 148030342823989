import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import React from 'react';
import styles from './Tooltip.module.scss';

const Tooltip = ({ children, classes, ...props }: TooltipProps): JSX.Element => {
  return (
    <MuiTooltip
      classes={{
        tooltip: styles.Tooltip,
        ...classes,
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
