import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CommunicationIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      fontSize: rem(18),
      ...sx,
    }}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.0002 1.94995C5.1066 1.94995 1.95021 5.10634 1.95021 8.99995C1.95021 9.37105 2.04559 9.95371 2.15656 10.4898C2.20999 10.7479 2.26356 10.9793 2.30379 11.1461C2.32387 11.2293 2.34052 11.2961 2.35204 11.3416L2.36524 11.3932L2.36853 11.4059L2.36928 11.4088L2.40235 11.5346L2.02317 15.977L6.46555 15.5978L6.59078 15.6307L6.59422 15.6316L6.60691 15.6349L6.65857 15.6481C6.70409 15.6596 6.77086 15.6763 6.8541 15.6964C7.02089 15.7366 7.25223 15.7902 7.51036 15.8436C8.04644 15.9546 8.62911 16.05 9.0002 16.05C12.8938 16.05 16.0502 12.8936 16.0502 8.99995C16.0502 5.10634 12.8938 1.94995 9.0002 1.94995ZM6.33784 17.1142C6.38266 17.1254 6.43812 17.1391 6.50241 17.1546C6.68067 17.1976 6.9284 17.2549 7.20631 17.3125C7.74231 17.4234 8.45965 17.5499 9.0002 17.5499C13.7222 17.5499 17.5502 13.722 17.5502 8.99995C17.5502 4.27792 13.7222 0.449951 9.0002 0.449951C4.27817 0.449951 0.45021 4.27792 0.45021 8.99995C0.45021 9.5405 0.576746 10.2578 0.687696 10.7938C0.745223 11.0718 0.802608 11.3195 0.8456 11.4977C0.861105 11.562 0.874788 11.6175 0.885994 11.6623L0.452916 16.7362C0.434109 16.9565 0.513499 17.1739 0.669868 17.3303C0.826237 17.4866 1.04364 17.566 1.26398 17.5472L6.33784 17.1142Z"
      fill="currentColor"
    />
    <path
      d="M10.1998 9.00005C10.1998 9.66279 9.66255 10.2 8.9998 10.2C8.33706 10.2 7.7998 9.66279 7.7998 9.00005C7.7998 8.33731 8.33706 7.80005 8.9998 7.80005C9.66255 7.80005 10.1998 8.33731 10.1998 9.00005Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CommunicationIcon;
