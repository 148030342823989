import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { rem } from '@app/utils/sx/functions';

const InfoCircleIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      color: 'text.auxiliary',
      fontSize: rem(18),
      ...sx,
    }}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      fill="currentColor"
      fillOpacity="0.2"
    />
    <path d="M9 12V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 6H9.00667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default InfoCircleIcon;
