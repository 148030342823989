import { useAuth } from '@app/contexts/auth';
import { getErrorMessage } from '@app/utils/http/getErrorMessage';
import { authService } from '@app/utils/services/authService';
import { credentialsService } from '@app/utils/services/credentialsService';
import { currentUserService } from '@app/utils/services/currentUserService';
import { emailService } from '@app/utils/services/emailServices';
import { VerifyTokenActions } from '@app/utils/services/types';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';

export const useVerifyEmail = () => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const { setIsAuthorized } = useAuth();
  const history = useHistory();
  const { mutate: newEmail } = useMutation(emailService.newEmail, {
    onSuccess: () => {
      credentialsService.flush();
      currentUserService.flush();
      setIsAuthorized(false);
      history.push({
        pathname: '/auth/login',
        state: {
          snackbar: {
            severity: 'success',
            message: t('Email was changed you can use it'),
          },
        },
      });
    },
    onError: (error: AxiosError) => {
      goToProfile(error);
    },
  });

  const { mutate, isLoading } = useMutation(authService.verifyToken, {
    onSuccess: () => {
      newEmail(token);
    },
    onError: (error: AxiosError) => {
      goToProfile(error);
    },
  });

  const goToProfile = (error: AxiosError) => {
    history.push({
      pathname: '/profile',
      state: {
        snackbar: {
          severity: 'error',
          message: getErrorMessage(error),
        },
      },
    });
  };

  useEffect(() => {
    mutate({ token, action: VerifyTokenActions.ChangeEmail });
  }, [token]);

  return {
    isLoading,
  };
};
