import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowBackIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path d="M20 11H7.83L11.41 7.41L10 6L4 12L10 18L11.41 16.59L7.83 13H20V11Z" fill="currentColor" />
  </SvgIcon>
);

export default ArrowBackIcon;
