import { useMainLayoutContext } from '@app/contexts/mainLayout';
import Header from '@app/layouts/MainLayout/Header';
import { rem } from '@app/utils/sx/functions';
import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Content = ({ children }: Props): JSX.Element => {
  const { viewportHeight, mainPaddingTop } = useMainLayoutContext();

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: viewportHeight ? '100vh' : 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />

      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          pt: { xs: `${mainPaddingTop}px`, md: 1.9 },
          p: { xs: rem([15, 12]), md: rem(16) },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Content;
