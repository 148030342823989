import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SearchIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      color: 'text.auxiliary',
      ...sx,
    }}
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6538 6.75C8.49781 6.75 6.75 8.49781 6.75 10.6538C6.75 12.8099 8.49781 14.5577 10.6538 14.5577C12.8099 14.5577 14.5577 12.8099 14.5577 10.6538C14.5577 8.49781 12.8099 6.75 10.6538 6.75ZM5.25 10.6538C5.25 7.66938 7.66938 5.25 10.6538 5.25C13.6383 5.25 16.0577 7.66938 16.0577 10.6538C16.0577 11.8756 15.6522 13.0027 14.9685 13.9078L18.5303 17.4697L17.4697 18.5303L13.9078 14.9685C13.0027 15.6522 11.8756 16.0577 10.6538 16.0577C7.66938 16.0577 5.25 13.6383 5.25 10.6538Z" fill="currentColor"/>
  </SvgIcon>
);

export default SearchIcon;
