export enum QueryKeys {
  CurrentUser = 'currentUser',
  UsersList = 'usersList',
  BuyersList = 'buyersList',
  UsersListExport = 'UsersListExport',
  User = 'user',
  Warehouse = 'warehouse',
  WarehousesList = 'warehousesList',
  ItemsList = 'itemsList',
  ItemsListExport = 'itemsListExport',
  ItemBarcode = 'itemBarcode',
  Item = 'item',
  FilterItemIds = 'filterItemIds',
  ItemInventory = 'itemInventory',
  AdjustmentsList = 'adjustmentsList',
  AdjustmentsListTotal = 'adjustmentsListTotal',
  SearchSku = 'searchSku',
  ReceiptsSerialsList = 'ReceiptsSerialsList',
  ReceiptsSerialsListExport = 'ReceiptsSerialsListExport',
  TrackingNumbersList = 'TrackingNumbersList',
  PackageNumbersList = 'PackageNumbersList',
  TrackingNumbersListExport = 'TrackingNumbersListExport',
  TrackingNumbersTotalAmount = 'TrackingNumbersTotalAmount',
  ReceivingList = 'receivingList',
  ReceivingListExport = 'ReceivingListExport',
  ReceivingListTotal = 'ReceivingListTotal',
  SearchBuyer = 'SearchBuyer',
  SearchUser = 'SearchUser',
  ReceiptItem = 'receiptItem',
  ReceiptExport = 'receiptExport',
  ReceiptConnectedItems = 'receiptConnectedItems',
  Balance = 'balance',
  InvoicesList = 'InvoicesList',
  InvoicesListExport = 'invoicesListExport',
  InvoiceExportSlip = 'InvoiceExportSlip',
  InvoicesExportPdf = 'invoicesExportPdf',
  InvoicesListTotal = 'InvoicesListTotal',
  Invoice = 'Invoice',
  InvoiceConnectedItems = 'invoiceConnectedItems',
  PaymentDetails = 'PaymentDetails',
  PaymentsList = 'PaymentsList',
  PaymentsTotal = 'PaymentsTotal',
  PaymentListExportCsv = 'PaymentListExportCsv',
  PaymentListExportPdf = 'PaymentListExportPdf',
  PaymentExport = 'paymentExportPdf',
  CreditCharge = 'creditCharge',
  CreditChargeList = 'creditChargeList',
  CreditChargeListExport = 'creditChargeListExport',
  creditChargeTotal = 'creditChargeTotal',
  ItemsInventory = 'ItemsInventory',
  BackupList = 'backupList',
  SearchResults = 'SearchResults',
  WarehouseInventory = 'WarehouseInventory',
  Config = 'Config',
  Ticket = 'Ticket',
  TicketsList = 'TicketsList',
  TicketTypes = 'TicketTypes',
  TicketFiles = 'TicketFiles',
  TicketHistoryList = 'TicketHistoryList',
  TicketListExportCsv = 'TicketListExportCsv',
  SavedRepliesList = 'SavedRepliesList',
  ReturnTasksList = 'ReturnTasksList',
  ReturnTasksDetails = 'ReturnTasksDetails',
  ReturnTasksListExportCsv = 'ReturnTasksListExportCsv',
  BoxTypeList = 'BoxTypeList',
}
