import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const HardIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="6" y="7" width="12" height="2" rx="1" fill="#F13572" />
    <rect x="6" y="11" width="12" height="2" rx="1" fill="#F13572" />
    <rect x="6" y="15" width="12" height="2" rx="1" fill="#F13572" />
  </SvgIcon>
);
export default HardIcon;
