import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const FilterIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 14 14"
    fill="none"
    sx={{
      ...sx,
      fontSize: '14px',
    }}
    {...props}
  >
    <path
      d="M0 2.5C0 2.08579 0.335786 1.75 0.75 1.75H13.25C13.6642 1.75 14 2.08579 14 2.5C14 2.91421 13.6642 3.25 13.25 3.25H0.75C0.335786 3.25 0 2.91421 0 2.5Z"
      fill="currentColor"
    />
    <path
      d="M2.5 7C2.5 6.58579 2.83579 6.25 3.25 6.25H10.75C11.1642 6.25 11.5 6.58579 11.5 7C11.5 7.41421 11.1642 7.75 10.75 7.75H3.25C2.83579 7.75 2.5 7.41421 2.5 7Z"
      fill="currentColor"
    />
    <path
      d="M5 11.5C5 11.0858 5.33579 10.75 5.75 10.75H8.25C8.66421 10.75 9 11.0858 9 11.5C9 11.9142 8.66421 12.25 8.25 12.25H5.75C5.33579 12.25 5 11.9142 5 11.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default FilterIcon;
