import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ToggleTotalsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_5541_139739)">
      <path fillRule="evenodd" clipRule="evenodd" d="M19 5C19.5523 5 20 5.44772 20 6L20 10C20 10.5523 19.5523 11 19 11L5 11C4.44771 11 4 10.5523 4 10L4 6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5L19 5ZM18 7L6 7L6 9L18 9L18 7Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.7071 13.2929C19.8946 13.4804 20 13.7348 20 14L20 18C20 18.5523 19.5523 19 19 19L5 19C4.73478 19 4.48043 18.8946 4.29289 18.7071C4.10536 18.5196 4 18.2652 4 18L4 14C4 13.4477 4.44771 13 5 13L19 13C19.2652 13 19.5196 13.1054 19.7071 13.2929ZM18 15L6 15L6 17L18 17L18 15Z" fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0_5541_139739">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ToggleTotalsIcon;
