import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ReceivingIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 18 18"
    sx={{
      ...sx,
      fill: 'none',
      fontSize: '18px',
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67006 0.225139C8.87819 0.123196 9.12176 0.123196 9.32989 0.225139L16.5299 3.7518C16.9019 3.93401 17.0557 4.38327 16.8735 4.75526C16.6913 5.12724 16.2421 5.28109 15.8701 5.09889L8.99997 1.73382L2.12988 5.09889C1.7579 5.28109 1.30864 5.12724 1.12643 4.75525C0.944227 4.38327 1.09808 3.93401 1.47006 3.7518L8.67006 0.225139Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.80001 3.67537C2.21423 3.67537 2.55001 4.01116 2.55001 4.42538L2.54993 13.0631L3.01701 13.2919C3.38899 13.4741 3.54284 13.9234 3.36064 14.2954C3.17843 14.6674 2.72917 14.8212 2.35718 14.639L1.47002 14.2045C1.21291 14.0785 1.04992 13.8172 1.04993 13.5309L1.05001 4.42536C1.05001 4.01115 1.3858 3.67537 1.80001 3.67537ZM8.99998 7.65C9.41419 7.65001 9.74997 7.98579 9.74997 8.40001L9.74993 16.8C9.74993 17.0886 9.5843 17.3517 9.324 17.4764C9.06371 17.6011 8.75494 17.5654 8.53 17.3845L5.53 14.9726C5.20717 14.7131 5.15587 14.241 5.41541 13.9182C5.67494 13.5954 6.14704 13.5441 6.46986 13.8036L8.24994 15.2347L8.24997 8.4C8.24997 7.98579 8.58576 7.65 8.99998 7.65Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1999 3.67537C15.7857 3.67537 15.4499 4.01116 15.4499 4.42538L15.4499 13.0631L14.97 13.2982C14.5981 13.4804 14.4442 13.9297 14.6264 14.3017C14.8086 14.6736 15.2579 14.8275 15.6299 14.6453L16.5299 14.2045C16.787 14.0785 16.95 13.8172 16.95 13.5309L16.9499 4.42536C16.9499 4.01115 16.6141 3.67537 16.1999 3.67537ZM8.99995 7.65C8.58574 7.65 8.24995 7.98579 8.24995 8.4V16.8C8.24995 17.0886 8.41558 17.3517 8.67588 17.4764C8.93617 17.6011 9.24494 17.5654 9.46988 17.3845L12.4699 14.9726C12.7927 14.7131 12.844 14.241 12.5845 13.9182C12.3249 13.5954 11.8528 13.5441 11.53 13.8036L9.74995 15.2347V8.4C9.74995 7.98579 9.41417 7.65 8.99995 7.65Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ReceivingIcon;
