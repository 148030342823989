import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ExportIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45 12C5.92533 12 5.5 12.4253 5.5 12.95V17.55C5.5 18.0747 5.92533 18.5 6.45 18.5H17.55C18.0747 18.5 18.5 18.0747 18.5 17.55V12.95C18.5 12.4253 18.0747 12 17.55 12H15.8C15.3858 12 15.05 11.6642 15.05 11.25C15.05 10.8358 15.3858 10.5 15.8 10.5H17.55C18.9031 10.5 20 11.5969 20 12.95V17.55C20 18.9031 18.9031 20 17.55 20H6.45C5.0969 20 4 18.9031 4 17.55V12.95C4 11.5969 5.0969 10.5 6.45 10.5H8.2C8.61421 10.5 8.95 10.8358 8.95 11.25C8.95 11.6642 8.61421 12 8.2 12H6.45Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7563 14.4143C12.7563 14.8322 12.4175 15.1709 11.9997 15.1709C11.5819 15.1709 11.2432 14.8322 11.2432 14.4143V6.5836L9.51124 8.31552C9.21579 8.61097 8.73677 8.61097 8.44131 8.31552C8.14586 8.02007 8.14586 7.54105 8.44131 7.2456L11.4437 4.24324C11.5819 4.0935 11.7799 3.99971 11.9997 3.99971C12.054 3.99971 12.1069 4.00542 12.1579 4.01627C12.2962 4.04556 12.428 4.11394 12.5354 4.2214L15.5596 7.2456C15.8551 7.54105 15.8551 8.02007 15.5596 8.31552C15.2642 8.61097 14.7852 8.61097 14.4897 8.31552L12.7563 6.58207V14.4143Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ExportIcon;
