import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const MediumIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect x="6" y="8" width="12" height="2" rx="1" fill="#FF9635" />
    <rect x="6" y="14" width="12" height="2" rx="1" fill="#FF9635" />
  </SvgIcon>
);
export default MediumIcon;
