import { useConfig } from '@app/contexts/config';
import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './AuthLayout.module.scss';

interface Props {
  children: ReactNode;
}

const AuthLayout = ({ children }: Props): JSX.Element => {
  const { logoLight } = useConfig();

  return (
    <section className="auth-layout">
      <Grid container spacing={0}>
        <Grid item xs={12} lg={6}>
          <div className={styles.Container}>
            <div className={styles.Content}>
              <img className={styles.Logo} src={logoLight} alt="Pointsmaker" />
              {children}
            </div>
          </div>
        </Grid>
        <Grid item sx={{ display: { xs: 'none', lg: 'block' } }} lg={6}>
          <div className={styles.Banner} style={{ backgroundImage: "url('/images/auth-layout/bg.png')" }} />
        </Grid>
      </Grid>
    </section>
  );
};

export default AuthLayout;
