import { production } from '@app/constants/environments';
import React, { ErrorInfo } from 'react';
import { Trans } from 'react-i18next';

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /**
     * Here you can log the error
     */
    if (!production) {
      console.error('ERROR:', error);
      console.error('ERROR INFO:', errorInfo);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <h3>
          <Trans>errors.unknown</Trans>
        </h3>
      );
    }

    return this.props.children;
  }
}
