import {
  iconBox,
  indicator,
  profileButton,
  profileName,
} from '@app/components/DefaultPageHeader/ProfileMenu/ProfileMenu.sx';
import UserIcon from '@app/components/Icons/UserIcon';
import Tooltip from '@app/components/Tooltip';
import WarningIndicator from '@app/components/WarningIndicator';
import { verifyPhone } from '@app/constants/profile';
import { useCurrentUser } from '@app/contexts/currentUser';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

interface Props {
  open: boolean;
  openMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

const ProfileButton = ({ open, openMenu }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { firstName, lastName, userId, phoneVerified } = useCurrentUser() || {};
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Button
      sx={profileButton}
      id="profile-button"
      size="small"
      aria-controls="profile-menu"
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      variant="outlined"
      color="inherit"
      style={open ? { textTransform: 'none', color: '#6658ff', borderColor: '#6658ff' } : { textTransform: 'none' }}
      disableElevation
      onClick={openMenu}
      endIcon={isMobile ? null : <ArrowDownIcon />}
      startIcon={
        !phoneVerified && userId ? (
          <Tooltip componentsProps={{ tooltip: { sx: { mt: '7px !important' } } }} title={t(verifyPhone)}>
            <span>
              <UserIcon sx={iconBox} />
            </span>
          </Tooltip>
        ) : (
          <UserIcon sx={iconBox} />
        )
      }
    >
      {!phoneVerified && userId && <WarningIndicator sx={indicator} />}

      {!isMobile && (
        <Tooltip title={`${firstName} ${lastName}`}>
          <Box sx={profileName}>
            {firstName} {lastName}
          </Box>
        </Tooltip>
      )}
    </Button>
  );
};

export default ProfileButton;
