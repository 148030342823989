import { rem } from '@app/utils/sx/functions';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const asideHeader: SxProps<Theme> = {
  minHeight: rem(60),
  justifyContent: 'space-between',
};

export const search: SxProps<Theme> = {
  m: rem([17, 16, 13]),
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgb(232, 236, 241, 0.25) !important',
  },
  '.MuiInputBase-input::placeholder': {
    color: '#fff',
  },
  '.MuiSvgIcon-root': {
    color: '#fff',
  },
};

export const backButton: SxProps<Theme> = {
  backgroundColor: '#1d215b',
  borderRadius: rem(6),
};

export const collapseMenu: SxProps<Theme> = {
  fontSize: rem(12),
  backgroundColor: '#1d215b',
  borderRadius: rem(6),
  '&:hover': {
    backgroundColor: '#2d3267',
  },
};
