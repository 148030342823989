import { SIDEBAR_WIDTH } from '@app/constants/main-layout';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { closedMixin, openedMixin } from '@app/utils/material-ui/css-mixins';
import { Drawer as MuiDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import MainMenu from './MainMenu';
import SidebarHeader from './SidebarHeader';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: SIDEBAR_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, SIDEBAR_WIDTH),
    '& .MuiDrawer-paper': openedMixin(theme, SIDEBAR_WIDTH),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DesktopDrawer = (): JSX.Element => {
  const { expanded } = useMainLayoutContext();

  return (
    <Drawer
      variant="permanent"
      sx={{
        overflowX: 'visible',
        display: { xs: 'none', md: 'block' },
        '& .MuiPaper-root': { overflowX: 'visible' },
      }}
      open={expanded}
      PaperProps={{
        sx: {
          overflowX: 'visible',
          overflowY: 'visible',
          backgroundColor: '#030848',
          color: '#fff',
          '& .MuiListItemIcon-root': {
            color: 'inherit',
          },
          '& .MuiButtonBase-root': {
            color: '#fff',
            textTransform: 'none',
          },
        },
      }}
    >
      <SidebarHeader />
      <MainMenu />
    </Drawer>
  );
};

export default DesktopDrawer;
