import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const EmailIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 5.54999C4.22421 5.54999 3.75 6.0242 3.75 6.59999V17.4C3.75 17.9758 4.22421 18.45 4.8 18.45H19.2C19.7758 18.45 20.25 17.9758 20.25 17.4V6.59999C20.25 6.0242 19.7758 5.54999 19.2 5.54999H4.8ZM2.25 6.59999C2.25 5.19577 3.39579 4.04999 4.8 4.04999H19.2C20.6042 4.04999 21.75 5.19577 21.75 6.59999V17.4C21.75 18.8042 20.6042 19.95 19.2 19.95H4.8C3.39579 19.95 2.25 18.8042 2.25 17.4V6.59999Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.38558 6.16988C2.62311 5.83055 3.09076 5.74802 3.4301 5.98556L12 11.9845L20.5699 5.98556C20.9092 5.74802 21.3769 5.83055 21.6144 6.16988C21.852 6.50922 21.7694 6.97687 21.4301 7.2144L12.4301 13.5144C12.1719 13.6952 11.8281 13.6952 11.5699 13.5144L2.5699 7.2144C2.23057 6.97687 2.14804 6.50922 2.38558 6.16988Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default EmailIcon;
