import Search from '@app/components/DefaultPageHeader/Search';
import ArrowBackIcon from '@app/components/Icons/ArrowBackIcon';
import CollapseMenuIcon from '@app/components/Icons/CollapseMenuIcon';
import { useConfig } from '@app/contexts/config';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { asideHeader, backButton, collapseMenu, search } from '@app/layouts/MainLayout/Sidebar/SidebarHeader.sx';
import { IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.scss';

const SidebarHeader = (): JSX.Element => {
  const { mobileOpen, handleDrawerToggle, expanded, handleCollapse } = useMainLayoutContext();
  const { logoDark, logoShort } = useConfig();

  return (
    <>
      <Toolbar sx={asideHeader}>
        <Link to="/" style={{ marginLeft: '-8px', marginTop: expanded ? 0 : '5px' }}>
          <img
            src={expanded ? logoDark : logoShort}
            alt="Company logo"
            className={expanded ? styles.Logo : styles.LogoShort}
          />
        </Link>

        {mobileOpen && (
          <IconButton sx={backButton} onClick={handleDrawerToggle}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {!mobileOpen && expanded && (
          <IconButton sx={collapseMenu} color="inherit" onClick={handleCollapse} edge="end">
            <CollapseMenuIcon />
          </IconButton>
        )}
      </Toolbar>
      {mobileOpen && <Search sx={search} />}
    </>
  );
};

export default SidebarHeader;
