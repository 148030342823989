import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TableArrowDownIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      fontSize: rem(12),
      ...sx,
    }}
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 4.29289C2.68342 3.90237 3.31658 3.90237 3.70711 4.29289L6 6.58579L8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711L6 9.41421L2.29289 5.70711C1.90237 5.31658 1.90237 4.68342 2.29289 4.29289Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TableArrowDownIcon;
