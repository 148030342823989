import ConfirmDialog from '@app/components/ConfirmDialog';
import Checkbox from '@app/forms/components/Checkbox';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import styles from './LogoutDialog.module.scss';

interface Props {
  open: boolean;
  onClose: (value?: boolean) => void;
  forgetDevice: boolean;
  setForgetDevice: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
}

const LogoutDialog = ({ open, onClose, forgetDevice, setForgetDevice, isLoading, ...rest }: Props): JSX.Element => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForgetDevice(event.target.checked);
  };

  return (
    <ConfirmDialog
      classes={{ paper: styles.LogoutDialog }}
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      title="Sign Out"
      content="Do you really want to Sign Out from your account? You will be returned to the Sign In screen."
      okText="Yes, Sign Out"
      okVariant="primary"
    >
      <div className={styles.DialogCheckbox}>
        <Checkbox
          label="Remove access for this device and delete verification data"
          checked={forgetDevice}
          onChange={handleChange}
        />
      </div>
    </ConfirmDialog>
  );
};

export default LogoutDialog;
