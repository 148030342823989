import { useConfigQuery } from '@app/api/hooks/useConfigQuery';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface Config {
  logoDark: string; // link to 147 x 32 SVG or PNG picture for dark background
  logoShort: string; // link to 32 x 32 SVG or PNG picture for dark background
  logoLight: string; // link to 215 x 47 SVG or PNG picture for light background
  supportEmail: string;
}

interface Props {
  children: ReactNode;
}

const defaultValues: Config = {
  logoDark: '/images/main-layout/logo.svg',
  logoShort: '/images/main-layout/logo-short.svg',
  logoLight: '/images/auth-layout/logo.svg',
  supportEmail: 'info@pointsmaker.com',
};
const ConfigContext = createContext<Config>(null);

export const useConfig = () => useContext<Config>(ConfigContext);

const ConfigProvider = ({ children }: Props): JSX.Element => {
  const [value, setValue] = useState<Config>(defaultValues);
  const { data } = useConfigQuery();

  useEffect(() => {
    if (data) {
      setValue((currentValue) => ({
        logoDark: data.parameters.logoDark || defaultValues.logoDark,
        logoShort: data.parameters.logoShort || defaultValues.logoShort,
        logoLight: data.parameters.logoLight || defaultValues.logoLight,
        supportEmail: data.parameters.supportEmail || defaultValues.supportEmail,
      }));
    }
  }, [data]);

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export default ConfigProvider;
