import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const NotebookIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path d="M2 17.2H22C22 18.1941 21.1941 19 20.2 19H3.8C2.80589 19 2 18.1941 2 17.2Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 6.8H6C5.88954 6.8 5.8 6.88954 5.8 7V16C5.8 16.1105 5.88954 16.2 6 16.2H18C18.1105 16.2 18.2 16.1105 18.2 16V7C18.2 6.88954 18.1105 6.8 18 6.8ZM6 5C4.89543 5 4 5.89543 4 7V16C4 17.1046 4.89543 18 6 18H18C19.1046 18 20 17.1046 20 16V7C20 5.89543 19.1046 5 18 5H6Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default NotebookIcon;
