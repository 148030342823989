import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

interface Props extends SvgIconProps {
  small?: boolean;
}

const ImagePlaceholderIcon = ({ small, sx, ...svgProps }: Props): JSX.Element => (
  <SvgIcon
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      fill: 'none',
      fontSize: small ? rem(24) : rem(40),
      ...sx,
    }}
    {...svgProps}
  >
    <rect width="40" height="40" rx="4" fill="#EEF0F6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 14C13.8954 14 13 14.8954 13 16V24C13 25.1046 13.8954 26 15 26H25C26.1046 26 27 25.1046 27 24V16C27 14.8954 26.1046 14 25 14H15ZM15.8092 24H20.8092H21.1912H24.1912C24.5629 24 24.8046 23.6089 24.6384 23.2764L22.9474 19.8944C22.7632 19.5259 22.2373 19.5259 22.053 19.8944L21.0002 22L18.9474 17.8944C18.7632 17.5259 18.2373 17.5259 18.053 17.8944L15.362 23.2764C15.1958 23.6089 15.4375 24 15.8092 24Z"
      fill="#9CABBD"
    />
  </SvgIcon>
);

export default ImagePlaceholderIcon;
