import Button from '@app/components/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './NoMatch.module.scss';

interface Props {
  auth?: boolean;
}

const NoMatch = ({ auth }: Props): JSX.Element => {
  const className = auth ? styles.Auth : '';

  return (
    <div className={className}>
      <h1 className="HeadingH1">404 error</h1>
      <h2 className="HeadingH2">The requested URL was not found on the server.</h2>

      {auth && (
        <Link className={styles.BackLink} to="/auth/login">
          <Button variant="text">Go to Sign in</Button>
        </Link>
      )}
    </div>
  );
};

export default NoMatch;
