import Button from '@app/components/Button';
import {
  button,
  buttonGroup,
  contentText,
  dialog,
  dialogContent,
  dialogTitle,
} from '@app/components/ConfirmDialog/ConfirmDialog.sx';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import React, { MouseEvent, ReactNode } from 'react';

export interface Props extends DialogProps {
  open: boolean;
  onClose: (value?: boolean) => void;
  title: string;
  isLoading?: boolean;
  content?: ReactNode;
  cancelText?: string;
  okText?: string;
  okVariant?: 'primary' | 'error';
  okDisabled?: boolean;
  children?: ReactNode;
  width?: number;
}

const ConfirmDialog = ({
  open,
  onClose,
  isLoading = false,
  title,
  content,
  cancelText = 'No, cancel',
  okText = 'Yes, delete',
  okVariant = 'error',
  okDisabled = false,
  children,
  width,
  ...rest
}: Props): JSX.Element => {
  const handleCancel = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClose();
  };

  const handleOk = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClose(true);
  };

  return (
    <Dialog sx={dialog(width)} open={open} {...rest}>
      <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
      <DialogContent sx={dialogContent}>
        <Box sx={contentText}>{content}</Box>
        {children}
      </DialogContent>
      <DialogActions sx={buttonGroup}>
        <Button sx={button} variant="outlined" color="inherit" onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button sx={button} loading={isLoading} color={okVariant} onClick={handleOk} disabled={okDisabled}>
          {!isLoading && okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
