import Button from '@app/components/Button';
import Bell from '@app/components/Icons/Bell';
import CollapseMenuIcon from '@app/components/Icons/CollapseMenuIcon';
import HelpCircle from '@app/components/Icons/HelpCircle';
import Settings from '@app/components/Icons/Settings';
import { useAuth } from '@app/contexts/auth';
import { useConfig } from '@app/contexts/config';
import { useMainLayoutContext } from '@app/contexts/mainLayout';
import { UserRoles } from '@app/pages/users/types';
import { useMenuToggle } from '@app/utils/hooks/useMenuToggle';
import { rem } from '@app/utils/sx/functions';
import { Badge, Box, IconButton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './DefaultPageHeader.module.scss';
import InfoMenu from './InfoMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import Search from './Search';

const DefaultPageHeader = (): JSX.Element => {
  const { handleDrawerToggle, expanded, handleExpand, header } = useMainLayoutContext();
  const { role } = useAuth();
  const isReceiver = role === UserRoles.Receiver;
  const { openMenu, closeMenu, anchorEl } = useMenuToggle();
  const { logoLight } = useConfig();

  if (isReceiver) {
    return (
      <>
        <Box
          sx={{
            width: rem(147),
            a: {
              display: 'flex',
            },
            img: {
              width: '100%',
            },
          }}
        >
          <Link to="/">
            <img src={logoLight} alt="Company logo" />
          </Link>
        </Box>

        <Box
          sx={{ flexGrow: 1, m: 0, color: '#212B36', fontSize: { xs: '18px', sm: '22px' } }}
          className={classNames('HeadingH2', styles.HeaderTitle)}
        >
          {header}
        </Box>

        <ProfileMenu />
      </>
    );
  }

  return (
    <>
      <IconButton
        className={styles.CollapseMenu}
        color="inherit"
        onClick={!expanded ? handleExpand : handleDrawerToggle}
        edge="end"
        sx={{
          display: { xs: 'flex', md: 'none' },
          backgroundColor: '#1D215B',
          color: '#fff',
          borderRadius: '6px',
          margin: 0,
          marginRight: 1.5,
        }}
      >
        <CollapseMenuIcon />
      </IconButton>

      <Box
        sx={{ flexGrow: 1, m: 0, color: '#212B36', fontSize: { xs: '18px', sm: '22px' } }}
        className={classNames('HeadingH2', styles.HeaderTitle)}
      >
        {header}
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Search />
      </Box>

      <Button
        className={classNames(styles.ButtonIcon, styles.BellIcon)}
        variant="outlined"
        color="inherit"
        size="small"
        sx={{ display: 'none' }} // For second release
      >
        <Badge badgeContent={4} color="primary">
          <Bell />
        </Badge>
      </Button>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Button
          sx={{
            width: '36px',
            height: '36px',
            mr: '12px',
          }}
          variant="outlined"
          color="inherit"
          size="small"
          disableElevation
          onClick={openMenu}
        >
          <HelpCircle />
        </Button>
        <InfoMenu anchorEl={anchorEl} closeMenu={closeMenu} />
      </Box>

      <Button
        className={classNames(styles.ButtonIcon, styles.SettingsIcon)}
        variant="outlined"
        color="inherit"
        size="small"
        // sx={{ display: { xs: 'none', md: 'flex' } }}
        sx={{ display: 'none' }} // For second release
      >
        <Settings />
      </Button>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <ProfileMenu />
      </Box>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <MobileMenu />
      </Box>
    </>
  );
};

export default DefaultPageHeader;
