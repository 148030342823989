import AuthLayout from '@app/layouts/AuthLayout';
import styles from '@app/pages/auth/EmailVerification/EmailVerification.module.scss';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useVerifyEmail } from './useVerifyEmail';

const VerifyEmail = (): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading } = useVerifyEmail();

  return (
    <AuthLayout>
      <Helmet>
        <title>Change email</title>
      </Helmet>

      {isLoading && (
        <div className={styles.LoaderInfo}>
          <CircularProgress color="primary" size={20} />
          {t('The system is verifying your email. Please wait.')}
        </div>
      )}
    </AuthLayout>
  );
};

export default VerifyEmail;
