import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CloseIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      d="M7.88349 6.5335C7.5107 6.16071 6.90629 6.16071 6.5335 6.5335C6.16071 6.90629 6.16071 7.5107 6.5335 7.88349L10.6499 11.9999L6.5335 16.1164C6.16071 16.4891 6.16071 17.0936 6.5335 17.4663C6.90629 17.8391 7.5107 17.8391 7.88349 17.4663L11.9999 13.3499L16.1164 17.4663C16.4891 17.8391 17.0936 17.8391 17.4663 17.4663C17.8391 17.0936 17.8391 16.4891 17.4663 16.1164L13.3499 11.9999L17.4663 7.88349C17.8391 7.5107 17.8391 6.90629 17.4663 6.5335C17.0936 6.16071 16.4891 6.16071 16.1164 6.5335L11.9999 10.6499L7.88349 6.5335Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CloseIcon;
