import React, { SVGAttributes } from 'react';

const SimpleArrowUp = (props: SVGAttributes<any>): JSX.Element => (
  <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70711 6.70711C9.31658 7.09763 8.68342 7.09763 8.29289 6.70711L5 3.41421L1.70711 6.70711C1.31658 7.09763 0.683417 7.09763 0.292893 6.70711C-0.0976315 6.31658 -0.0976316 5.68342 0.292893 5.29289L5 0.585787L9.70711 5.29289C10.0976 5.68342 10.0976 6.31658 9.70711 6.70711Z"
      fill="currentColor"
    />
  </svg>
);

export default SimpleArrowUp;
