import { Box, InputLabel } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { createContext, HTMLProps, ReactNode, useContext } from 'react';

const ErrorContext = createContext(false);

export const useHasError = () => useContext(ErrorContext);

interface Props extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
  label?: string;
  required?: boolean;
  hasError?: boolean;
  sx?: SxProps<Theme>;
}
const FormGroup = ({ children, label, required = false, hasError = false, sx, ...divProps }: Props): JSX.Element => {
  return (
    <Box sx={sx}>
      <div {...divProps}>
        {label && (
          <InputLabel required={required} error={hasError}>
            {label}
          </InputLabel>
        )}
        <ErrorContext.Provider value={hasError}>{children}</ErrorContext.Provider>
      </div>
    </Box>
  );
};

export default FormGroup;
