import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TrashIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      color: 'secondary.negative',
      ...sx,
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 7.5C4.5 7.08579 4.83579 6.75 5.25 6.75H18.75C19.1642 6.75 19.5 7.08579 19.5 7.5C19.5 7.91421 19.1642 8.25 18.75 8.25H5.25C4.83579 8.25 4.5 7.91421 4.5 7.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 5.25C10.3011 5.25 10.1103 5.32902 9.96967 5.46967C9.82902 5.61032 9.75 5.80109 9.75 6V6.75H14.25V6C14.25 5.80109 14.171 5.61032 14.0303 5.46967C13.8897 5.32902 13.6989 5.25 13.5 5.25H10.5ZM15.75 6.75V6C15.75 5.40326 15.5129 4.83097 15.091 4.40901C14.669 3.98705 14.0967 3.75 13.5 3.75H10.5C9.90326 3.75 9.33097 3.98705 8.90901 4.40901C8.48705 4.83097 8.25 5.40326 8.25 6V6.75H6.75C6.33579 6.75 6 7.08579 6 7.5V18C6 18.5967 6.23705 19.169 6.65901 19.591C7.08097 20.0129 7.65326 20.25 8.25 20.25H15.75C16.3467 20.25 16.919 20.0129 17.341 19.591C17.7629 19.169 18 18.5967 18 18V7.5C18 7.08579 17.6642 6.75 17.25 6.75H15.75ZM7.5 8.25V18C7.5 18.1989 7.57902 18.3897 7.71967 18.5303C7.86032 18.671 8.05109 18.75 8.25 18.75H15.75C15.9489 18.75 16.1397 18.671 16.2803 18.5303C16.421 18.3897 16.5 18.1989 16.5 18V8.25H7.5Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default TrashIcon;
