import { UserRoles } from '@app/pages/users/types';
import { credentialsService } from '@app/utils/services/credentialsService';
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface AuthContextProps {
  isAuthorized: boolean;
  setIsAuthorized: (isAuthorized: boolean) => void;
  role: UserRoles | null;
}

const AuthContext = createContext<AuthContextProps>({
  isAuthorized: false,
  setIsAuthorized: () => {},
  role: credentialsService.role,
});

export const useAuth = () => useContext<AuthContextProps>(AuthContext);

interface Props {
  children: ReactNode;
}

const AuthProvider = ({ children }: Props): JSX.Element => {
  const [isAuthorized, setIsAuthorized] = useState(Boolean(credentialsService.credentials));

  const value: AuthContextProps = {
    isAuthorized,
    setIsAuthorized,
    role: credentialsService.role,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
