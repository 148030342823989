import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const PlusIcon = ({ ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="currentColor" />
  </SvgIcon>
);

export default PlusIcon;
