import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
import { rem } from '@app/utils/sx/functions';

const CheckedIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 25 25"
    {...props}
    sx={{
      fontSize: rem(25),
      ...sx
    }}
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H21C23.2091 0 25 1.79086 25 4V21C25 23.2091 23.2091 25 21 25H4C1.79086 25 0 23.2091 0 21V4Z"
      fill="#01AB55"
      fillOpacity="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7157 8.65635C18.0948 9.03539 18.0948 9.64993 17.7157 10.029L11.2059 16.5388L7.28428 12.6172C6.90524 12.2382 6.90524 11.6236 7.28428 11.2446C7.66332 10.8655 8.27786 10.8655 8.6569 11.2446L11.2059 13.7936L16.3431 8.65635C16.7221 8.27731 17.3367 8.27731 17.7157 8.65635Z"
      fill="#01AB55"
    />
  </SvgIcon>
);

export default CheckedIcon;
