import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowRightIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    viewBox="0 0 12 12"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M3.29289 10.7071C2.90237 10.3166 2.90237 9.68342 3.29289 9.29289L6.58579 6L3.29289 2.70711C2.90237 2.31658 2.90237 1.68342 3.29289 1.29289C3.68342 0.902369 4.31658 0.902368 4.70711 1.29289L9.41421 6L4.70711 10.7071C4.31658 11.0976 3.68342 11.0976 3.29289 10.7071Z" fill="currentColor"/>
  </SvgIcon>
);

export default ArrowRightIcon;
