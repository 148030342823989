import React from 'react';

const RadioChecked = (): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.5" stroke="#6658FF" />
    <circle cx="12" cy="12" r="7" fill="#6658FF" />
  </svg>
);

export default RadioChecked;
