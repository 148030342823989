import { useAuth } from '@app/contexts/auth';
import FormDirtyProvider from '@app/contexts/formDirty';
import MainLayoutProvider from '@app/contexts/mainLayout';
import { UserRoles } from '@app/pages/users/types';
import { Box, CssBaseline } from '@mui/material';
import React, { ReactNode } from 'react';
import Content from './Content';
import Sidebar from './Sidebar';

interface Props {
  children: ReactNode;
}

const MainLayout = ({ children }: Props): JSX.Element => {
  const { role } = useAuth();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <MainLayoutProvider>
        <FormDirtyProvider>
          {role !== UserRoles.Receiver && <Sidebar />}

          <Content>{children}</Content>
        </FormDirtyProvider>
      </MainLayoutProvider>
    </Box>
  );
};

export default MainLayout;
