import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const CheckboxDisabledEmptyIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H21C23.2091 0 25 1.79086 25 4V21C25 23.2091 23.2091 25 21 25H4C1.79086 25 0 23.2091 0 21V4Z"
      fill="#9CABBD"
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7157 8.65586C18.0948 9.0349 18.0948 9.64944 17.7157 10.0285L11.2059 16.5383L7.28428 12.6167C6.90524 12.2377 6.90524 11.6231 7.28428 11.2441C7.66332 10.8651 8.27786 10.8651 8.6569 11.2441L11.2059 13.7931L16.3431 8.65586C16.7221 8.27682 17.3367 8.27682 17.7157 8.65586Z"
      fill="white"
    />
  </SvgIcon>
);

export default CheckboxDisabledEmptyIcon;
