import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SendIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.43251 4.36704C4.69229 4.13413 5.06801 4.08362 5.38008 4.23966L19.3801 11.2398C19.668 11.3838 19.8499 11.6781 19.8499 12.0001C19.8499 12.322 19.668 12.6163 19.3801 12.7603L5.38006 19.7602C5.068 19.9162 4.69227 19.8657 4.4325 19.6328C4.17273 19.3999 4.08167 19.0319 4.20286 18.7047L6.68621 12.0001L4.20285 5.29514C4.08167 4.96796 4.17274 4.59996 4.43251 4.36704ZM8.18424 12.8501L6.54439 17.2774L15.3992 12.8501H8.18424ZM15.3993 11.1501H8.18425L6.54437 6.72248L15.3993 11.1501Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default SendIcon;
